import { useAuth0 } from '@auth0/auth0-react';
import React, { useContext, useEffect } from 'react'
import { ContextAppSettings } from '../config/context';
import { showLoader, hideLoader } from '../config/functions';
import axiosInstance from '../config/axios';
import history from '../config/history';
import { MpaUi } from '@mixerpa/mpa-react-components.mpa-ui';

const CheckLoginStatus = () => {
  const context = useContext(ContextAppSettings);
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    const token = window.storageGetItemValue("Auth-token");
    if (isAuthenticated && !window.location.pathname.includes("/post-login")) {
      loadProfile();
    } else if (!isAuthenticated && token) {
      loadProfile();
    }
  }, [isAuthenticated])

  async function loadProfile() {
    showLoader();

    var tokenInStorage = null;

    try {
      tokenInStorage = JSON.parse(window.localStorage.getItem(`@@auth0spajs@@::${process.env.REACT_APP_AUTH0_CLIENT_ID}::@@user@@`) ?? "");
    }
    catch (e) { }

    // Exit if the token is not found in storage...
    if (!tokenInStorage) {
      window.storageRemoveItem("Auth-memberID");
      window.storageRemoveItem("Auth-memberTypeID");
      window.storageRemoveItem("Auth-roles");
      window.storageRemoveItem("isFirstAccess");
      window.storageRemoveItem("Auth-token");

      if (context) {
        context.setAuthToken(null);
      }

      hideLoader();
      
      history.push("/login");
      return;
    }

    const res = await fetch(`${process.env.REACT_APP_API_URL}Authentication/Auth0login`, {
      method: "POST",
      headers: {
        'Authorization': `Bearer ${tokenInStorage.id_token}`
      }
    }).catch((e) => null);

    if (res && res.ok) {
      // Get my profile...
      const response = await res.json();

      if (response.status !== "success") {
        MpaUi.Utils.showNotification("error", "An error has occurred while retriving the profile data, please contact an administrator");
        hideLoader();
      }
      else {
        let _token = `Bearer ${response.data.token}`;
        window.storageSetItem("Auth-token", _token);
        if (response.deviceToken) {
          window.storageSetItem("Auth-device-token", response.data.deviceToken);
        }

        axiosInstance
          .get("Members/GetMyProfile")
          .then((res) => {
            let profileResponse = res.data;
            if (profileResponse.status === "success") {
              window.storageSetItem("Auth-memberID", profileResponse.data.memberID);
              window.storageSetItem("Auth-memberTypeID", profileResponse.data.memberTypeID);
              window.storageSetItem("Auth-roles", profileResponse.data.memberRoles);
              window.storageSetItem("isFirstAccess", profileResponse.data.isFirstAccess ? "S" : "N");
            }
            if (context) {
              context.setAuthToken(_token);
              context.setProfileData(profileResponse.data);
            }
          })
          .finally(() => {
            hideLoader();
          })
          .catch((e) => {
            MpaUi.Utils.showNotification("error", "An error has occurred: " + e.message);
          });
      }
    } else {
      window.storageRemoveItem("Auth-memberID");
      window.storageRemoveItem("Auth-memberTypeID");
      window.storageRemoveItem("Auth-roles");
      window.storageRemoveItem("isFirstAccess");

      if (context) {
        context.setAuthToken(null);
      }

      history.push("/login")

      if (res?.status === 401) {
        MpaUi.Utils.showNotification("error", "Session expired, please login to access the platform");
      } else {
        MpaUi.Utils.showNotification("error", "An error has occurred while authenticating, please contact an administrator");
      }
      hideLoader();
    }
  }

  return (
    <></>
  )
}

export default CheckLoginStatus