import React, { useState, useEffect } from 'react';
import $ from 'jquery';

import axios from '../../config/axios';
import { ChatTypeRes } from '../../Models/ResponseModels/Chat'

import ChatInfo from './ChatInfo';
import Toggle from '../_UI/Toggle/Toggle';

interface IProps {
    chatInfo: ChatTypeRes.IChat,      // info chat open
    liveWebinar?: boolean,
    fnShowArchivedMessages: (value: boolean) => void
}

const ChatDetails = ({ chatInfo, liveWebinar = false, fnShowArchivedMessages }: IProps) => {

    const [isModalInfoOpen, setModalInfoOpen] = useState<boolean>(false);
    const [isArchiveMessagesVisible, showArchivedMessages] = useState<boolean>(false);

    useEffect(() => {

        //Closes the chat faculty details
        $(".chat-om-close").on("click", function(){
            $(".chat-om-main-msg").slideUp(300).promise().done( function() {
                $(".chat-om-open").fadeIn(300).promise().done(() => {
                    var chatOpen = document.querySelector(".chat-om-open") as HTMLElement
                    if(chatOpen){
                        chatOpen.style.display = "block"
                    }
                });
            });
        });
    
        //Reopens the chat faculty details
        $(".chat-om-open").on("click", function(){
            $(".chat-om-open").fadeOut(300);
            $(".chat-om-main-msg").slideDown(300);
        });

    }, []);

    const handleArchivedMessages = () => {
        const showArchived = !isArchiveMessagesVisible;
        showArchivedMessages(showArchived);
        fnShowArchivedMessages(showArchived);
    }

    const faculties = chatInfo.partecipants.filter((faculty) => faculty.isFaculty === true);
    const isChatAdmin = window.userInRole("ChatAdmin");

    let facultiesHTML: any = null
    window.asyncForEach(faculties, async (faculty: any) => {
        if(faculty.member.hasProfilePicture)
        {
            await axios.get("Members/" + faculty.member.memberID + "/ProfileImage").then(res => {
                var data = res.data;
                if(data.status === "success")
                {
                    faculty.portrait = data.data.content;
                }
                else {
                    faculty.portrait  = "/assets/images/member-no-image.jpg";
                }
            })
        }
        else
        {
            faculty.portrait = "/assets/images/member-no-image.jpg";
        }
        facultiesHTML = (
            <div className="chat-om-faculty-div">
            	<img src={faculty.portrait} alt="" className="img-fluid center-block" />
            	<p>{faculty.member.title+' '+faculty.member.name+' '+faculty.member.lastName}</p>
            </div>
        )
    })

    return (
        <div className="chat-opening-message-wrapper">
            <div className="chat-opening-message clearfix chat-om-main-msg">
                <button type="button" className="close chat-om-close" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
                {
                    !liveWebinar ?
                    <button type="button" id="btn-chat-info"
                        className="btn btn-primary btn-sm float-right margin-bottom-10" aria-label="Chat Informations"
                        onClick={() => setModalInfoOpen(true)}>
                        <span className="glyphicon glyphicon-info-sign mr-2" aria-hidden="true"></span>
                        Case Forum info
                    </button> :
                    null
                }
                <div className="faculties-container" id="faculties-container">
                    { facultiesHTML }
                </div>
                <div className="chat-om-text-div">
                    <h1 id="chat-room-title">{chatInfo.chatRoomTitle}</h1>
                    <p id="chat-room-description">{chatInfo.chatRoomDescription}</p>
                    {
                        isChatAdmin &&
                        <Toggle 
                            text="Show archived messages" 
                            isActive={isArchiveMessagesVisible} 
                            onClick={handleArchivedMessages}>
                        </Toggle>
                    }
                </div>
            </div>
            <div className="chat-om-open">
                <span className="glyphicon glyphicon-info-sign" aria-hidden="true"></span>
            </div>
            {
                isModalInfoOpen ?
                <ChatInfo isOpen={isModalInfoOpen} setOpen={setModalInfoOpen} /> :
                null
            }
        </div>
    )
}

export default ChatDetails;