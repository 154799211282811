import { useEditor, useNode, Element } from "@craftjs/core";
import React, { CSSProperties, ReactNode, useEffect, useState } from "react";
import "../PluginsStyles/ContainerLayout.scss";
import RangeInput from "./Range";
import ColorPicker from "./ColorPicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faColumns, faSquare, faTrain } from "@fortawesome/free-solid-svg-icons";
import Upload from "./Upload";
import Anchor from "./Anchor";
import { calculateMargin } from "./Button";
import { Container } from "./Container";

interface IProps {
    children?: ReactNode | ReactNode[],
    style?: CSSProperties
    anchor?: string,
    numberOfCols?: string
}

export const ContainerLayout = ({ children, style, anchor, numberOfCols }: IProps) => {
    const { connectors: { connect, drag } } = useNode();
    const [colsNumber, setColsNumber] = useState(0);
    let containers = [];

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    useEffect(() => {
        switch (numberOfCols) {
            case 'one-column':
                setColsNumber(1);
                break;
            case 'two-columns':
                setColsNumber(2);
                break;
            case 'three-columns':
                setColsNumber(3);
                break;
            default:
                console.log('default case');
        }


    }, [numberOfCols])

    function gimmieChild(num: number) {
        let elems: ReactNode[] | null = null;
        elems = [];
        for (let i = 0; i < num; i++) {
            elems.push(
                <Element key={i} id={"container-" + num} is={Container}
                    canvas
                    anchor={"none"}
                    style={{ gap: '16px', padding: '16px', backgroundColor: '#F0EFF0', flexDirection: "column" }}
                />
            );
        }
        return elems;
    }

    return (
        <div className={`section ${enabled && 'editable'} container-row ${numberOfCols}`} style={{ ...style, margin: calculateMargin(anchor) }} ref={ref => { if (ref) connect(drag(ref)) }} >
            {
                gimmieChild(colsNumber)
            }
            {/* {(children || style?.background) ? children : <div className="dummy">Drag here your elements...</div>} */}
        </div>
    )
}

export const ContainerLayoutSettings = () => {
    const { backgroundColor, name, anchor, gap, padding, maxWidth, height, flexDirection, numberOfCols, actions: { setProp } } = useNode(node => ({
        backgroundColor: node.data.props.style.backgroundColor,
        name: node.data.props.filename,
        anchor: node.data.props.anchor,
        // background: node.data.props.style.background,
        height: node.data.props.style.height,
        maxWidth: node.data.props.style.maxWidth || "100%",
        gap: node.data.props.style.gap,
        padding: node.data.props.style.padding,
        flexDirection: node.data.props.style.flexDirection,
        numberOfCols: node.data.props.numberOfCols,
    }));

    const [autoLayout, setAutoLayout] = useState(anchor === "none");

    function handleInputChange(name: string, base64: string) {
        setProp((props: { filename: string }) => props.filename = name);
        if (base64) {
            setProp((props: { style: CSSProperties }) => props.style.backgroundImage = `url(${base64})`);
        } else {
            setProp((props: { style: CSSProperties }) => props.style.backgroundImage = "none");
        }
    }

    return (
        <form>
            <div className="option">
                <label>Layout</label>
                <div className="list">
                    <div className={`${numberOfCols}`} onClick={e => { setProp((props: { numberOfCols: string; }) => props.numberOfCols = "one-column"); }}>
                        <FontAwesomeIcon icon={faSquare} />
                    </div>
                    <div className={`${numberOfCols}`} onClick={e => { setProp((props: { numberOfCols: string; }) => props.numberOfCols = "two-columns"); }}>
                        <FontAwesomeIcon icon={faColumns} />
                    </div>
                    <div className={`${numberOfCols}`} onClick={e => { setProp((props: { numberOfCols: string; }) => props.numberOfCols = "three-columns"); }}>
                        <FontAwesomeIcon icon={faTrain} />
                    </div>
                    {/* <div className={`${flexDirection === "row" ? "selected" : ""} icon`} onClick={e => { setProp((props: { style: CSSProperties }) => props.style.flexDirection = "row"); }} >
                        <FontAwesomeIcon icon={faColumns} />
                    </div>
                    <div className={`${flexDirection === "column" ? "selected" : ""} icon`} onClick={e => { setProp((props: { style: CSSProperties }) => props.style.flexDirection = "column"); }} >
                        <FontAwesomeIcon icon={faColumns} className={`fa-rotate-90`} />
                    </div> */}
                </div>
            </div>
        </form>
    )
}

export const ContainerDefaultProps = {
    style: {
        backgroundColor: "#FFF",
        padding: "3px",
        flexDirection: "column",
        gap: "16px",
        anchor: "none",
    }
};

ContainerLayout.craft = {
    props: {
        filename: "",
        anchor: "none",
        style: {
            backgroundColor: "#fff",
            maxWidth: "100%",
            height: "auto",
            padding: "20px",
            // flexDirection: "row",
            numberOfCols: "three-columns",
            gap: "16px"
        }
    },
    rules: {
        canDrag: (node: any) => node.data.props.text != "Drag"
    },
    related: {
        settings: ContainerLayoutSettings
    }
}