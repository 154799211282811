import './config/polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import history from './config/history';
import './config/globalFunctions';
import App from './App';
import './main.scss';
import './style/fonts/budmo-jiggler.ttf';
import './style/fonts/budmo-jigglish.ttf';

import { Auth0Provider } from '@auth0/auth0-react';

const onRedirectCallback = (appState: any) => {
  console.log("appState", appState);
  if (window.storageGetItemValue("redirectTo")) {
    history.push(window.storageGetItemValue("redirectTo"));
    window.storageRemoveItem("redirectTo");
  } else {
    history.replace("/");
  }

  history.push("/post-login");
};

const app = (
  <Router history={history}>
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_ISSUER_BASE_URL || ""}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID || ""}
      onRedirectCallback={onRedirectCallback}
      cacheLocation='localstorage'
      authorizationParams={{
        redirect_uri: process.env.REACT_APP_AUTH0_BASE_URL,
        scope: "openid email offline_access",
        signup_form_url: process.env.REACT_APP_AUTH0_SIGNUP_FORM_URL,
        user_import_url: process.env.REACT_APP_AUTH0_USER_IMPORT_URL,
        home_url: process.env.REACT_APP_AUTH0_BASE_URL,
      }}
    >
      <App />
    </Auth0Provider>
  </Router>
);

ReactDOM.render(app, document.getElementById('root'));