import { TMainLanguage } from "./context";

//type Tlanguages = 'en' | 'de' | 'es' | 'it' | 'fr';
type IStringTranslations = {
    [key in TMainLanguage]: string;
};

interface IObjectTranslations {
    register: {
        header_title: IStringTranslations,
        header_subtitle: IStringTranslations,
        accept_terms: IStringTranslations,
        accept_terms_digital: IStringTranslations,
        area_of_interests: IStringTranslations,
        choose_area_of_interests: IStringTranslations,
        city: IStringTranslations,
        clinic: IStringTranslations,
        confirm_password: IStringTranslations,
        country: IStringTranslations,
        select_a_country: IStringTranslations,
        date_of_birth: IStringTranslations,
        email_address: IStringTranslations,
        forgot_password: IStringTranslations,
        login: IStringTranslations,
        register: IStringTranslations,
        register_now: IStringTranslations,
        select_title: IStringTranslations,
        mr: IStringTranslations,
        ms: IStringTranslations,
        mrs: IStringTranslations,
        // dr: IStringTranslations,
        // dr_ms: IStringTranslations,
        // prof: IStringTranslations,
        a_prof: IStringTranslations,
        type_of_user: IStringTranslations,
        unsubscribe: IStringTranslations,
        post_registration_info: IStringTranslations,
        registration_completed: IStringTranslations,
        confirm: IStringTranslations
    },
    login: {
        existing_details: IStringTranslations,
        remember_me: IStringTranslations,
        login_successful: IStringTranslations,
        password_change: IStringTranslations,
        change_password: IStringTranslations,
        post_change_password_info: IStringTranslations,
        email_sent_successfully: IStringTranslations,
        password_change_successfully: IStringTranslations,
        email_sent: IStringTranslations,
    }
    forgot_password: {
        forgot_password: IStringTranslations,
        email_change_password: IStringTranslations,
        send_me_email: IStringTranslations,
        change_your_password: IStringTranslations,
        insert_new_password: IStringTranslations,
        retype_paasword: IStringTranslations,
        password_min_length: IStringTranslations,
    },
    gender: {
        male: IStringTranslations,
        female: IStringTranslations,
        prefer_not_say: IStringTranslations,
    },
    navbar: {
        users_list: IStringTranslations,
        edit_profile: IStringTranslations,
        views_panel: IStringTranslations,
        logout: IStringTranslations,
        back: IStringTranslations,
        reserved_area: IStringTranslations,
        navigation: IStringTranslations,
        view_profile: IStringTranslations,
        events_management: IStringTranslations,
        speaker_area: IStringTranslations,
        upload_menu: IStringTranslations,
        warehouse_area: IStringTranslations,
        hall: IStringTranslations,
        onDemand: IStringTranslations,
        language: IStringTranslations,
        exhibition_room: IStringTranslations,
        exhibition_room_configurator: IStringTranslations,
        emea_awards_trip: IStringTranslations,
    },
    hall: {
        header: IStringTranslations,
        header_no_events: IStringTranslations,
        visit_the_hall: IStringTranslations,
        welcome: IStringTranslations,
        user_journey: IStringTranslations,
        watch_the_teaser: IStringTranslations,
        exhibition_preview_title: IStringTranslations,
        exhibition_preview_subtitle: IStringTranslations,
    },
    profile: {
        header: IStringTranslations,
        header_no_events: IStringTranslations,
        not_answered: IStringTranslations,
        not_participating: IStringTranslations,
        participate: IStringTranslations,
        no: IStringTranslations,
        yes: IStringTranslations,
        job_title: IStringTranslations,
        name: IStringTranslations,
        name_lastname: IStringTranslations,
        password: IStringTranslations,
        surname: IStringTranslations,
        title: IStringTranslations,
        gender: IStringTranslations,
        take_a_picture: IStringTranslations,
        delete_account: IStringTranslations,
        save: IStringTranslations,
        open_the_event: IStringTranslations,
        email_lowercase: IStringTranslations,
        maximum_file_size: IStringTranslations,
        successfully_updated: IStringTranslations,
        delete_account_info: IStringTranslations,
        yes_delete_account: IStringTranslations,
        remove: IStringTranslations,
        update_info: IStringTranslations,
        edit_profile: IStringTranslations,
    },
    event: {
        countdown_title: IStringTranslations,
        program: IStringTranslations,
        agenda: IStringTranslations,
        info: IStringTranslations,
        enter_the_event: IStringTranslations,
        related_content: IStringTranslations,
        backstage_chat: IStringTranslations,
        faculty_panel: IStringTranslations,
        related_documents: IStringTranslations,
        your_events: IStringTranslations,
        skip_animation: IStringTranslations,
        enter: IStringTranslations,
    },
    contact_faculty: {
        textbox_placeholder: IStringTranslations,
        contact_me_at: IStringTranslations,
        contact_me: IStringTranslations,
    }
    rooms: {
        plenary_room: IStringTranslations,
        lounge_room: IStringTranslations,
        exhibition_room: IStringTranslations,
        breakout_room: IStringTranslations,
        breakout_room_number: IStringTranslations,
        open_the_livestream: IStringTranslations,
        open_the_breakout_room: IStringTranslations,
        breakout_booking: IStringTranslations,
    },
    lounge_cafe: {
        title: IStringTranslations,
        open_discussion: IStringTranslations,
        chat_with_faculties: IStringTranslations,
        welcome_to: IStringTranslations,
    },
    save_the_date: {
        header_title: IStringTranslations,
        header_subtitle: IStringTranslations,
    },
    video: {
        program: IStringTranslations
    },
    chat: {
        header_title: IStringTranslations,
        header_subtitle: IStringTranslations,
        textbox_placeholder: IStringTranslations,
        check_anonym_message: IStringTranslations,
        check_top_rated: IStringTranslations,
        check_most_recent: IStringTranslations,
    },
    agenda: {
        header_title: IStringTranslations,
        header_subtitle: IStringTranslations,
    },
    polls: {
        header_title: IStringTranslations,
        header_subtitle: IStringTranslations,
    },
    survey: {
        header_title: IStringTranslations,
        submit_button: IStringTranslations,
        check_answers_title: IStringTranslations,
        check_answers_content: IStringTranslations,
        check_answers_button: IStringTranslations,
        test_submitted_title: IStringTranslations,
        test_submitted_content: IStringTranslations,
        test_submitted_content2: IStringTranslations,
        test_submitted_button1: IStringTranslations,
        test_submitted_button2: IStringTranslations,
        test_completed_title: IStringTranslations,
        test_completed_content: IStringTranslations,
        test_completed_button1: IStringTranslations,
        test_completed_button2: IStringTranslations,
    },
    policy: {
        by_registering: IStringTranslations,
        consent_to_receive: IStringTranslations,
        if_already: IStringTranslations,
        thank_you: IStringTranslations,
    },
    breakout_room: {
        breakout_list_title: IStringTranslations,
        breakout_bookings_title: IStringTranslations,
        help: IStringTranslations,
        help_title: IStringTranslations,
        help_text: IStringTranslations,
        open_in_the_app: IStringTranslations,
    },
    faculty_area: {
        welcome_to: IStringTranslations,
        tips: IStringTranslations,
        description_text: IStringTranslations,
        get_prepared_digital_event: IStringTranslations,
        join_live_session_zoom: IStringTranslations,
        use_of_the_backstage_chat: IStringTranslations,
    },
    onDemand_area: {
        welcome_to: IStringTranslations,
        welcome_description: IStringTranslations,
        your_library: IStringTranslations,
        library_description: IStringTranslations,
        library_description2: IStringTranslations,
        select_day: IStringTranslations,
        order_by: IStringTranslations,
        date: IStringTranslations,
        most_watched: IStringTranslations,
    },
    achievements_info: {
        title: IStringTranslations,
    },
    error: {
        unauthorized: IStringTranslations,
        forbidden: IStringTranslations,
        network_error: IStringTranslations,
        generic_error: IStringTranslations,
        password_match: IStringTranslations,
        invalid_email: IStringTranslations,
        required: IStringTranslations,
        password_short: IStringTranslations,
        password_long: IStringTranslations,
    },
    event_step_manager: {
        current_step: IStringTranslations,
        auto_redirect: IStringTranslations,
        manual_redirect: IStringTranslations,
    }
}

export const translations: IObjectTranslations = {
    register: {
        header_title: {
            'en': 'Welcome to zbsmartspace',
            'de': 'Willkommen bei zbsmartspace',
            'es': 'Bienvenido a zbsmartspace',
            'it': 'Benvenuto in zbsmartspace',
            'fr': 'Bienvenue chez zbsmartspace',
        },
        header_subtitle: {
            'en': 'Transforming the way we experience digital conferencing',
            'de': 'Wir verändern die Art und Weise, wie wir digitale Konferenzen erleben',
            'es': 'Transformando la experiencia de la reunión digital',
            'it': 'Trasformare il modo in cui viviamo le conferenze digitali',
            'fr': 'Transformons notre expérience de la conférence digitale',
        },
        accept_terms: {
            'en': 'Accept Terms of Service',
            'de': 'Nutzungsbedingungen akzeptieren',
            'es': 'Acepta los  Términos y Condiciones del Servicio',
            'it': 'Accetta Termini e Condizioni del servizio',
            'fr': 'Acceptation des conditions générales d\'utilisation',
        },
        accept_terms_digital: {
            'en': 'By checking this box I confirm I agree with the Terms and Conditions for Digital Events',
            'de': 'Durch Ankreuzen dieses Kästchens bestätige ich, dass ich mit den Allgemeinen Geschäftsbedingungen für digitale Veranstaltungen einverstanden bin.',
            'es': 'Al marcar esta casilla, confirmo que estoy de acuerdo con los Términos y condiciones para eventos digitales',
            'it': 'Cliccando su questo box accetto e confermo i termini e le condizioni per gli eventi digitali',
            'fr': 'En cochant cette case, je confirme que j\'accepte les conditions générales des événements numériques',
        },
        area_of_interests: {
            'en': 'Area of Interests',
            'de': 'Interessenbereich',
            'es': 'Área de Interés',
            'it': 'Aree di Interesse',
            'fr': 'Centre d\'intérêts',
        },
        choose_area_of_interests: {
            'en': 'Choose your area of interests',
            'de': 'Wählen Sie Ihren Interessenbereich',
            'es': 'Elija sus areas de interés',
            'it': 'Scegli le tue aree di interesse',
            'fr': 'Choisissez vos centres d\'intérêts',
        },
        city: {
            'en': 'City',
            'de': 'Stadt',
            'es': 'Ciudad',
            'it': 'Città',
            'fr': 'Ville',
        },
        clinic: {
            'en': 'Clinic/Hospital ',
            'de': 'Klinik/Krankenhaus',
            'es': 'Clínica/Ciudad',
            'it': 'Clinica/Ospedale',
            'fr': 'Clinique/Hôpital',
        },
        confirm_password: {
            'en': 'Confirm Password',
            'de': 'Bitte bestätigen Sie das Passwort',
            'es': 'Confirme su Contraseña',
            'it': 'Conferma Password',
            'fr': 'Confirmer le mot de passe',
        },
        country: {
            'en': 'Country',
            'de': 'Land',
            'es': 'País',
            'it': 'Paese',
            'fr': 'Pays',
        },
        select_a_country: {
            'en': 'Please select a country',
            'de': 'Bitte wählen Sie ein Land',
            'es': 'Por favor seleccione un país',
            'it': 'Seleziona Paese',
            'fr': 'Selectionnez un pays',
        },
        date_of_birth: {
            'en': 'Date of Birth (dd/mm/yyyy)',
            'de': 'Geburtsdatum (tt/mm/jjjj)',
            'es': 'Fecha de nacimiento(dd/mm/aaaa)',
            'it': 'Data di Nascita (gg/mm/aa)',
            'fr': 'Date de naissance (JJ/MM/AAAA)',
        },
        email_address: {
            'en': 'Email address',
            'de': 'Email Adresse',
            'es': 'Dirección de Email',
            'it': 'Indirizzo Email',
            'fr': 'Adresse email',
        },
        forgot_password: {
            'en': 'I forgot my password',
            'de': 'Passwort vergessen',
            'es': 'He olvidado mi contraseña',
            'it': 'Password dimenticata',
            'fr': 'J\'ai oublié mon mot de passe',
        },
        login: {
            'en': 'Login',
            'de': 'Login',
            'es': 'Login',
            'it': 'Accedi',
            'fr': 'Identifiant',
        },
        register: {
            'en': 'Register ',
            'de': 'Registrieren',
            'es': 'Regístrese',
            'it': 'Registrati',
            'fr': 'S\'enregistrer',
        },
        register_now: {
            'en': 'Register Now!',
            'de': 'Registrieren Sie sich jetzt!',
            'es': '!Regístrese Ahora!',
            'it': 'Registrati adesso!',
            'fr': 'S\'enregistrer maintenant!',
        },
        select_title: {
            'en': 'Please select a title',
            'de': 'Bitte wählen Sie Ihren Titel',
            'es': 'Por favor seleccione un título',
            'it': 'Seleziona il tuo titolo',
            'fr': 'Merci de sélectionner un titre',
        },
        mr: {
            'en': 'Mr',
            'de': 'Herr',
            'es': 'Sr.',
            'it': 'Sig.',
            'fr': 'Mr',
        },
        ms: {
            'en': 'Ms',
            'de': 'Frau',
            'es': 'Srta.',
            'it': 'Sig.a',
            'fr': 'Mme',
        },
        mrs: {
            'en': 'Mrs',
            'de': 'Mrs',
            'es': 'Sra.',
            'it': 'Sig.na',
            'fr': 'Mme',
        },
        a_prof: {
            'en': 'A/Prof',
            'de': 'A/Prof',
            'es': 'A/Prof',
            'it': 'Professor',
            'fr': 'Prof.',
        },
        type_of_user: {
            'en': 'Type of User',
            'de': 'Benutzertyp',
            'es': 'Tipo de usuario',
            'it': 'Tipologia Utente',
            'fr': 'Type d\'utilisateur',
        },
        unsubscribe: {
            'en': 'You can unsubscribe at any time. To do so please go to “Edit your profile” by clicking the icon 👤 and uncheck the newsletter checkbox. If you unsubscribe by clicking on the newsletter footer button “unsubscribe from this list”, you won’t be able to change your mind in the future.',
            'de': 'Sie können sich jederzeit wieder abmelden. Dazu gehen Sie bitte auf "Ihr Profil bearbeiten", indem Sie auf das Symbol 👤 klicken, und deaktivieren Sie das Kontrollkästchen für den Newsletter. Wenn Sie sich durch Klicken auf die Schaltfläche "Von dieser Liste abmelden" in der Fußzeile des Newsletters abmelden, können Sie Ihre Meinung in Zukunft nicht mehr ändern.',
            'es': 'Puede darse de baja en cualquier momento. Para hacerlo, vaya a "Editar su perfil" haciendo clic en el icono 👤 y desmarque la casilla de verificación del formulario. Si cancela la suscripción haciendo clic en el botón al pie de página del formulario "cancelar suscripción de esta lista", no podrá cambiar de opinión en el futuro.',
            'it': 'Puoi cancellare la tua iscrizione in qualsiasi momento da "Modifica Profilo" cliccando sull\'icona 👤 ed eliminando la spunta dalla casella newsletter. Se invece cancelli la tua iscrizione cliccando su "cancellami dalla lista" in fondo alla pagina della newsletter non potrai  modificare la tua scelta successivamente e ricevere nuovamente le informazioni.',
            'fr': 'Vous pouvez vous désinscrire à tout moment. Pour ce faire, cliquez sur « Modifier votre profil » en cliquant sur l\'icône 👤 et décochez la case concernant l\'envoi de la newsletter. Si vous vous désinscrivez en cliquant sur le bouton en pied de page de la newsletter « se désinscrire de cette liste », vous ne pourrez plus changer d\'avis.',
        },
        post_registration_info: {
            'en': 'Please note that your account needs to be approved by a Zimmer Biomet Admin. <br></br>Once approved, you will receive a confirmation email. <p className="u-font-size-9 mt-4">If you can\'t see it, please also check into your spam folder.</p>',
            'de': 'Bitte beachten Sie, dass Ihr Konto von einem Zimmer Biomet-Administrator genehmigt werden muss. <br></br>Nach der Genehmigung erhalten Sie eine Bestätigungs-E-Mail. <p className="u-font-size-9 mt-4">Wenn Sie es nicht sehen können, schauen Sie bitte auch in Ihren Spam-Ordner.</p>',
            'es': 'Recuerda que tu cuenta de tiene que ser aprobada por un administrador de Zimmer Biomet. <br></br> Una vez aprobada, recibirás un correo de confirmación. <p className="u-font-size-9 mt-4"> Si no puedes verla, revisas tu carpeta de spam.</p>',
            'it': 'Tieni presente che il tuo account deve essere approvato da un amministratore di Zimmer Biomet. <br></br>Una volta approvato, riceverai un\'email di conferma. <p className="u-font-size-9 mt-4">Se non riesci a vederla, controlla anche nella cartella spam.</p>',
            'fr': 'Veuillez noter que votre compte doit être approuvé par un administrateur de Zimmer Biomet. <br></br> Une fois approuvé, vous recevrez un email de confirmation. <p className="u-font-size-9 mt-4"> Si vous ne pouvez pas la voir, vérifiez également votre dossier spam. </p>',
        },
        registration_completed: {
            'en': 'Registration completed!',
            'de': 'Registrierung vervollständigt!',
            'es': '¡Inscripción completada!',
            'it': 'Registrazione completata!',
            'fr': 'Enregistrement terminé!',
        },
        confirm: {
            'en': 'Confirm',
            'de': 'Bestätigen Sie',
            'es': 'Confirme',
            'it': 'Conferma',
            'fr': 'Confirmer',
        }
    },
    login: {
        existing_details: {
            'en': 'Please use your existing <br></br><a href="https://www.zbinetworkemea.com" target="_blank" rel="noopener noreferrer">Zimmer Biomet Institute Network</a> login details.',
            'de': 'Bitte verwenden Sie Ihre bestehenden <br>Zugangsdaten für das <br><a href="https://www.zbinetworkemea.com" target="_blank" rel="noopener noreferrer">Zimmer Biomet Institute Network.</a>',
            'es': 'Por favor utilizas tus datos de acceso de <br></br><a href="https://www.zbinetworkemea.com" target="_blank" rel="noopener noreferrer">Zimmer Biomet Institute Network</a>',
            'it': 'Per cortesia usa i tuoi dati di accesso di <br></br><a href="https://www.zbinetworkemea.com" target="_blank" rel="noopener noreferrer">Zimmer Biomet Institute Network</a>',
            'fr': 'Utilisez vos données d\'accès de <br></br><a href="https://www.zbinetworkemea.com" target="_blank" rel="noopener noreferrer">Zimmer Biomet Institute Network</a> s\'il vous plaît',
        },
        remember_me: {
            'en': 'Remember me',
            'de': 'Angemeldet bleiben',
            'es': 'Recuerdeme',
            'it': 'Ricordamelo',
            'fr': 'Se souvenir de moi',
        },
        login_successful: {
            'en': 'Login successful!',
            'de': 'Anmeldung erfolgreich!',
            'es': '¡Acceso conseguido!',
            'it': 'Accesso riuscito!',
            'fr': 'Accès réussi!',
        },
        password_change: {
            'en': 'Your password has been changed successfully!',
            'de': 'Ihr Passwort wurde erfolgreich geändert!',
            'es': '¡Tu contraseña ha sido cambiada con éxito!',
            'it': 'La tua password è stata cambiata con successo!',
            'fr': 'Votre mot de passe a été modifié avec succès!',
        },
        change_password: {
            'en': 'Change password',
            'de': 'Passwort ändern',
            'es': 'Cambiar contraseña',
            'it': 'Cambia la password',
            'fr': 'Changer de mot de passe',
        },
        post_change_password_info: {
            'en': 'An email with a link to change your password has been sent to your inbox.<br /> <p className="u-font-size-9">If you can\'t see it, please also check into your spam folder.</p>',
            'de': 'Eine E-Mail mit einem Link zum Ändern Ihres Passworts wurde an Ihren Posteingang gesendet.<br /> <p className="u-font-size-9"> Wenn Sie es nicht sehen können, schauen Sie bitte auch in Ihren Spam-Ordner.</p>',
            'es': 'Un correo electrónico con un enlace para cambiar la contraseña ha sido enviado a tu bandeja de entrada. <br /> <p className="u-font-size-9"> Si no puedes verla, revisas tu carpeta de spam. </p>',
            'it': 'Un\'e-mail con un link per modificare la password è stata inviata alla tua casella di posta.<br /> <p className="u-font-size-9">Se non riesci a vederla, controlla anche nella cartella spam.</p>',
            'fr': 'Un e-mail avec un lien pour modifier le mot de passe a été envoyé à votre boite de réception. <br /> <p className="u-font-size-9"> Si vous ne pouvez pas le voir, vérifiez également dans le dossier spam. </p>',
        },
        email_sent_successfully: {
            'en': 'Email sent successfully!',
            'de': 'Email erfolgreich Versendet!',
            'es': '¡Correo electrónico enviado correctamente!',
            'it': 'Email inviata correttamente!',
            'fr': 'L\'email a été envoyé avec succès!',
        },
        password_change_successfully: {
            'en': 'Password change successful!',
            'de': 'Passwortänderung erfolgreich!',
            'es': '¡Modificación de contraseña conseguida!',
            'it': 'Modifica password riuscita!',
            'fr': 'Le changement de mot de passe a réussi',
        },
        email_sent: {
            'en': 'An email has been sent',
            'de': 'Eine E-Mail wurde gesendet',
            'es': 'Un correo ha sido enviado',
            'it': 'Una email è stata inviata',
            'fr': 'Un email a été envoyé',
        }
    },
    forgot_password: {
        forgot_password: {
            'en': 'Forgot your password?',
            'de': 'Passwort vergessen?',
            'es': '¿Olvidado tu contraseña?',
            'it': 'Hai dimenticato la password?',
            'fr': 'Mot de passe oublié?',
        },
        email_change_password: {
            'en': 'Please insert your email, a link to change your password will be sent to your inbox.',
            'de': 'Bitte geben Sie Ihre E-Mail ein, ein Link zum Ändern Ihres Passworts wird an Ihren Posteingang gesendet.',
            'es': 'Ingreses tu correo electrónico, recibirás  en tu bandeja de entrada un enlace para cambiar tu contraseña.',
            'it': 'Inserisci la tua email, ti verrà inviato un link per modificare la password nella tua casella di posta.',
            'fr': 'Veuillez entrer votre email, vous recevrez un lien pour modifier le mot de passe dans votre boîte de réception.',
        },
        send_me_email: {
            'en': 'Send me an email with the link',
            'de': 'Senden Sie mir eine E-Mail mit dem Link',
            'es': 'Enviame un correo electrónico con el enlace',
            'it': 'Mandami una mail con il link',
            'fr': 'Envoyez-moi un mail avec le lien',
        },
        change_your_password: {
            'en': 'Change your password',
            'de': 'Ändern Sie Ihr Passwort',
            'es': 'Cambiar contraseña',
            'it': 'Cambia la tua password',
            'fr': 'Changer votre mot de passe',
        },
        insert_new_password: {
            'en': 'Please insert a new password.',
            'de': 'Bitte geben Sie ein neues Passwort ein.',
            'es': 'Por favor introduces una nueva contraseña.',
            'it': 'Si prega di inserire una nuova password.',
            'fr': 'Veuillez entrer un nouveau mot de passe.',
        },
        retype_paasword: {
            'en': 'RETYPE PASSWORD',
            'de': 'PASSWORT ERNEUT EINGEBEN',
            'es': 'REPETIR CONTRASEÑA',
            'it': 'RIPETI PASSWORD',
            'fr': 'RÉPÉTEZ MOT DE PASSE',
        },
        password_min_length: {
            'en': 'Password length must be at least 8 characters',
            'de': 'Die Passwortlänge muss mindestens 8 Zeichen betragen',
            'es': 'Escoges una contraseña de 8 caracteres como mínimo.',
            'it': 'La lunghezza della password deve essere di almeno 8 caratteri',
            'fr': 'Le mot de passe doit contenir un minimum de 8 caractères',
        },
    },
    gender: {
        male: {
            'en': 'Male',
            'de': 'Männlich',
            'es': 'Masculino',
            'it': 'Uomo',
            'fr': 'Homme',
        },
        female: {
            'en': 'Female',
            'de': 'Weiblich',
            'es': 'Femenino',
            'it': 'Donna',
            'fr': 'Femme',
        },
        prefer_not_say: {
            'en': 'Prefer not to say',
            'de': 'keine Angabe',
            'es': 'Prefiero no especificar',
            'it': 'Preferisco non specificarlo',
            'fr': 'Je ne souhaite pas me prononcer',
        }
    },
    navbar: {
        views_panel: {
            'en': 'Views Panel',
            'de': 'Ansichten Panel',
            'es': 'Panel de visualizaciones',
            'it': 'Pannello Viste',
            'fr': 'Views Panel',
        },
        users_list: {
            'en': 'Users list',
            'de': 'Users list',
            'es': 'Lista de usuarios',
            'it': 'Lista utenti',
            'fr': 'Liste des utilisateurs',
        },
        edit_profile: {
            'en': 'Edit my profile',
            'de': 'Mein Profil bearbeiten',
            'es': 'Editar mi perfil',
            'it': 'Modifca Profilo',
            'fr': 'Modifier mon profil',
        },
        logout: {
            'en': 'Logout',
            'de': 'Ausloggen',
            'es': 'Logout',
            'it': 'Disconnettersi',
            'fr': 'Logout',
        },
        back: {
            'en': 'Back',
            'de': 'Zurück',
            'es': 'Back',
            'it': 'Indietro',
            'fr': 'Back',
        },
        reserved_area: {
            'en': 'RESERVED AREA',
            'de': 'RESERVIERTER BEREICH',
            'es': 'RESERVED AREA',
            'it': 'AREA RISERVATA',
            'fr': 'RESERVED AREA',
        },
        navigation: {
            'en': 'VISIT',
            'de': 'BESUCH',
            'es': 'VISIT',
            'it': 'VISITA',
            'fr': 'VISIT',
        },
        view_profile: {
            'en': 'My profile and badges',
            'de': 'Mein Profil',
            'es': 'Mi perfil y acreditación',
            'it': 'Mio Profilo e Badges',
            'fr': 'Mon profil et mes badges',
        },
        events_management: {
            'en': 'Manage Events',
            'de': 'Veranstaltungen verwalten',
            'es': 'Gestión de eventos',
            'it': 'Gestisci Eventi',
            'fr': 'Gérer les événements',
        },
        speaker_area: {
            'en': 'Speaker Room',
            'de': 'Referentenbereich',
            'es': 'Área del ponente',
            'it': 'Area degli Speakers',
            'fr': 'Espace orateurs',
        },
        upload_menu: {
            'en': 'Upload menu',
            'de': 'Upload menu',
            'es': 'Upload menu',
            'it': 'Carica menu',
            'fr': 'Upload menu',
        },
        warehouse_area: {
            'en': 'Graphics Warehouse',
            'de': 'Graphics Warehouse',
            'es': 'Graphics Warehouse',
            'it': 'Graphics Warehouse',
            'fr': 'Graphics Warehouse',
        },
        hall: {
            'en': 'Hall',
            'de': 'Halle',
            'es': 'Hall',
            'it': 'Hall',
            'fr': 'Hall',
        },
        onDemand: {
            'en': 'On-Demand Room',
            'de': 'On-Demand Raum',
            'es': 'Sala On-Demand',
            'it': 'Stanza On-Demand',
            'fr': 'Salle On-Demand',
        },
        language: {
            'en': 'Language',
            'de': 'Sprache',
            'es': 'Language',
            'it': 'Lingua',
            'fr': 'Language',
        },
        exhibition_room: {
            'en': 'Exhibition Room',
            'de': 'Exhibition Raum',
            'es': 'Sala Exhibition',
            'it': 'Stanza Exhibition',
            'fr': 'Salle Exhibition',
        },
        exhibition_room_configurator: {
            'en': 'Exhibition Room configurator',
            'de': 'Exhibition Raum configurator',
            'es': 'Sala Exhibition configurator',
            'it': 'Stanza Exhibition configurator',
            'fr': 'Salle Exhibition configurator',
        },
        emea_awards_trip: {
            'en': 'Mauritius photo album',
            'de': 'Mauritius photo album',
            'es': 'Mauritius photo album',
            'it': 'Mauritius photo album',
            'fr': 'Mauritius photo album',
        },
    },
    hall: {
        header: {
            'en': 'Below you find all the events you have been invited to.',
            'de': 'Nachfolgend finden Sie alle Veranstaltungen, zu denen Sie eingeladen wurden.',
            'es': 'A continuación, encontrarás los eventos a los que has sido invitado.',
            'it': 'Sotto troverai gli eventi per i quali sei stato invitato.',
            'fr': 'Vous trouverez ci-dessous les évé',
        },
        header_no_events: {
            'en': "You haven't been invited to any event yet.",
            'de': "Sie wurden noch zu keiner Veranstaltung eingeladen.",
            'es': 'Aún no has sido invitado a ningún evento.',
            'it': 'Non sei stato ancora invitato ad alcun evento.',
            'fr': 'Vous n\'avez toujours pas été invité à un événement',
        },
        visit_the_hall: {
            'en': "Visit the hall",
            'de': "Besuchen Sie die Halle",
            'es': 'Visitar el Hall',
            'it': 'Visita la hall',
            'fr': 'Visiter le hall',
        },
        welcome: {
            'en': "Welcome",
            'de': "Willkommen ",
            'es': 'Bienvenido',
            'it': 'Benvenuto',
            'fr': 'Bienvenue',
        },
        user_journey: {
            'en': 'User Journey',
            'de': 'User Journey',
            'es': 'User Journey',
            'it': 'User Journey',
            'fr': 'User Journey',
        },
        watch_the_teaser: {
            'en': 'Video Teaser',
            'de': 'Video Teaser',
            'es': 'Video Teaser',
            'it': 'Video Teaser',
            'fr': 'Video Teaser',
        },
        exhibition_preview_title: {
            'en': 'Exhibition Room',
            'de': 'Exhibition Room',
            'es': 'Exhibition Room',
            'it': 'Exhibition Room',
            'fr': 'Exhibition Room',
        },
        exhibition_preview_subtitle: {
            'en': 'Check out a preview of what is coming out next >>',
            'de': 'Check out a preview of what is coming out next >>',
            'es': 'Check out a preview of what is coming out next >>',
            'it': 'Check out a preview of what is coming out next >>',
            'fr': 'Check out a preview of what is coming out next >>',
        }
    },
    profile: {
        header: {
            'en': 'Here you can find the badge for your upcoming event.<br><br>To participate, please click <strong style="color: #0081c9;">"yes"</strong> on the event you wish to join.<br><br>This page can be accessed anytime by clicking on<br><strong style="color: #0081c9;">"My profile and badges"</strong> button.',
            'de': 'Hier finden Sie das Badge für Ihre bevorstehende Veranstaltung.<br><br>Um teilzunehmen, klicken Sie bitte auf <strong style="color: #0081c9;">"Ja"</strong> bei der Veranstaltung, an der Sie teilnehmen möchten.<br><br>Diese Seite können Sie jederzeit aufrufen, indem Sie auf die Schaltfläche <strong style="color: #0081c9;">"Mein Profil"</strong> klicken.',
            'es': 'Aquí puede encontrar su acreditación para su próximo evento.<br><br>Para participar, por favor clique en <strong style="color: #0081c9;">"Sí"</strong> en el evento en el que desea participar.<br><br>Puede acceder a esta página en cualquier momento clicando en  <strong style="color: #0081c9;">"Mi perfil y acreditación"</strong>',
            'it': 'Qui puoi trovare il badge per il tuo prossimo evento.<br><br>Per partecipare, fare clic su <strong style="color: #0081c9;">"sì"</strong> sull\'evento a cui si desidera partecipare.<br><br>Questa pagina è accessibile in qualsiasi momento cliccando sul pulsante <strong style="color: #0081c9;">"Il mio profilo e i badge"</strong>.',
            'fr': 'Veuillez trouver ici le badge de votre prochain événement.<br><br>Pour participer, veuillez cliquer sur <strong style="color: #0081c9;">"oui"</strong> sur l\'événement auquel vous souhaitez participer.<br><br>Cette page est accessible à tout moment en cliquant sur le bouton <strong style="color: #0081c9;">"Mon profil et mes badges"</strong>.',
        },
        header_no_events: {
            'en': 'No event scheduled. Your badge will be visible once the invitation is sent out.',
            'de': 'Keine Veranstaltung geplant. Ihr Abzeichen wird sichtbar, sobald die Einladung verschickt wurde.',
            'es': 'No existe evento agendado. Su acreditación será visible una vez la invitación ha sido enviada.',
            'it': 'Nessun evento in programma. Il tuo badge sarà visibile una volta inviato l\'invito.',
            'fr': 'Aucun événement prévu. Votre badge apparaîtra une fois l\'invitation envoyée.',
        },
        not_answered: {
            'en': 'Not yet answered',
            'de': 'Noch nicht beantwortet',
            'es': 'No contestado todavía',
            'it': 'Non ancora risposto',
            'fr': 'Pas encore répondu',
        },
        not_participating: {
            'en': 'Not participating',
            'de': 'Nicht teilnehmen werden',
            'es': 'No participaré',
            'it': 'Non partecipa',
            'fr': 'Ne participe pas',
        },
        participate: {
            'en': 'Will you join?',
            'de': 'Werden Sie beitreten?',
            'es': '¿Podrá participar?',
            'it': 'Parteciperai? ',
            'fr': 'Est-ce que vous participerez ?',
        },
        no: {
            'en': 'No',
            'de': 'Nein',
            'es': 'No',
            'it': 'No',
            'fr': 'Non',
        },
        yes: {
            'en': 'Yes',
            'de': 'Ja',
            'es': 'Sí',
            'it': 'Si',
            'fr': 'Oui',
        },
        job_title: {
            'en': 'Jobtitle',
            'de': 'Jobtitel',
            'es': 'Título de trabajo',
            'it': 'Qualifica',
            'fr': 'Titre',
        },
        name: {
            'en': 'Name',
            'de': 'Vorname',
            'es': 'Nombre',
            'it': 'Nome',
            'fr': 'Nom',
        },
        name_lastname: {
            'en': 'Name Lastname ',
            'de': 'Nachname',
            'es': 'Apellido',
            'it': 'Nome Cognome',
            'fr': 'Nom de famille',
        },
        password: {
            'en': 'Password',
            'de': 'Passwort',
            'es': 'Contraseña',
            'it': 'Password',
            'fr': 'Mot de passe',
        },
        surname: {
            'en': 'Surname',
            'de': 'Nachname',
            'es': 'Apellido',
            'it': 'Cognome',
            'fr': 'Nom de famille',
        },
        title: {
            'en': 'Title',
            'de': 'Titel',
            'es': 'Título',
            'it': 'Titolo',
            'fr': 'Titre',
        },
        gender: {
            'en': 'Gender',
            'de': 'Geschlecht',
            'es': 'Género',
            'it': 'Sesso',
            'fr': 'Sexe',
        },
        take_a_picture: {
            'en': 'Take a picture',
            'de': 'Machen Sie ein Foto',
            'es': 'Tomar una foto',
            'it': 'Fatti una foto',
            'fr': 'Prendre une photo',
        },
        delete_account: {
            'en': 'Delete account',
            'de': 'Account löschen',
            'es': 'Cancelar esta cuenta',
            'it': 'Eliminare l\'account',
            'fr': 'Supprimer ce compte',
        },
        save: {
            'en': 'Save',
            'de': 'Speichern',
            'es': 'Guardar',
            'it': 'Salva',
            'fr': 'Enregistrer',
        },
        open_the_event: {
            'en': 'OPEN THE EVENT',
            'de': 'VERANSTALTUNG ÖFFNEN',
            'es': 'ABRIR EL EVENTO',
            'it': 'ACCEDI ALL\'EVENTO',
            'fr': 'OUVRIR L\'ÉVÉNEMENT',
        },
        email_lowercase: {
            'en': 'will be automatically converted in lowercase',
            'de': 'wird automatisch in Kleinbuchstaben umgewandelt',
            'es': 'se convertirá automaticamente en minúsculas',
            'it': 'verrà automaticamente convertita in minuscolo',
            'fr': 'Sera automatiquement converti en minuscule',
        },
        maximum_file_size: {
            'en': 'maximum file size 20 MB',
            'de': 'maximale Dateigröße 20 MB',
            'es': 'Tamaño máximo de archivo 20MB',
            'it': 'dimensione massima del file 20 MB',
            'fr': 'Taille maximale du fichier 20 MB',
        },
        successfully_updated: {
            'en': 'Your profile has been successfully updated!',
            'de': 'Ihr Profil wurde erfolgreich aktualisiert!',
            'es': '¡Tu perfil ha sido actualizado correctamente!',
            'it': 'Il tuo profilo è stato aggiornato con successo!',
            'fr': 'Votre profil a été mis à jour avec succès',
        },
        delete_account_info: {
            'en': 'Your account will be deleted permanently.<br /> Are you sure?',
            'de': 'Ihr Account wird dauerhaft gelöscht.<br /> Sind Sie sicher?',
            'es': 'Tu cuenta será eliminada definitivamente. <br /> ¿Estás seguro de querer continuar?',
            'it': 'Il tuo account sarà eliminato definitivamente.<br /> Sei sicuro di procedere?',
            'fr': 'Votre compte sera supprimé définitivement. <br />  Voulez-vous vraiment continuer?',
        },
        yes_delete_account: {
            'en': 'Yes, delete account',
            'de': 'Ja, Account löschen',
            'es': 'Sì, eliminar cuenta',
            'it': 'Si, elimina l\'account',
            'fr': 'Supprimer le compte',
        },
        remove: {
            'en': 'Remove',
            'de': 'Entfernen',
            'es': 'Remover',
            'it': 'Rimuovere',
            'fr': 'Supprimer',
        },
        update_info: {
            'en': 'By updating your profile it will be also updated on <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a>',
            'de': 'Durch die Aktualisierung Ihres Profils wird es auch auf <br><a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a> aktualisiert',
            'es': 'Actualizando tu perfil, lo actualizarás también en <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a>',
            'it': 'Aggiornando il tuo profilo verrà aggiornato anche su <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a>',
            'fr': 'En mettant à jour votre profil, il sera également mis à jour sur <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a>',
        },
        edit_profile: {
            'en': 'Edit Profile',
            'de': 'Profil bearbeiten',
            'es': 'Edit Profile',
            'it': 'Modifica Profilo',
            'fr': 'Edit Profile',
        }
    },
    event: {
        countdown_title: {
            'en': 'THE EVENT WILL START IN:',
            'de': 'Das Event startet in:',
            'es': 'El evento comenzará en',
            'it': 'L\'evento inizierà fra',
            'fr': 'L\'évènement va débuter dans',
        },
        program: {
            'en': 'Program',
            'de': 'Programm',
            'es': 'Programa',
            'it': 'Programma',
            'fr': 'Programme',
        },
        agenda: {
            'en': 'Live-Agenda',
            'de': 'Live-Agenda',
            'es': 'Live-Agenda',
            'it': 'Live-Agenda',
            'fr': 'Live-Agenda',
        },
        info: {
            'en': 'Info Point',
            'de': 'Infopunkt',
            'es': 'Punto de Información',
            'it': 'Informazioni',
            'fr': 'Point d\'information',
        },
        enter_the_event: {
            'en': 'Enter the event',
            'de': 'Betreten Sie die Veranstaltung',
            'es': 'Introducir el evento',
            'it': 'Accedi all\'evento',
            'fr': 'Accéder à l\'évènement',
        },
        related_content: {
            'en': 'Related content',
            'de': 'Verwandte Inhalte',
            'es': 'Documentos asociados',
            'it': 'Documenti associati',
            'fr': 'Documents associés',
        },
        backstage_chat: {
            'en': 'Backstage chat',
            'de': 'Backstage-Chat',
            'es': 'Backstage chat',
            'it': 'Backstage chat',
            'fr': 'Discussion en coulisse',
        },
        faculty_panel: {
            'en': 'Faculty panel',
            'de': 'Faculty-Panel',
            'es': 'Faculty panel',
            'it': 'Faculty panel',
            'fr': 'Faculty panel',
        },
        related_documents: {
            'en': 'Related Documents',
            'de': 'Dokumente zum Thema',
            'es': 'Material Relacionado',
            'it': 'Materiale Evento',
            'fr': 'Documents connexes',
        },
        your_events: {
            'en': 'Your Events',
            'de': 'Ihr Event',
            'es': 'Sus Eventos',
            'it': 'I tuoi Eventi',
            'fr': 'Vos évènements',
        },
        skip_animation: {
            'en': 'Skip animation',
            'de': 'Animation überspringen',
            'es': 'Saltar animación',
            'it': 'Salta animazione',
            'fr': 'Ignorer l\'animation',
        },
        enter: {
            'en': 'Enter',
            'de': 'Eintreten',
            'es': 'Entrar',
            'it': 'Entra',
            'fr': 'Entrez',
        },
    },
    contact_faculty: {
        textbox_placeholder: {
            'en': 'Type here your questions',
            'de': 'Stellen Sie hier Ihre Fragen',
            'es': 'Escribe tus preguntas aquí',
            'it': 'Scrivi qui le tue domande',
            'fr': 'Écrivez vos questions ici',
        },
        contact_me_at: {
            'en': 'Contact us',
            'de': 'Kontaktiere uns',
            'es': 'Contáctanos',
            'it': 'Contattaci',
            'fr': 'Contactez-nous',
        },
        contact_me: {
            'en': 'CONTACT US',
            'de': 'KONTAKTIERE UNS',
            'es': 'CONTÁCTANOS',
            'it': 'CONTATTACI',
            'fr': 'CONTACTEZ-NOUS',
        }
    },
    rooms: {
        plenary_room: {
            'en': 'Plenary Room',
            'de': 'Meeting Raum',
            'es': 'Sala Plenaria',
            'it': 'Sala Plenaria',
            'fr': 'Salle plénière',
        },
        lounge_room: {
            'en': 'LOUNGE',
            'de': 'LOUNGE ROOM',
            'es': 'LOUNGE ROOM',
            'it': 'LOUNGE ROOM',
            'fr': 'SALON',
        },
        exhibition_room: {
            'en': 'Exhibition Room',
            'de': 'Ausstellungsraum',
            'es': 'Área de Exposición',
            'it': 'Area Espositiva',
            'fr': 'Espace d\'exposition',
        },
        breakout_room: {
            'en': 'Breakout rooms',
            'de': 'Breakout-Room',
            'es': 'Sala para Grupo ',
            'it': 'Sottogruppo Nr.',
            'fr': 'Salle de la session',
        },
        breakout_room_number: {
            'en': 'BREAKOUT N°',
            'de': 'SESSION N/',
            'es': 'SALA PARA GRUPO N°',
            'it': 'SOTTOGRUPPO N.',
            'fr': 'SESSION N°',
        },
        open_the_livestream: {
            'en': 'Open the live stream',
            'de': 'Öffne den Livestream',
            'es': 'Abrir Live Stream',
            'it': 'Aprire il Live Stream',
            'fr': 'Ouvrir le Live Stream',
        },
        open_the_breakout_room: {
            'en': 'Open the breakout room',
            'de': 'Breakout-Room öffnen',
            'es': 'Abrir Breakout Room',
            'it': 'Aprire la Breakout Room',
            'fr': 'Ouvrir la Breakout Room',
        },
        breakout_booking: {
            'en': 'Breakout Room booking',
            'de': '',
            'es': '',
            'it': '',
            'fr': '',
        }
    },
    lounge_cafe: {
        title: {
            'en': 'Lounge Room',
            'de': 'Lounge Room',
            'es': 'Lounge Room',
            'it': 'Lounge Room',
            'fr': 'Lounge Room',
        },
        open_discussion: {
            'en': 'Go to the open discussion',
            'de': 'Gehe zur offenen Diskussion',
            'es': 'Ir al debate abierto',
            'it': 'Vai alla discussione',
            'fr': 'Aller à la discussion',
        },
        chat_with_faculties: {
            'en': 'Chat live and interact with the faculties',
            'de': 'Live chatten und mit den Referenten interagieren',
            'es': 'Chat en vivo e interactivo con los ponentes',
            'it': 'Chatta dal vivo e interagisci con i faculties',
            'fr': 'Discussion en direct et interaction avec les modérateurs',
        },
        welcome_to: {
            'en': 'Welcome to the Lounge Cafè',
            'de': 'Willkommen im Lounge Cafè',
            'es': 'Bienvenido/a a la Zona de Café',
            'it': 'Benvenuto nella Lounge Cafè',
            'fr': 'Bienvenue au Café Lounge',
        }
    },
    save_the_date: {
        header_title: {
            'en': 'Save the Date in your calendar',
            'de': 'Sichern Sie sich jetzt schon den Termin in Ihrem Kalender!',
            'es': 'Guardar la fecha en su calendario',
            'it': 'Salva la data nel tuo calendario',
            'fr': 'Enregistrer la date dans votre calendrier',
        },
        header_subtitle: {
            'en': '',
            'de': '',
            'es': '',
            'it': '',
            'fr': '',
        },
    },
    video: {
        program: {
            'en': 'View Program',
            'de': 'Programm',
            'es': '',
            'it': 'Programma',
            'fr': '',
        }
    },
    chat: {
        header_title: {
            'en': 'Live question',
            'de': 'Live Fragen',
            'es': 'Preguntas en vivo',
            'it': 'Domande Live',
            'fr': 'Questions en direct',
        },
        header_subtitle: {
            'en': 'Lorem ipsus dolor sit amet',
            'de': '',
            'es': '',
            'it': '',
            'fr': '',
        },
        textbox_placeholder: {
            'en': 'Type in your questions here to interact live…',
            'de': 'Stellen Sie hier Ihre Fragen live... (max. 500 zeichen)',
            'es': 'Escribe tus preguntas aquí para interactuar en vivo...',
            'it': 'Scrivi la tua domanda qui per interagire con la live...',
            'fr': 'Écrivez votre question ci-dessous pour interagir avec la Live...',
        },
        check_anonym_message: {
            'en': 'Send anonymous message',
            'de': 'Frage anonym stellen',
            'es': 'Enviar mensaje anónimo',
            'it': 'Invia messaggio anonimo',
            'fr': 'Envoyez un message anonyme',
        },
        check_top_rated: {
            'en': 'Top rated first',
            'de': 'Beliebtestes zuerst',
            'es': 'Más valorado',
            'it': 'Più votati',
            'fr': 'Les mieux notés',
        },
        check_most_recent: {
            'en': 'Most recent first',
            'de': 'Neuest zuerst',
            'es': 'Más recientes',
            'it': 'Più recenti',
            'fr': 'Plus récentes',
        },
    },
    agenda: {
        header_title: {
            'en': 'Live agenda',
            'de': 'Live Programm',
            'es': 'Agenda en vivo',
            'it': 'Agenda Live',
            'fr': 'Agenda en direct',
        },
        header_subtitle: {
            'en': 'Please select the date:',
            'de': 'Bitte wählen Sie das Datum aus:',
            'es': 'Seleccionar una fecha',
            'it': 'Selezionare una data',
            'fr': 'Choisir une date',
        },
    },
    polls: {
        header_title: {
            'en': 'LIVE POLLS',
            'de': 'LIVE ABSTIMMUNG',
            'es': 'SONDEO EN VIVO',
            'it': 'SONDAGGIO LIVE',
            'fr': 'Sondage en direct',
        },
        header_subtitle: {
            'en': '',
            'de': '',
            'es': '',
            'it': '',
            'fr': '',
        },
    },
    survey: {
        header_title: {
            'en': 'Course evaluation',
            'de': 'Kursbewertung',
            'es': 'Evaluación curso',
            'it': 'Valutazione corso',
            'fr': 'Évaluation cours',
        },
        submit_button: {
            'en': 'Submit test',
            'de': 'Bewertung absenden',
            'es': 'Envía test',
            'it': 'Invia test',
            'fr': 'Envoyez test',
        },
        check_answers_title: {
            'en': 'Test not completed',
            'de': 'Test nicht abgeschlossen',
            'es': 'Test incompleto',
            'it': 'Test non completato',
            'fr': 'Test pas complété',
        },
        check_answers_content: {
            'en': 'Please answer all the questions before submitting',
            'de': 'Bitte beantworten Sie alle Fragen vor dem Absenden',
            'es': 'Por favor responder a todas las preguntas antes de proceder con el envío.',
            'it': 'Per favore risponi a tutte le domande prima di procedere con l\'invio.',
            'fr': 'S\'il vous plaît répondre à toutes les questions avant de procéder à l\'envoi.',
        },
        check_answers_button: {
            'en': 'Close',
            'de': 'schließen',
            'es': 'Cerrado',
            'it': 'Chiudi',
            'fr': 'Fermer',
        },
        test_submitted_title: {
            'en': 'Test submitted',
            'de': 'Test submitted',
            'es': 'Test enviado',
            'it': 'Test inviato',
            'fr': 'Test envoyé',
        },
        test_submitted_content: {
            'en': 'You answered correctly #userCorrectAnswersNumber question out of #correctAnswersNumber.',
            'de': 'You answered correctly #userCorrectAnswersNumber question out of #correctAnswersNumber.',
            'es': 'Has respondido correctamente a #userCorrectAnswersNumber preguntas de #correctAnswersNumber.',
            'it': 'Hai risposto correttamente a #userCorrectAnswersNumber domande su #correctAnswersNumber.',
            'fr': 'Vous avez répondu correctement à  #userCorrectAnswersNumber questions sur #correctAnswersNumber.',
        },
        test_submitted_content2: {
            'en': 'You can download the <b>certificate</b> if you answer correctly at least #quizPercentageSuccess% of the questions.',
            'de': 'You can download the <b>certificate</b> if you answer correctly at least #quizPercentageSuccess% of the questions.',
            'es': 'Puedes descargar el <b>certificato</b> si has respondido correctamente al #quizPercentageSuccess% de las preguntas como mínimo.',
            'it': 'Puoi scaricare il <b>certificato</b> se hai risposto correttamente ad almeno il #quizPercentageSuccess% delle domande.',
            'fr': 'Vous pouvez télécharger le <b>certificato</b> si vous avez répondu correctement à au moins #quizPercentageSuccess% des questions.',
        },
        test_submitted_button1: {
            'en': 'Try again',
            'de': 'Try again',
            'es': 'Reintentar',
            'it': 'Riprova',
            'fr': 'Veuillez réessayer',
        },
        test_submitted_button2: {
            'en': 'See correct answers',
            'de': 'See correct answers',
            'es': 'Ver las respuestas correctas',
            'it': 'Guarda le risposte corrette',
            'fr': 'Voir les réponses correctes',
        },
        test_completed_title: {
            'en': 'Test completed',
            'de': 'Test completed',
            'es': 'Test completado',
            'it': 'Test completato',
            'fr': 'Test complété',
        },
        test_completed_content: {
            'en': 'You answered correctly #userCorrectAnswersNumber question out of #correctAnswersNumber.',
            'de': 'You answered correctly #userCorrectAnswersNumber question out of #correctAnswersNumber.',
            'es': 'Has respondido correctamente a #userCorrectAnswersNumber preguntas de #correctAnswersNumber.',
            'it': 'Hai risposto correttamente a #userCorrectAnswersNumber domande su #correctAnswersNumber.',
            'fr': 'Vous avez répondu correctement à  #userCorrectAnswersNumber questions sur #correctAnswersNumber.',
        },
        test_completed_button1: {
            'en': 'Download your certificate',
            'de': 'Download your certificate',
            'es': 'Descarga tu certificado',
            'it': 'Scarica il tuo certificato',
            'fr': 'Téléchargez le certificat',
        },
        test_completed_button2: {
            'en': 'Close',
            'de': 'Close',
            'es': 'Cerrar',
            'it': 'Chiudi',
            'fr': 'Fermer',
        },
    },
    policy: {
        by_registering: {
            'en': 'By registering to this event please note that you will be also registered to',
            'de': 'Wenn Sie sich für diese Veranstaltung anmelden, beachten Sie bitte, dass Sie auch für',
            'es': 'Al registrarse en este evento tenga en cuenta que será también registrado en',
            'it': 'Registrandoti a questo evento sarai automaticamente registrato anche a',
            'fr': 'En vous inscrivant à cet évènement, veuillez noter que vous serez automatiquement inscrit sur notre plateforme',
        },
        consent_to_receive: {
            'en': 'I consent to receive Zimmer Biomet Institute Network newsletter, which includes information on new and upcoming contents on the platform and attended or missed webinar emails.					',
            'de': 'Ich bin damit einverstanden, den Newsletter des Zimmer Biomet Institute Network zu erhalten, der Informationen über neue und kommende Inhalte auf der Plattform sowie E-Mails über besuchte oder verpasste Webinare enthält.',
            'es': 'Consiento en recibir las newsletter de Zimmer Biomet Institute, que incluye información en novedades y próximos contenidos en la plataforma y webinars a los que asistí o no tuve la oportunidad de participar.',
            'it': 'Acconsento di ricevere da Zimmer Biomet Institute Network newsletter, che includono avvisi  ed anteprime su  contenuti ed eventi che saranno disponibili sulla piattaforma',
            'fr': 'J\'accepte de recevoir la newsletter de Zimmer Biomet Institute Network qui propose des informations concernant les contenus nouveaux et à venir disponibles sur la plateforme ainsi que les liens des webinaires auxquels j\'ai assisté ou manqué.',
        },
        if_already: {
            'en': 'If you have already a <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a> account please click directly on login',
            'de': 'Wenn Sie bereits ein <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a> Konto haben, klicken Sie bitte direkt auf Login',
            'es': 'Si ya tiene una cuenta en <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a> por favor haga clic aquí para directamente realizar el login',
            'it': 'Se hai già un account <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a> verrai indirizzato direttamente alla piattaforma cliccando su accedi',
            'fr': 'Si vous avez déjà un compte <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a>, merci de cliquer sur "login"',
        },
        thank_you: {
            'en': 'Thank you',
            'de': 'Danke',
            'es': 'Gracias',
            'it': 'Grazie',
            'fr': 'Merci',
        },
    },
    breakout_room: {
        breakout_list_title: {
            'en': 'Choose your Breakout room',
            'de': 'Wählen Sie Ihren Breakout-Room',
            'es': 'Elija su Sala',
            'it': 'Secgli la tua Breaout room',
            'fr': 'Choisir votre salle de session',
        },
        breakout_bookings_title: {
            'en': 'Book now your breakout room',
            'de': '',
            'es': '',
            'it': '',
            'fr': '',
        },
        help: {
            'en': 'Help',
            'de': 'Hilfe',
            'es': 'Ayuda',
            'it': 'Aiuto',
            'fr': 'Aide',
        },
        help_title: {
            'en': 'Breakout room assistance',
            'de': 'Hilfe mit der Breakout-Room',
            'es': 'Asistencia Breakout Room',
            'it': 'Assistenza breakout room',
            'fr': 'Assistance Breakout Room',
        },
        help_text: {
            'en': 'If you have any trouble joining the meeting, please click the following button to open the breakout room directly in the Zoom App.',
            'de': 'Wenn Sie Probleme haben, dem Meeting beizutreten, klicken Sie bitte auf die folgende Schaltfläche, um den Breakout-Room direkt in der Zoom-App zu öffnen.',
            'es': 'Si tienes problemas para acceder a la reunión, por favor haga clic en el siguiente enlace para abrir la Breakout Room directamente en la aplicación de Zoom.',
            'it': 'Se hai dei problemi nell\'accedere al meeting, per favore clicca sul seguente link per aprire la breakout room direttamente nell\'app di Zoom.',
            'fr': 'Que vous rencontriez des problèmes pour accéder à la réunion, s’il vous plaît cliquez sur le lien suivant pour ouvrir la Breakout Room directement dans l’application de Zoom.',
        },
        open_in_the_app: {
            'en': 'Open in the Zoom App',
            'de': 'In der Zoom-App öffnen',
            'es': 'Abrir en la aplicación de Zoom',
            'it': 'Apri nell\'app di Zoom',
            'fr': 'Ouvrir dans l\'application de Zoom',
        }
    },
    faculty_area: {
        welcome_to: {
            'en': 'Welcome to the Speaker Room',
            'de': 'Willkommen im Sprecherraum',
            'es': 'Bienvenido a la sala de los speaker',
            'it': 'Benvenuto nella stanza degli speaker',
            'fr': 'Bienvenue chez la salle des orateurs',
        },
        tips: {
            'en': 'Tips for being comfortable on stage',
            'de': 'Tipps zum Wohlfühlen auf der Bühne',
            'es': 'Trucos para sentirse cómodo en el escenario',
            'it': 'Consigli per sentirsi a proprio agio in scena',
            'fr': 'Conseils pour être à l\'aise sur scène',
        },
        description_text: {
            'en': 'Running a digital event can be a daunting task, but it doesn’t have to be.<br>There are some great tips and best practices that will help you to get prepared to it.<br><br>Watch the tutorials below to learn more.',
            'de': 'Die Durchführung eines digitalen Events kann ziemlich herausfordernd sein, muss es aber nicht.<br>Wir haben einige gute Tipps und Best Practices für Sie zusammengestellt, die Ihnen bei Ihrer Vorbereitung helfen können.<br><br>Sehen Sie sich die folgenden Tutorials an, um mehr zu erfahren.',
            'es': 'Gestionar un evento digital puede ser un tárea desalentadora, pero no tiene por qué serlo. Hay algunos buenos trucos y consejos que le ayudará a prepararse para ello. Ver los tutoriales de abajo para aprender más',
            'it': 'Gestire un evento digitale può essere un compito arduo, ma non deve esserlo.<br>Ci sono alcuni ottimi suggerimenti e best practice che ti aiuteranno a prepararti.<br><br>Guarda i tutorial qui sotto per saperne di più.',
            'fr': 'Gérer un événement numérique peut être une tâche difficile, mais ça ne doit pas l\'être. Voici quelques bons conseils et bonnes pratiques qui vous aideront à vous y préparer. Regardez les tutoriels ci-dessous pour en savoir plus.',
        },
        get_prepared_digital_event: {
            'en': 'Get prepared for your digital event',
            'de': 'Bereiten Sie sich auf Ihr digitales Event vor',
            'es': 'Prepararse para su evento digital',
            'it': 'Preparati al tuo evento digitale',
            'fr': 'Préparez-vous à votre événement digital',
        },
        use_of_the_backstage_chat: {
            'en': 'Join the live session via Zoom',
            'de': 'Nehmen Sie an der Live-Sitzung über Zoom teil',
            'es': 'Unirse a la sesión en vivo vía Zoom',
            'it': 'Collegati alla sessione in diretta con Zoom',
            'fr': 'Rejoignez la session en direct via Zoom',
        },
        join_live_session_zoom: {
            'en': 'Use of the Backstage Chat',
            'de': 'Nutzung des Backstage-Chats',
            'es': 'Uso del chat de backstage',
            'it': 'Utilizzo della chat di backstage',
            'fr': 'Utilisation du chat de backstage',
        }
    },
    onDemand_area: {
        welcome_to: {
            'en': 'Welcome to the On-Demand Room',
            'de': 'Willkommen im On-Demand-Room',
            'es': 'Bienvenido a la sala On-Demand',
            'it': 'Benvenuto nella stanza On-Demand',
            'fr': 'Bienvenue chez la salle On Demand',
        },
        welcome_description: {
            'en': 'You missed something during your event, explore it at a time and place that suits you.',
            'de': 'Sie haben während Ihrer Veranstaltung etwas verpasst, erkunden Sie es zu einem Zeitpunkt und an einem Ort, der zu Ihnen passt.',
            'es': 'Si te has perdido algo durante tu evento, explórelo en cualquier momento y lugar que prefrieras.',
            'it': 'Se ti sei perso qualcosa durante il tuo evento, esploralo in qualsiasi momento e luogo che preferisci.',
            'fr': 'Si vous avez manqué quelque chose pendant votre événement, explorez-le à tout moment et n\'importe où vous voulez.',
        },
        your_library: {
            'en': 'Your media library',
            'de': 'Ihre Bibliothek',
            'es': 'Tu biblioteca',
            'it': 'La tua biblioteca',
            'fr': 'Votre bibliothèque',
        },
        library_description: {
            'en': 'From here you have access to the entire registration of the event.',
            'de': 'Von hier aus haben Sie Zugriff auf die gesamte Registrierung der Veranstaltung.',
            'es': 'Desde aquí tienes acceso a todo el catálogo de las grabaciones de los eventos.',
            'it': 'Da qui hai l\'accesso all\'intero catalogo delle registrazioni degli eventi',
            'fr': 'De là, vous avez accès à l’intégralité du catalogue des enregistrements des événements',
        },
        library_description2: {
            'en': 'In case it’s no longer available, please visit <a href="https://www.zbinetworkemea.com/" target="_blank" style="color:#0081c9;">zbinetwork</a> Event Recordings.',
            'de': 'Falls es nicht mehr verfügbar ist, besuchen Sie bitte zbinetwork Event Recordings.',
            'es': 'En caso de que ya no esté disponible, por favor visite <a href="https://www.zbinetworkemea.com/" target="_blank" style="color:#0081c9;">zbinetwork</a> Event Recordings.',
            'it': 'In caso non fosse più disponibile, visita per cortesia <a href="https://www.zbinetworkemea.com/ target="_blank" style="color:#0081c9;">zbinetwork</a> Event Recordings.',
            'fr': 'Au cas où il ne serait plus disponible, visitez <a href="https://www.zbinetworkemea.com/ target="_blank" style="color:#0081c9;">zbinetwork</a> Event Recordings.',
        },
        select_day: {
            'en': 'Select day',
            'de': 'Tag auswählen',
            'es': 'Seleccionar una fecha',
            'it': 'Seleziona il giorno',
            'fr': 'Choisir une date',
        },
        order_by: {
            'en': 'Order By',
            'de': 'Sortieren nach',
            'es': 'Ordenar por',
            'it': 'Ordina per',
            'fr': 'Trier par',
        },
        date: {
            'en': 'Date',
            'de': 'Datum',
            'es': 'Fecha',
            'it': 'Data',
            'fr': 'Date',
        },
        most_watched: {
            'en': 'Most Watched',
            'de': 'Meist gesehen',
            'es': 'Más visto',
            'it': 'Più visto',
            'fr': 'Plus regardé',
        }
    },
    achievements_info: {
        title: {
            'en': 'ENGAGEMENT',
            'de': 'ENGAGEMENT',
            'es': 'ENGAGEMENT',
            'it': 'ENGAGEMENT',
            'fr': 'ENGAGEMENT',
        }
    },
    error: {
        unauthorized: {
            'en': 'Unauthorized',
            'de': 'Nicht autorisiert',
            'es': 'No autorizado',
            'it': 'Non autorizzato',
            'fr': 'Non autorisé',
        },
        forbidden: {
            'en': 'Forbidden',
            'de': 'Verboten',
            'es': 'Prohibido',
            'it': 'Proibito',
            'fr': 'Interdit',
        },
        network_error: {
            'en': 'Network error. Please check your internet connection and try again.',
            'de': 'Netzwerkfehler. Bitte überprüfen Sie Ihre Internetverbindung und versuchen Sie es erneut.',
            'es': 'Error de red. Comprueba tu conexión a Internet y intenta de nuevo.',
            'it': 'Errore di rete. Per favore controlla la tua connessione Internet e prova di nuovo.',
            'fr': 'Erreur réseau. Veuillez vérifier votre connexion Internet et réessayer.',
        },
        generic_error: {
            'en': 'An error has occurred. Please try again later.',
            'de': 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
            'es': 'Ha ocurrido un problema. Inténtelo más tarde.',
            'it': 'Si è verificato un problema. Riprovare più tardi',
            'fr': 'Une erreur est survenue. Réessayer plus tard.',
        },
        password_match: {
            'en': 'The password and retype password fields doesn\'t match',
            'de': 'Die Felder "Passwort" und "Passwort wiederholen" stimmen nicht überein',
            'es': 'Los campos "Contraseña" y "Rescribir contraseña" no corresponden.',
            'it': 'I campi "Password" e "Riscrivi password" non corrispondono',
            'fr': 'Les champs "Mot de passe" et "Modifier le mot de passe" ne correspondent pas',
        },
        invalid_email: {
            'en': 'Invalid email',
            'de': 'Ungültige E-Mail',
            'es': 'Dirección de correo electrónico no válida',
            'it': 'E-mail non valido',
            'fr': 'L\'adresse électronique n\'est pas valide.',
        },
        required: {
            'en': 'This field is required',
            'de': 'Dieses Feld wird benötigt',
            'es': 'This field is required',
            'it': 'Questo campo è obbligatorio',
            'fr': 'This field is required',
        },
        password_short: {
            'en': 'Password too short',
            'de': 'Passwort zu kurz',
            'es': 'Password too short',
            'it': 'Password troppo corta',
            'fr': 'Password too short',
        },
        password_long: {
            'en': 'Password too long',
            'de': 'Passwort zu lang',
            'es': 'Password too long',
            'it': 'Password troppo lunga',
            'fr': 'Password too long',
        },
    },
    event_step_manager: {
        current_step: {
            'en': 'Redirecting to:',
            'de': '',
            'es': '',
            'it': 'Reindirizzamento:',
            'fr': '',
        },
        auto_redirect: {
            'en': 'You will be redirected in',
            'de': '',
            'es': '',
            'it': 'Sarai reindirizzato tra',
            'fr': '',
        },
        manual_redirect: {
            'en': 'Skip >>',
            'de': '',
            'es': '',
            'it': 'Salta >>',
            'fr': '',
        }
    },
}