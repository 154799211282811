import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useEventListener from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";
import React, { useEffect, useState } from "react";

const InPersonPopUp = () => {
    const [isOpen, setIsOpen] = useState(false);
    useEventListener('digi-pad', () => setIsOpen(true));
    return (
        <>
            <div style={isOpen ? { display: 'block' } : { display: 'none' }} className="in-person-pop-up">
                <FontAwesomeIcon style={isOpen ? { display: 'block' } : { display: 'none' }} className="in-person-pop-up__close" size="2x" icon={faTimes} onClick={() => setIsOpen(false)} />
                <iframe style={isOpen ? { display: 'block' } : { display: 'none' }} src={process.env.NODE_ENV === "development" ? `http://localhost:3000/digi-pad?token=${window.storageGetItemValue("Auth-token")}` : `/digi-pad`}
                    allow="microphone; camera;" allowFullScreen={true} frameBorder="0" className="in-person-live-single-tool__iframe" />
            </div>
        </>
    )
}

export default InPersonPopUp;