import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight, faCaretLeft, faSearchPlus, faSearchMinus, faTimes, faFilePdf, faFileVideo, faFileAudio, faFileImage, faLink, faFileArchive, faUndo } from '@fortawesome/free-solid-svg-icons'
import axiosInstance from '../../config/axios';
import { ResourcesTypeRes } from '../../Models/ResponseModels/Resources';
import { ContextAppSettings } from '../../config/context';
import ReactHtmlParser from 'react-html-parser'
import { translations } from '../../config/translations';
import { objToQueryParams } from '../../config/functions';
import { Document, Page } from 'react-pdf/dist/esm/entry.webpack';
// using ES6 modules
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// using CommonJS modules
import 'react-pdf/dist/umd/Page/AnnotationLayer.css';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import useCurrentWidth from '../../Components/_hooks/useCurrentWidth';
import Button from '../../Components/_UI/Button/Button';

interface IProps {
    resourceCategoryID: number,
    setShowRelatedDocuments: Function,
    setShowRelatedContentInfo?: Function,
    inColumn?: boolean,
    hideHeader?: boolean,
}

const RelatedDocuments = ({ resourceCategoryID, setShowRelatedDocuments, setShowRelatedContentInfo }: IProps) => {
    const [resources, setResources] = useState<ResourcesTypeRes.IResource[]>([]);
    const [selectedResource, setSelectedResource] = useState<ResourcesTypeRes.IResource | null>(null);
    const { language } = useContext(ContextAppSettings);

    useEffect(() => {
        if (resourceCategoryID > 0) {
            let data = {
                sortField: "order",
                limit: 999,
            }
            let querystring = objToQueryParams(data);
            axiosInstance.get(`Resources/Category/${resourceCategoryID}/Resources${querystring}`).then(res => {
                const response = res.data;
                if (response.status === "success") {
                    let _resources: ResourcesTypeRes.IResource[] = response.data.collection;
                    if (_resources && _resources.length > 0) {
                        setResources(_resources);
                        if (_resources[0].resourceTypeID !== "LINK")
                            setSelectedResource(_resources[0]);
                        if (setShowRelatedContentInfo) {
                            setShowRelatedContentInfo(_resources.length > 0);
                        }
                    }
                }
            }).catch(e => {
                console.log("Couldn't retrive resources", e)
            });
        }
    }, [resourceCategoryID])

    useEffect(() => {
        if (selectedResource?.resourceTypeID == "LINK") {
            window.open(selectedResource.link);
        }
        if (selectedResource?.resourceTypeID == "ARCHIVE") {
            window.open(window.apiURL + "Resources/" + selectedResource.resourceID + "/Visualize/")
        }
    }, [selectedResource])

    interface IPropsRI { resource: ResourcesTypeRes.IResource }
    const ResourceItem = ({ resource }: IPropsRI) => {

        let icon;
        switch (resource.resourceTypeID) {
            case "DOCUMENT": icon = faFilePdf;
                break;
            case "AUDIO": icon = faFileAudio;
                break;
            case "IMAGE": icon = faFileImage;
                break;
            case "VIDEO": icon = faFileVideo;
                break;
            case "ARCHIVE": icon = faFileArchive;
                break;
            case "LINK": icon = faLink;
                break;
            default: icon = faLink;
                break;
        }

        return (
            <div className={"related-documents-item-wrapper" + (resource.resourceID === selectedResource?.resourceID ? " selected" : "")}
                onClick={() => setSelectedResource(resource)}>
                <div className="related-documents-item d-flex align-items-center">
                    <FontAwesomeIcon className={"related-documents-item__icon" + (resource.resourceID === selectedResource?.resourceID ? " selected" : "")}
                        icon={icon} />
                    <p className="u-font-size-8 m-0 ml-2 related-documents-item__title">{resource.title}</p>
                </div>
            </div>
        )
    }

    return (
        <div className="related-documents-wrapper background-glass h-100 p-3">
            <header className="related-documents-header">
                <h1 className="related-documents-header__title u-font-size-18 text-center font-weight-bold">
                    {resources && resources[0] ? resources[0].resourceCategory.resourceCategoryName : translations.event.related_documents[language]}
                </h1>
                <div className="fab-btn" onClick={() => setShowRelatedDocuments(false)}>
                    <FontAwesomeIcon className="close-btn" icon={faTimes} />
                </div>
                {/* <FontAwesomeIcon icon={faTimes} size="lg" className="related-documents-header__close" onClick={() => setShowRelatedDocuments(false)} /> */}
                <hr className="related-documents__separator w-100" />
            </header>
            {
                resources && resources.length > 0 &&
                <div className="related-documents">
                    <div className="related-documents-list-wrapper">
                        <div className="related-documents-list">
                            {
                                resources.map(x => {
                                    return <ResourceItem resource={x} />
                                })
                            }
                        </div>
                    </div>
                    <div className="related-documents-viewer glass w-100">
                        {
                            selectedResource && selectedResource.resourceTypeID === "DOCUMENT" &&
                            <div className="related-documents-pdf-viewer-wrapper h-100">
                                <PdfViewer selectedResource={selectedResource} />
                            </div>
                        }
                        {
                            selectedResource && selectedResource.resourceTypeID === "VIDEO" &&
                            <div className="related-documents-video-viewer-wrapper container-video container-vm-iframe">
                                {ReactHtmlParser(selectedResource.link)}
                            </div>
                        }
                        {
                            selectedResource && selectedResource.resourceTypeID === "IMAGE" &&
                            <div className="related-documents-image-viewer-wrapper">
                                <img src={window.apiURL + "Resources/" + selectedResource?.resourceID + "/Visualize/"}
                                    className="related-documents-image" />
                            </div>
                        }
                    </div>
                </div>
                //:
                //no resource
            }
        </div>
    )
}

interface IPdfViewerProps {
    selectedResource: ResourcesTypeRes.IResource,
}

export const PdfViewer = ({ selectedResource }: IPdfViewerProps) => {
    const [numPages, setNumPages] = useState<number | null>(null);
    const [pageNumber, setPageNumber] = useState(1);
    const [docHeight, setDocHeight] = useState<number | undefined>();
    const [docWidth, setDocWidth] = useState<number | undefined>();
    let width = useCurrentWidth();
    const [zoom, setZoom] = useState(1);

    function onDocumentLoadSuccess(numPages: number) {
        console.log("numPages", numPages)
        setNumPages(numPages);
    }

    function onPageLoadSuccess(page: any) {
        let container = document.getElementsByClassName("related-documents-viewer");
        if (container && container[0]) {

            if (page.originalHeight > page.originalWidth) {
                setDocHeight(container[0].clientHeight - 70);
                setDocWidth(undefined);
            }
            else {
                if (container[0].clientWidth > 800)
                    setDocWidth(800);
                else
                    setDocWidth(container[0].clientWidth);
                setDocHeight(undefined);
            }
        }
    }

    function previousPage() {
        setPageNumber(pageNumber - 1);
    }

    function nextPage() {
        setPageNumber(pageNumber + 1);
    }

    return (
        <div className="pdf-viewer d-flex flex-column h-100">
            <TransformWrapper
                initialScale={1}
                initialPositionX={0}
                initialPositionY={0}
                maxScale={2}
                onWheel={(e) => { console.log("onWheel", e); setZoom(e.state.scale); }}
                onPinching={(e) => { console.log("onPinching", e); setZoom(e.state.scale); }}
            >
                {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
                    <React.Fragment>
                        <TransformComponent>
                            <Document

                                file={window.apiURL + "Resources/" + selectedResource?.resourceID + "/Visualize/?token=" + window.storageGetItemValue("Auth-token")}
                                onLoadSuccess={(pdf) => {
                                    setTimeout(() => {
                                        centerView();
                                        resetTransform();
                                    }, 100);
                                    console.log("onLoadSuccess - pdf", pdf)
                                    console.log("onLoadSuccess - pdf numPages", pdf.numPages)
                                    onDocumentLoadSuccess(pdf.numPages);
                                }}
                            >
                                <Page pageNumber={pageNumber} width={docWidth} height={docHeight} scale={zoom} onLoadSuccess={(page) => onPageLoadSuccess(page)} />
                            </Document>
                        </TransformComponent>
                        <div className="pdf-viewer-footer m-2">
                            <div className="pdf-viewer-footer__buttons">
                                <Button
                                    withClass={["small", "blue", "left"]}
                                    clicked={() => window.open(window.apiURL + "Resources/" + selectedResource?.resourceID + "/Visualize/?token=" + window.storageGetItemValue("Auth-token"))}
                                >Open in a new tab</Button>
                            </div>
                            <div className="pdf-viewer-footer__pages d-flex justify-content-center align-items-center">
                                {
                                    pageNumber > 1 &&
                                    <FontAwesomeIcon className="related-documents-fa-icon mr-2 cursor-pointer" icon={faCaretLeft} size="2x" onClick={() => previousPage()} />
                                }
                                <span>Page {pageNumber} of {numPages}</span>
                                {
                                    numPages && pageNumber < numPages &&
                                    <FontAwesomeIcon className="related-documents-fa-icon ml-2 cursor-pointer" icon={faCaretRight} size="2x" onClick={() => nextPage()} />
                                }
                            </div>
                            <div className="pdf-viewer-footer__zoom d-flex justify-content-center align-items-center">
                                <FontAwesomeIcon className="related-documents-fa-icon cursor-pointer" icon={faSearchPlus} size="2x" onClick={() => { zoomIn(); setZoom(zoom < 2 ? zoom + 0.25 : zoom) }} />
                                <FontAwesomeIcon className="related-documents-fa-icon ml-2 cursor-pointer" icon={faSearchMinus} size="2x" onClick={() => { zoomOut(); setZoom(zoom > 1 ? zoom - 0.25 : zoom) }} />
                                <FontAwesomeIcon className="related-documents-fa-icon ml-2 cursor-pointer" icon={faUndo} size="2x" onClick={() => resetTransform()} />
                            </div>
                        </div>
                    </React.Fragment >
                )}
            </TransformWrapper >
        </div >
    );
}

export default RelatedDocuments;