import React from 'react';
import Background from '../../Background/Background';

const PageNotFound: React.FC = () => {

    return (
        <>
            <Background defaultBackground="/assets/images/bg-palace.jpg"></Background>
            <div className="page-not-found-wrapper">
                <div className="page-not-found text-center">
                    <h1 className="page-not-found__title">403</h1>
                    <h2 className="page-not-found__subtitle">Page forbidden</h2>
                    <h3 className="page-not-found__descr u-font-size-14">Sorry, but you don't currently have permission to access this page.</h3>
                </div>
            </div>
        </>
    );
}

export default PageNotFound;