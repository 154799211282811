/*
#IMPORTANT
 - Create an history object in an external file
 - Wrap it in <Router> (es. src\index.tsx)
    (please note, you should use import { Router } instead of import { BrowserRouter as Router })
 - Change your current location from any place (es. src\Components\ChangePassword\ChangePassword.tsx )
*/

// import createHistory from 'history/createBrowserHistory';
const createHistory = require("history").createBrowserHistory;

const history = createHistory({
    basename: process.env.PUBLIC_URL
});

export default history;