import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import { Card, CardContent } from "./Plugins/Card";
import { SettingsPanel } from "./SettingsPanel";
import ToolBox from "./ToolBox";
import { TopBar } from "./TopBar";
import { Editor, Frame, Element } from "@craftjs/core";
import { Button } from "./Plugins/Button";
import { Text } from "./Plugins/Text";
import { PageTitle, PageTitleText } from "./Plugins/PageTitle";
import { Container } from "./Plugins/Container";
import { ContainerLayout } from "./Plugins/ContainerLayout";
import { Carousel } from "./Plugins/Carousel";
import { Video } from "./Plugins/Video";
import { Image } from "./Plugins/Image";
import { CardButton, CardButtonText } from "./Plugins/CardButton";
import "./Editor.scss";
import axiosInstance from "../../../config/axios";
import { ResourcesTypeRes } from "../../../Models/ResponseModels/Resources";
import Treeview, { ISelectedNode } from "./Treeview";
import { dataURLtoFile } from "../../../config/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import ContentEditable from "react-contenteditable";
import { update } from "lodash";
import { showNotification } from "@mixerpa/mpa-react-components.mpa-ui/dist/utilities";
import { callEvent } from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";

interface IProps {
    readonly?: boolean,
    externalResource?: ISelectedNode,
}
const EditorJournal = ({ readonly = false, externalResource }: IProps) => {
    const [data, setData] = useState<string | null>(null);
    const [selectedNode, setSelectedNode] = useState<ISelectedNode | null>(null);
    const [editedNode, setEditedNode] = useState<ISelectedNode | null>(null);

    const [rightPanelOpen, setRightPanelOpen] = useState<boolean | null>(null);
    const [leftPanelOpen, setLeftPanelOpen] = useState<boolean | null>(null);

    useEffect(() => {
        if (externalResource) {
            setSelectedNode(externalResource);
        }
    }, [externalResource])

    useEffect(() => {
        if (selectedNode) {
            if (selectedNode.id > 0) {
                axiosInstance.get(`Resources/${selectedNode.id}/base64`)
                    .then(res => {
                        const response = res.data;
                        let data = JSON.parse(atob((response.data.content as string).split(",")[1]));
                        setData(null)
                        setTimeout(() => {
                            setData(data);
                        }, 1000);
                    })
                    .catch(() => {
                        setData(null)
                        setTimeout(() => {
                            setData("");
                        }, 1000);
                    })
            } else {
                setData(null)
                setTimeout(() => {
                    setData("");
                }, 1000);
            }
        }
        setEditedNode(selectedNode);
    }, [selectedNode])

    async function handleSavePage(base64: string) {
        if (editedNode) {
            // Check page name:
            let resources: ResourcesTypeRes.IResource[] = [];
            await axiosInstance.get(`Resources/Category/${process.env.REACT_APP_IN_PERSON_JOURNAL_CATEGORY_ID}/Resources`).then(res => {
                if (res.data.status === "success") {
                    resources = res.data.data.collection as ResourcesTypeRes.IResource[];
                }
            })
            if (resources.findIndex(x => x.resourceID !== editedNode.id && x.title === editedNode.title) >= 0) {
                showNotification("error", "There is another page with the same title, change the title before saving the current page");
            }
            else {
                let parentUrl = (editedNode.parentUrl as string);
                if (parentUrl && parentUrl.endsWith("/")) {
                    parentUrl = parentUrl.substring(0, parentUrl.length - 1);
                }
                let data = new FormData();
                if (document) {
                    let _file = dataURLtoFile(base64, editedNode.title);
                    data.append('AttachedFile', _file, editedNode.title);
                }
                data.append('AdditionalField01', editedNode.published ? "true" : "false");
                data.append('ResourceTypeID', "DOCUMENT");
                data.append('ResourceCategoryID', process.env.REACT_APP_IN_PERSON_JOURNAL_CATEGORY_ID || "93");
                data.append('Public', 'false');
                data.append('Published', 'true');
                data.append('DeletePreviousFile', 'true');
                data.append('Title', editedNode.title);
                data.append('Subtitle', editedNode.title);
                if (editedNode.isLandingPage) {
                    data.append('Link', `/`);
                } else {
                    data.append('Link', `${parentUrl}/${editedNode.title.toLocaleLowerCase().replace(" ", "-")}`);
                }
                data.append('Description', editedNode.title);
                if (editedNode.isLandingPage) {
                    data.append('FriendlyURL', `/`);
                } else {
                    data.append('FriendlyURL', `${parentUrl}/${editedNode.title.toLocaleLowerCase().replace(" ", "-")}`);
                }
                // Add
                if (editedNode.id === 0) {
                    axiosInstance.post("Resources/", data).then((res) => {
                        if (res.data.status === "success") {
                            handleSuccess("new");
                        }
                        else {
                            showNotification("error", res.data.message);
                        }
                    }).catch((e: any) => {
                        showNotification("error", e)
                    });
                }
                // Edit
                else {
                    data.append('ResourceID', editedNode.id.toString());
                    axiosInstance.put(`Resources/${editedNode.id}`, data).then((res) => {
                        if (res.data.status === "success") {
                            handleSuccess("udpate");
                        }
                        else {
                            showNotification("error", "Couldn't update current page, please try again later");
                        }
                    }).catch((e: any) => {
                        showNotification("error", e)
                    });
                }
            }
        }
    }

    function handleSuccess(op: "new" | "udpate") {
        showNotification("success", `Page ${op === "new" ? "added" : "updated"} successfully`);
        callEvent("postPageEdit");
    }

    return (
        <Editor resolver={{ Card, Button, Text, Container, ContainerLayout, CardContent, Carousel, PageTitle, PageTitleText, Video, CardButton, CardButtonText, Image }}>
            <div className={`journal ${readonly ? "readonly" : ""}`}>

                <TopBar readonly={readonly} handleSavePage={handleSavePage} />

                <div className="editor">
                    <div className="left-panel">
                        <div className={leftPanelOpen ? " open" : ""}>
                            <Treeview selectedNode={editedNode} setSelectedNode={setSelectedNode} />
                            <div className="page-title">
                                {
                                    editedNode ?
                                        <>
                                            <ContentEditable html={editedNode.title} tagName="h4" onChange={(e) => setEditedNode(prev => {
                                                if (prev) {
                                                    let upd = { ...prev };
                                                    upd.title = e.target.value.replace(/<(\s*\/?)[^>]+>/g, "");
                                                    // Create url
                                                    if (!upd.isLandingPage) {
                                                        upd.url = e.target.value.toLocaleLowerCase().replace(" ", "-");
                                                    }
                                                    return upd;
                                                }
                                                return null;
                                            })} />
                                            <div>
                                                <label>Published</label>
                                                <input type={"checkbox"} checked={editedNode?.published} onChange={(e) => setEditedNode(prev => {
                                                    if (prev) {
                                                        let upd = { ...prev };
                                                        upd.published = e.target.checked;
                                                        return upd;
                                                    }
                                                    return null;
                                                })} />
                                            </div>
                                        </>
                                        :
                                        <h4>No page selected</h4>
                                }
                            </div>
                            <ToolBox />
                        </div>

                        <div className={`hamburger-button${leftPanelOpen !== null ? (leftPanelOpen ? " open" : " closed") : ""}`} onClick={() => setLeftPanelOpen(!leftPanelOpen)}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>

                    </div>

                    <div className="center-panel">
                        <div className="page-wrapper">
                            <div className="page">
                                {
                                    editedNode !== null && data !== null &&
                                    <Frame data={data ? data : ""}>
                                        <Element is={Container} canvas anchor={"none"} style={{ gap: '16px', padding: '16px', backgroundColor: '#F0EFF0', flexDirection: "column" }} />
                                    </Frame>
                                }
                            </div>
                        </div>
                    </div>

                    <div className="right-panel">
                        <SettingsPanel open={rightPanelOpen === true} />
                        <div className={`hamburger-button${rightPanelOpen !== null ? (rightPanelOpen ? " open" : " closed") : ""}`} onClick={() => setRightPanelOpen(!rightPanelOpen)}>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>
            </div>
        </Editor>
    )
}

export default EditorJournal;