import React, { useContext, useEffect, useRef, useState } from "react"
import axios from "../../config/axios";
import { ContextAppSettings } from "../../config/context"
import { hideLoader, saveLog, showLoader } from "../../config/functions";
import history from "../../config/history";
import { translations } from "../../config/translations";
import { Enum } from "../../Models/Enum";
import MemberLog from "../../Models/Log/MemberLog";
import { EventsTypeRes } from "../../Models/ResponseModels/Events"
import { MembersTypeRes } from "../../Models/ResponseModels/Members";
import { ZoomTypeRes } from "../../Models/ResponseModels/Zoom";
import useEventListener, { callEvent } from "../_hooks/useEventListener";
import Button from "../_UI/Button/Button";

interface IProps {
    userData: MembersTypeRes.IMember,
    currentEvent: EventsTypeRes.IEvent | null,
}

const defaultTime = 3;

const ManagerEventSteps = ({ userData, currentEvent }: IProps) => {
    const { language } = useContext(ContextAppSettings);
    const [showStepNotification, setShowStepNotification] = useState(false);
    const [remainingTime, setRemainingTime] = useState(0);
    const [currentStep, setCurrentStep] = useState<EventsTypeRes.IEventStep | null>(null)
    const currentStepRef = useRef<EventsTypeRes.IEventStep | null>(null)
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const timeoutRef = useRef<NodeJS.Timeout | null>(null);

    useEventListener("SelectEventStep", (e: CustomEvent<{ selectedStep: EventsTypeRes.IEventStep }>) => {
        if (history.location.pathname.indexOf("events-admin") === -1 && currentEvent && e.detail.selectedStep.eventID === currentEvent.eventID) {
            setCurrentStep(e.detail.selectedStep);
            currentStepRef.current = e.detail.selectedStep;
            if ((currentStepRef.current.eventStepType !== Enum.EventStepType.LiveDryRun && currentStepRef.current.eventStepType !== Enum.EventStepType.ZoomDryRun) || history.location.pathname.indexOf("faculty-panel") > -1) {
                if (currentStepRef.current.eventStepType !== Enum.EventStepType.Live || (currentStepRef.current.eventStepType === Enum.EventStepType.Live && history.location.pathname.indexOf("live-page") === -1)) {
                    if (currentEvent && currentEvent.eventSettings) {
                        saveLog(new MemberLog(history.location.pathname, "event-step-changed", "New step: " + currentStepRef.current.title, "", currentEvent.eventID, navigator.userAgent));
                    }
                    setShowStepNotification(true);
                    setRemainingTime(defaultTime);
                    if (intervalRef.current) {
                        clearInterval(intervalRef.current);
                    }
                    if (timeoutRef.current) {
                        clearTimeout(timeoutRef.current);
                    }
                    intervalRef.current = setInterval(() => {
                        setRemainingTime(prev => {
                            let newValue = prev - 1;
                            if (newValue < 0 && intervalRef.current) {
                                clearInterval(intervalRef.current);
                                handleRedirect();
                            }
                            return newValue;
                        });
                    }, 1000);
                }
            }
        }
    });

    useEffect(() => {
        return () => {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        }
    }, [])

    const handleRedirect = () => {
        if (currentEvent && currentStepRef.current) {
            if (intervalRef.current) {
                clearInterval(intervalRef.current);
            }
            if (currentStepRef.current.eventStepType === Enum.EventStepType.Zoom || currentStepRef.current.eventStepType === Enum.EventStepType.ZoomDryRun) // Zoom breakout rooms:
            {
                showLoader();
                axios.get(`Events/${currentEvent?.eventID}/ZoomMeetings`).then(res => {
                    const response = res.data;
                    if (response.status === 'success') {
                        let meetings: ZoomTypeRes.IZoomMeeting[] = response.data;
                        if (meetings) {
                            // Search for the meeting related to the current step:
                            const currentMeeting = meetings.find(x => currentStepRef.current && x.step === currentStepRef.current.step);
                            if (currentMeeting) {
                                if (history.location.pathname.indexOf("faculty-panel") > -1) {
                                    callEvent("updateFacultyPanelMeeting", currentStepRef.current);
                                } else {
                                    history.push(`/${currentEvent?.eventSettings.eventPath}/breakout-room/${currentMeeting.friendlyURL || currentMeeting.zoomMeetingEventID}`);
                                }
                            }
                        }
                        setShowStepNotification(false);
                        timeoutRef.current = setTimeout(() => {
                            hideLoader();
                        }, 1000);
                    }
                }).catch(e => {
                    console.log(`Events/${currentEvent?.eventID}/ZoomMeetings - Error: `, e)
                    setShowStepNotification(false);
                    timeoutRef.current = setTimeout(() => {
                        hideLoader();
                    }, 1000);
                })
            } else if (currentStepRef.current.eventStepType === Enum.EventStepType.Live || currentStepRef.current.eventStepType === Enum.EventStepType.LiveDryRun) {
                if (history.location.pathname.indexOf("faculty-panel") > -1) {
                    callEvent("updateFacultyPanelMeeting", currentStepRef.current);
                } else {
                    if (history.location.pathname.indexOf("breakout-room") > -1)
                        callEvent("redirectToLivePage");
                    else
                        history.push(`/${currentEvent?.eventSettings.eventPath}/live-page`);
                }
                setShowStepNotification(false);
            }
        }
    }

    return (
        <div className="event-step-notification-container">
            {
                showStepNotification && currentStep &&
                <div className="event-step-notification p-4">
                    <div className="event-step-notification__title mb-2">
                        <h3>{translations.event_step_manager.current_step[language]} {currentStep.title}</h3>
                    </div>
                    <div className="event-step-notification__redirect mb-2">
                        <span>{translations.event_step_manager.auto_redirect[language]}</span>
                        <span>{remainingTime}s</span>
                    </div>
                    <div className="event-step-notification__progress-bar-container mb-4">
                        <div className="event-step-notification__progress-bar" style={{ width: `${(remainingTime / defaultTime) * 100}%` }} />
                    </div>
                    <div className="event-step-notification__button">
                        <Button withClass={["blue", "small"]} classes="font-weight-bold" clicked={() => handleRedirect()}>
                            {translations.event_step_manager.manual_redirect[language]}
                        </Button>
                    </div>
                </div>
            }
        </div>
    )
}

export default ManagerEventSteps;