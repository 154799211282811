import { toast } from 'react-toastify';
import axios from './axios';
import { eventTitle } from './variables';
import MemberLog from '../Models/Log/MemberLog';
import { WebSocketTypeRes } from '../Models/ResponseModels/WebSocketModels';
import moment from 'moment';
import { EventsTypeRes } from '../Models/ResponseModels/Events';
import { EventUtility, ISession } from '../Models/Utility';
import ReactHtmlParser from 'react-html-parser'
import { MembersTypeRes } from '../Models/ResponseModels/Members';
import { callEvent } from '../Components/_hooks/useEventListener';
import JSZip from 'jszip';
import saveAs from 'file-saver';
import { forEach } from 'lodash';

// Use forEach with async functions to wait 
export async function asyncForEach(array: any[], callback: any) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}

// Scroll top to element with specific id
export function scrollToElementWithId(id: string, yOffset: number = 0, container?: any) {
  const element = document.getElementById(id) as HTMLElement;
  if (element) {
    if (container) {
      container.scrollTop = element.offsetTop + yOffset;
    } else {
      const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
      window.scrollTo({ top: y, behavior: 'smooth' });
    }
  }
}

// Detects if mobile device is iOS 
export const isIos = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return /iphone|ipad|ipod/.test(userAgent);
}

// Detects if mobile device
export const isMobileDevice = () => {
  return (typeof window.orientation !== "undefined") || (navigator.userAgent.indexOf('IEMobile') !== -1);
};

// Detects if device is in standalone mode (=> PWA installed)
// @ts-ignore
export const isInStandaloneMode = () => (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone === true);

// Detects if browser is IE
export const isIE = () => {
  const userAgent = window.navigator.userAgent.toLowerCase();
  return userAgent.indexOf("msie ") > -1 || userAgent.indexOf("trident/") > -1;
}


// AddDays to a specific date
export const addDays = (date: Date, numDaysToAdd: number) => {
  var dateNow = new Date();
  var finalDate = dateNow.setDate(date.getDate() + numDaysToAdd);
  return new Date(finalDate);
}

// RemoveDays from a specific date
export const removeDays = (date: Date, numDaysToRemove: number) => {
  var dateNow = new Date();
  var finalDate = dateNow.setDate(date.getDate() - numDaysToRemove);
  return new Date(finalDate);
}


// Remove HTML tags from a string
export const cleanText = (text: string) => {
  return text.replace(/<\/?[^>]+(>|$)/g, "").replace(/&\/?[^]+(;)/g, "");
}

export const truncateText = (text: string, maxChars: number) => {
  let formattedText = text;
  if (text.length >= maxChars) {
    formattedText = text.substr(0, maxChars - 5).concat('...')
  }
  return formattedText;
}

export const formatFileSize = (size: number) => {
  var fSExt = new Array('Bytes', 'KB', 'MB', 'GB');
  var i = 0;
  while (size > 900) {
    size /= 1024;
    i++;
  }

  var exactSize = (Math.round(size * 100) / 100) + ' ' + fSExt[i];
  return exactSize;
}

export const saveLog = (data: MemberLog, async = true) => {
  if (async) {
    axios.post('Logs/SaveLog', data).then(res => console.log).catch(e => console.log(e));
  }
  else {
    const token = window.storageGetItemValue("Auth-token");
    var xhr = new XMLHttpRequest();
    xhr.open('POST', window.apiURL + 'Logs/SaveLog', false);
    xhr.setRequestHeader("Content-type", "application/json")
    xhr.setRequestHeader("Authorization", token);
    xhr.send(JSON.stringify(data));
  }
}


// loader
const loader: any = document.getElementById('page-loader');

let timer: NodeJS.Timeout;
export function showLoader() {
  clearTimeout(timer);
  loader.classList.remove('available');
}

export function hideLoader() {
  if (timer) {
    clearTimeout(timer);
  }
  timer = setTimeout(() => {
    loader.classList.add('available');
  }, 300);
}

export function forceHideLoader() {
  if (timer) {
    clearTimeout(timer);
  }
  loader.classList.add('available');
}

// Validate fields

export const validateEmail = (email: string) => {
  if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(email))
    return true;
  return false;
}

export const validatePassword = (psw: string) => {
  if (psw.length >= 8 && psw.length <= 50)
    return true
  return false
}


// CREATE AND DOWNLOAD CSV FILE


// Create file from data and download it
// * data: json
// * filename: name.ext
// * type: type of the file to download
export function downloadDataIntoCsv(data: any, filename: string, type: string) {
  var fileData = convertJsonToCsv(data);
  var file = new Blob(["\ufeff" + fileData], { type: type });
  {
    var a = document.createElement("a"),
      url = URL.createObjectURL(file);
    a.href = url;
    a.download = filename;
    document.body.appendChild(a);
    a.click();
    setTimeout(function () {
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    }, 0);
  }
}

// Receive a json and returns a string in csv format
function convertJsonToCsv(json: any) {
  const JOINCHAR = ';';
  var fields = Object.keys(json[0])
  var replacer = function (key: any, value: any) { return value === null ? '' : value }
  var csv = json.map(function (row: any) {
    return fields.map(function (fieldName) {
      return JSON.stringify(row[fieldName], replacer)
    }).join(JOINCHAR)
  })
  csv.unshift(fields.join(JOINCHAR)) // add header column
  return (csv.join('\r\n'));
}


// Download file from assets
export function downloadFile(filename: string, file: string) {
  var a = document.createElement("a");
  a.href = file;
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  setTimeout(function () {
    document.body.removeChild(a);
  }, 0);
}


// Force portrait or landscape when printing
export function forcePagePrintDirection(size: string) {
  var css = '@page { size: ' + size + ' }',
    head = document.head || document.getElementsByTagName('head')[0],
    style = document.createElement('style');

  style.type = 'text/css';
  style.media = 'print';

  //@ts-ignore
  if (style.styleSheet) {
    //@ts-ignore
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  head.appendChild(style);
}


/* COOKIES */

// Set cookie
export function setCookie(name: string, value: string, hours: number) {
  var expires = "";
  if (hours) {
    var date = new Date();
    date.setTime(date.getTime() + (hours * 60 * 60 * 1000));
    expires = "; expires=" + date.toUTCString();
  }
  document.cookie = name + "=" + (value || "") + expires + "; path=/";
}

// Get cookie
export function getCookie(name: string) {
  var nameEQ = name + "=";
  var ca = document.cookie.split(';');
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) === ' ') c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}

// Remove cookie
export function removeCookie(name: string) {
  document.cookie = name + '=; Max-Age=-99999999;';
}

// Manually add offset to UTC date based on a specific timezone
export function formatUTCDateWithOffset(dateToConvert: Date, secondsOffset: number, isDateInUTC: boolean = false) {
  const timeUTCdate = !isDateInUTC ?
    Date.UTC(dateToConvert.getUTCFullYear(), dateToConvert.getUTCMonth(), dateToConvert.getUTCDate(), dateToConvert.getUTCHours(), dateToConvert.getUTCMinutes(), dateToConvert.getUTCSeconds()) + dateToConvert.getTimezoneOffset() * 60000 :
    dateToConvert.getTime();
  var hours = Math.floor(secondsOffset / 60)
  var minutes = secondsOffset % 60;
  var newDateInMS = (timeUTCdate + (hours * 3600 * 1000) + (minutes * 60 * 1000));
  return new Date(newDateInMS)
}

//transform second in h:m:s
export function secondsToHMS(e: number) {
  var h = Math.floor(e / 3600).toString().padStart(2, '0'),
    m = Math.floor(e % 3600 / 60).toString().padStart(2, '0'),
    s = Math.floor(e % 60).toString().padStart(2, '0');

  return h + ':' + m + ':' + s;
  //return `${h}:${m}:${s}`;
}



/* FILES CONVERSION */

// Convert file image to base 64 string. return a promise with the value
export function fileToBase64(file: File) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (reader.result) {
        // var encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        // if ((encoded.length % 4) > 0) {
        //   encoded += '='.repeat(4 - (encoded.length % 4));
        // }
        resolve(reader.result);
      } else {
        reject('');
      }
    };
    reader.onerror = error => reject('');
  });
}

// Convert a base64 image to file
export const dataURLtoFile = (dataurl: string, filename: string) => {

  var arr = dataurl.split(','),
    //@ts-ignore
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  var file = new File([u8arr], filename, { type: mime });

  return file;
}

// From https://developer.mozilla.org/en-US/docs/Web/API/HTMLCanvasElement/toBlob, needed for Safari:
if (!HTMLCanvasElement.prototype.toBlob) {
  Object.defineProperty(HTMLCanvasElement.prototype, 'toBlob', {
    value: function (callback: any, type: any, quality: any) {

      var binStr = atob(this.toDataURL(type, quality).split(',')[1]),
        len = binStr.length,
        arr = new Uint8Array(len);

      for (var i = 0; i < len; i++) {
        arr[i] = binStr.charCodeAt(i);
      }

      callback(new Blob([arr], { type: type || 'image/png' }));
    }
  });
}

window.URL = window.URL || window.webkitURL;

// Modified from https://stackoverflow.com/a/32490603, cc by-sa 3.0
// -2 = not jpeg, -1 = no data, 1..8 = orientations
function getExifOrientation(file: any, callback: any) {
  // Suggestion from http://code.flickr.net/2012/06/01/parsing-exif-client-side-using-javascript-2/:
  if (file.slice) {
    file = file.slice(0, 131072);
  } else if (file.webkitSlice) {
    file = file.webkitSlice(0, 131072);
  }

  var reader = new FileReader();
  reader.onload = function (e: any) {
    var view = new DataView(e.target.result);
    if (view.getUint16(0, false) != 0xFFD8) {
      callback(-2);
      return;
    }
    var length = view.byteLength, offset = 2;
    while (offset < length) {
      var marker = view.getUint16(offset, false);
      offset += 2;
      if (marker == 0xFFE1) {
        if (view.getUint32(offset += 2, false) != 0x45786966) {
          callback(-1);
          return;
        }
        var little = view.getUint16(offset += 6, false) == 0x4949;
        offset += view.getUint32(offset + 4, little);
        var tags = view.getUint16(offset, little);
        offset += 2;
        for (var i = 0; i < tags; i++)
          if (view.getUint16(offset + (i * 12), little) == 0x0112) {
            callback(view.getUint16(offset + (i * 12) + 8, little));
            return;
          }
      }
      else if ((marker & 0xFF00) != 0xFF00) break;
      else offset += view.getUint16(offset, false);
    }
    callback(-1);
  };
  reader.readAsArrayBuffer(file);
}

// Derived from https://stackoverflow.com/a/40867559, cc by-sa
function imgToCanvasWithOrientation(img: any, rawWidth: number, rawHeight: number, orientation: number) {
  var canvas = document.createElement('canvas');
  if (orientation > 4) {
    canvas.width = rawHeight;
    canvas.height = rawWidth;
  } else {
    canvas.width = rawWidth;
    canvas.height = rawHeight;
  }

  var ctx: any = canvas.getContext('2d');
  switch (orientation) {
    case 2: ctx.transform(-1, 0, 0, 1, rawWidth, 0); break;
    case 3: ctx.transform(-1, 0, 0, -1, rawWidth, rawHeight); break;
    case 4: ctx.transform(1, 0, 0, -1, 0, rawHeight); break;
    case 5: ctx.transform(0, 1, 1, 0, 0, 0); break;
    case 6: ctx.transform(0, 1, -1, 0, rawHeight, 0); break;
    case 7: ctx.transform(0, -1, -1, 0, rawHeight, rawWidth); break;
    case 8: ctx.transform(0, -1, 1, 0, 0, rawWidth); break;
  }
  ctx.drawImage(img, 0, 0, rawWidth, rawHeight);
  return canvas;
}

export function reduceFileSize(file: File, acceptFileSize: number, maxWidth: number, maxHeight: number, quality: number, callback: any) {
  if (file.size <= acceptFileSize) {
    callback(file);
    return;
  }
  var img = new Image();
  img.onerror = function () {
    URL.revokeObjectURL(this.src);
    callback(file);
  };
  img.onload = function () {
    // @ts-ignore
    URL.revokeObjectURL(this.src);
    getExifOrientation(file, function (orientation: any) {
      var w = img.width, h = img.height;
      var scale = (orientation > 4 ?
        Math.min(maxHeight / w, maxWidth / h, 1) :
        Math.min(maxWidth / w, maxHeight / h, 1));
      h = Math.round(h * scale);
      w = Math.round(w * scale);

      var canvas = imgToCanvasWithOrientation(img, w, h, orientation);
      canvas.toBlob(function (blob: any) {
        callback(blob);
      }, 'image/jpeg', quality);
    });
  };
  img.src = URL.createObjectURL(file);
}

export function downloadEventSectionBackground(event: EventsTypeRes.IEvent, eventSection: string, updateCurrentEvent: boolean = true, setBackground?: Function, defaultBackground?: string) {
  if (event && event.eventBackgrounds) {
    let eventBackground = event.eventBackgrounds.find(bg => bg.eventSection === eventSection);
    if (eventBackground) {
      if (!eventBackground.backgroundImageBase64) {
        // Check if image is stored in our customDb, if it is, we retrive her from there, if not we call the api service to retrive the base64:
        let bgKey = `${event.eventSettings.eventPath}_${eventSection}_${eventBackground.resourceID}`;
        window.idbCustom.loadResourceBase64(bgKey).then((base64: string | null) => {
          if (base64 && base64.length > 0) {
            if (setBackground) {
              setBackground(base64);
            }
            updateEventBackground(event, eventSection, updateCurrentEvent, base64)
          } else if (eventBackground?.resourceID) {
            axios.get('Resources/' + eventBackground.resourceID + '/base64').then(res => {
              const response = res.data;
              if (response.status === "success") {
                // Store retrived image in customDb
                if (setBackground) {
                  setBackground(response.data.content);
                }
                window.idbCustom.saveBase64Image(bgKey, response.data.content);
                updateEventBackground(event, eventSection, updateCurrentEvent, response.data.content)
              } else {
                if (setBackground) {
                  setBackground(defaultBackground);
                }
              }
            }).catch(() => {
              if (setBackground) {
                setBackground(defaultBackground);
              }
            });
          }
        });
      }
      else if (setBackground) {
        setBackground(eventBackground.backgroundImageBase64);
      }
    }
  }
}

const thumbnailWidth = 350;
const thumbnailHeight = 196;
export function downloadEventThumbnail(event: EventsTypeRes.IEvent, setShowLoading: (showLoading: boolean) => void, setThumbnail: (base64: string) => void) {
  if (event.eventHeaders && event.eventHeaders.backgroundImageSmallResourceID > 0 && !event.eventHeaders.backgroundImageThumbnailBase64) {
    setShowLoading(true);
    let bgKey = `${event.eventSettings.eventPath}_Thumbnail_${event.eventHeaders.backgroundImageSmallResourceID}`;
    window.idbCustom.loadResourceBase64(bgKey).then((base64: string | null) => {
      if (base64 && base64.length > 0) {
        event.eventHeaders.backgroundImageThumbnailBase64 = base64;
        updateEventBackground(event, "", false, "");
        setShowLoading(false);
        setThumbnail(base64);
      } else {
        axios.get('Resources/' + event.eventHeaders.backgroundImageSmallResourceID + '/Thumbnail?width=' + thumbnailWidth + '&height=' + thumbnailHeight + '&fileMode=Base64')
          .then(res => {
            const response = res.data;
            if (response.status === "success") {
              // Store retrived image in customDb
              window.idbCustom.saveBase64Image(bgKey, response.data.content);
              event.eventHeaders.backgroundImageThumbnailBase64 = response.data.content;
              updateEventBackground(event, "", false, "");
              setThumbnail(response.data.content);
            }
            setShowLoading(false);
          }).catch(e => {
            setShowLoading(false);
          });
      }
    }).catch(() => {
      setShowLoading(false);
    });
  } else if (event.eventHeaders && event.eventHeaders.backgroundImageThumbnailBase64) {
    setThumbnail(event.eventHeaders.backgroundImageThumbnailBase64);
  }
}

export function downloadEventOnDemandThumbnail(event: EventsTypeRes.IEventContent, setShowLoading: (showLoading: boolean) => void, setThumbnail: (base64: string) => void) {
  if (event.eventThumbnail > 0) {
    setShowLoading(true);
    let bgKey = `${event.eventPath}_Thumbnail_${event.eventThumbnail}`;
    window.idbCustom.loadResourceBase64(bgKey).then((base64: string | null) => {
      if (base64 && base64.length > 0) {
        setShowLoading(false);
        setThumbnail(base64);
      } else {
        axios.get('Resources/' + event.eventThumbnail + '/Thumbnail?width=' + thumbnailWidth + '&height=' + thumbnailHeight + '&fileMode=Base64')
          .then(res => {
            const response = res.data;
            if (response.status === "success") {
              // Store retrived image in customDb
              window.idbCustom.saveBase64Image(bgKey, response.data.content);
              setThumbnail(response.data.content);
            }
            setShowLoading(false);
          }).catch(e => {
            setShowLoading(false);
          });
      }
    }).catch(() => {
      setShowLoading(false);
    });
  }
  // else if (event.eventHeaders && event.eventHeaders.backgroundImageThumbnailBase64) {
  //   setThumbnail(event.eventHeaders.backgroundImageThumbnailBase64);
  // }
}


function updateEventBackground(event: EventsTypeRes.IEvent, eventSection: string, updateCurrentEvent: boolean = true, base64: string) {
  const detail: EventUtility.IEventUpdate = {
    eventID: event.eventID,
    event: {
      ...event,
      eventBackgrounds: event.eventBackgrounds.map(element => element.eventSection === eventSection ?
        { ...element, backgroundImageBase64: base64 }
        :
        element)
    },
    updateCurrentEvent: updateCurrentEvent
  }
  window.dispatchEvent(new CustomEvent('updateCurrentEvent', { detail: detail }));
}

export function getEventSectionBackground(eventSection: string, defaultBackground: string, event?: EventsTypeRes.IEvent | null,) {

  let background = defaultBackground;

  if (event && event.eventBackgrounds) {
    let eventBackground = event.eventBackgrounds.find(bg => bg.eventSection === eventSection);

    if (eventBackground && eventBackground.backgroundImageBase64) {
      background = eventBackground.backgroundImageBase64;
    }
  }
  return background;
}

export const formatEventDate = (startDate: Date, endDate: Date, format: string, preText: string = '') => {
  let dateFormat = 'MMMM Do YYYY';

  format = preText ? preText + format : format;
  if (format && format.length > 0) {
    let startDateIndex = format.indexOf('$startDate');
    if (startDateIndex > -1) {
      if (format[startDateIndex + 10] === '[') {
        let endBracketIndex = format.indexOf(']', startDateIndex);
        dateFormat = format.substring(startDateIndex + 11, endBracketIndex);
        format = format.replace('[' + dateFormat + ']', '');
      }
      format = format.replace('$startDate', moment(startDate).format(dateFormat));
    }

    let endDateIndex = format.indexOf('$endDate');
    if (endDateIndex > -1) {
      if (format[endDateIndex + 8] === '[') {
        let endBracketIndex = format.indexOf(']', endDateIndex);
        dateFormat = format.substring(endDateIndex + 9, endBracketIndex);
        format = format.replace('[' + dateFormat + ']', '');
      }
      format = format.replace('$endDate', moment(endDate).format(dateFormat));
    }
    return ReactHtmlParser(format.replace(/([0-9])(st|nd|rd|th)/g, '$1<sup>$2</sup>'));
  }
}


/*  WEB SOCKET */

export const liveAnswerSubmit = (data: WebSocketTypeRes.IWsPollVote, roomID: string) => {
  let action: WebSocketTypeRes.IWsPollActions = "Vote";
  let command: WebSocketTypeRes.IwsCommand = {
    section: "Poll",
    command: action as string,
    value: data
  }
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Command",
    message: command,
    roomID: roomID
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}

// Values example: 
// { type: 'zoom', value: 'SPEC' }    // -- specific room by user
// { type: 'zoom', value: 'ALL' }     // -- global room for all users
export const openBreakoutRoom = (roomID: string, commandValue: { type: string, value: string }) => {
  if (window.debug) console.log('Open specific breakout room');
  let command: WebSocketTypeRes.IwsCommand = {
    section: "OpenWindow",
    command: '' as string,
    value: commandValue
  }
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Command",
    message: command,
    roomID: roomID
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}

// Values example: 
// { name: 'confetti', active: true }
export const setUIEffect = (roomID: string, commandValue: { name: string, active: boolean }) => {
  if (window.debug) console.log('Start/Stop specific UI effect');
  let action: WebSocketTypeRes.IWsUIEffectActions = "ShowEffect";
  let command: WebSocketTypeRes.IwsCommand = {
    section: "UICommand",
    command: action as string,
    value: commandValue
  }
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Command",
    message: command,
    roomID: roomID
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}

export const setOpenWindowEffect = (roomID: string, commandValue: { name: string, active: boolean }) => {
  if (window.debug) console.log('Start/Stop specific UI effect');
  let action: WebSocketTypeRes.IWsUIEffectActions = "OpenWindow";
  let command: WebSocketTypeRes.IwsCommand = {
    section: "UICommand",
    command: action as string,
    value: commandValue
  }
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Command",
    message: command,
    roomID: roomID
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}

export const updateButtonEffect = (roomID: string, commandValue: { id: string, visible?: boolean, text?: string, animate?: boolean }) => {
  if (window.debug) console.log('Show/Hide buttons');
  let action: WebSocketTypeRes.IWsUIEffectActions = "UpdateButton";
  let command: WebSocketTypeRes.IwsCommand = {
    section: "UICommand",
    command: action as string,
    value: commandValue
  }
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Command",
    message: command,
    roomID: roomID
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}

export const sendUserClick = (roomID: string, commandValue: { id: string }) => {
  if (window.debug) console.log('Show/Hide buttons');
  let action: WebSocketTypeRes.IWsUIEffectActions = "SendUserClick";
  let command: WebSocketTypeRes.IwsCommand = {
    section: "UICommand",
    command: action as string,
    value: commandValue
  }
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Command",
    message: command,
    roomID: roomID
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}

export const messageSubmit = (data: WebSocketTypeRes.IWsChatMessage, chatRoomID: string = window.chatRoomLive) => {
  var message: WebSocketTypeRes.IWsMessage = {
    action: "Message",
    message: data,
    roomID: chatRoomID
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}


export const sendjoinRoom = (chatRoomID: string = window.chatRoomLive) => {
  var message: WebSocketTypeRes.IWsMessage = {
    action: "JoinRoom",
    message: "",
    roomID: chatRoomID,
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}

export const sendLeaveRoom = (chatRoomID: string = window.chatRoomLive) => {
  var message: WebSocketTypeRes.IWsMessage = {
    action: "LeaveRoom",
    message: "",
    roomID: chatRoomID,
  }
  callEvent("sendWsMessage", { wsUrl: window.wsURL, object: message });
}


/* TOASTER Messages */
export const showWarningMessage = (message: string) => {
  toast.warning(message,
    {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return;
}

export const showSuccessMessage = (message: string) => {
  toast.success(message,
    {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return;
}

export const showErrorMessage = (message: string) => {
  toast.error(message,
    {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return;
}

export const showInfoMessage = (message: string) => {
  toast.info(message,
    {
      position: "bottom-right",
      autoClose: false,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  return;
}

// IE 11 fix: truncate text manually (prop -webkit-line-clamp not available)
export const ellipsizeText = (text: string, maxChars: number) => {
  let formattedText = text;
  if (document.body.style["webkitLineClamp"] === undefined) {
    if (text.length >= maxChars) {
      formattedText = text.substr(0, maxChars - 5).concat('...')
    }
  }
  return formattedText;
}

// Update user language on database 
export const updateUserLanguage = (user: MembersTypeRes.IMember, language: string) => {
  if (user.defaultLanguageID !== language) {
    axios.post('Members/UpdateLanguage/' + language)
      .then(res => {
        const response = res.data;
        if (response.status === 'success') {
        } else {
          console.log("Failed to update user default language.")
        }
      })
      .catch(e => {
        console.log("Failed to update user default language.")
      });
  }
}

// Transform object with properties in query string
export const objToQueryParams = (obj: { [key: string]: any }) => {
  const keys = Object.keys(obj);
  let query = '?'
  keys.forEach(key => {
    query += `${key}=${obj[key]}&`;
  })
  return query.slice(0, -1);
}

// Tens 2022 - Specific functions

export const filterTensSession = (event: EventsTypeRes.IEvent, sessions: ISession[], userCountryCode: string) => {
  return sessions.filter(y => y.title && checkTensSessionCountry(event, y, userCountryCode));
}

export const checkTensSessionCountry = (event: EventsTypeRes.IEvent, session: ISession, userCountryCode: string) => {
  let isEventApproval = false;
  if (event.eventAttendees?.length > 0) {
    isEventApproval = event.eventAttendees[0].isEventApproval;
  }
  let isEventAdmin = window.userInRole("EventAdmin") || window.userInRole("EventEditor") || isEventApproval;
  return isEventAdmin || !session.countryISOCode || session.countryISOCode === userCountryCode;
}

export const toDate = (date: Date) => {
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export interface ICategoryImage {
  fileName: string,
  base64: string,
  fileSize?: number,
  mimeType?: string
}

export const getCategoryImages = (category: string | number, setImages: (images: ICategoryImage[], totalRecords: number, reset: boolean) => void, offset: number = 0, limit: number = 999, reset: boolean = false) => {
  let querystring = objToQueryParams({ offset: offset, limit: limit });
  axios.get(`/Resources/Category/${category}/GetCategoryImages${querystring}`)
    // api.Resources.getCategoryImages(category, { offset: offset, limit: limit })
    .then(res => {
      const response = res.data;
      if (response.status === "success") {
        let images: ICategoryImage[] = response.data.collection.map((x: any) => { return { fileName: x.fileName, base64: x.content } });
        setImages(images, response.data.totalRecords, reset);
      }
    })
    .catch(e => console.log(e));
}

export const randomSort = (a: any, b: any) => {
  let random = Math.random();
  if (random > 0.5) return 1;
  if (random < 0.5) return -1;
  if (random === 0 || random === 1) return 0;
  return 0;
}

export const zipImages = async (images: ICategoryImage[]) => {
  var zip = new JSZip();
  var img = zip.folder("Zimmer_Biomet_Pictures");
  var base64: Blob;

  for (const imgage of images) {
    base64 = await (await fetch(imgage.base64)).blob();
    if (img && imgage.fileName) {
      img.file(imgage.fileName, base64, { base64: true });
    }
  }

  await zip.generateAsync({ type: "blob" }).then(function (content) {
    saveAs(content, "Zimmer_Biomet_Pictures.zip");
  });
}

export function filterMadridEvents(events: EventsTypeRes.IEvent[]) {
   // Filter "Problem solved digital" for users that have "Problem sovled madrid" associated
  let roles: MembersTypeRes.IMemberRole[] = window.storageGetItemValue('Auth-roles');
  if (!roles) {
     roles = []
   }
   const isAdmin = roles.findIndex(obj => obj.roleDescription === "EventAdmin") >= 0 || roles.findIndex(obj => obj.roleDescription === "EventEditor") >= 0;
   const digital = events.find(x => x.eventID.toUpperCase() === "60650688-D2EF-4FFA-BEE0-F17839BD00B1");
   // User is an event admin if it's the event approval
   const isEventAdmin = digital && digital.eventAttendees && digital.eventAttendees.length > 0 && digital.eventAttendees[0].isEventApproval;
   let inPerson = events.findIndex(x => x.eventID.toUpperCase() === "3D39E9B2-C9CD-4089-ABC4-A57004FE1D6F") > -1;
   return events.filter(x => x.eventID.toUpperCase() !== "60650688-D2EF-4FFA-BEE0-F17839BD00B1" || !inPerson || isAdmin || isEventAdmin);
}


export function compressImage(file: File, callbackFn: (file: Blob | null) => void) {
  const MAX_WIDTH = 960;
  const MAX_HEIGHT = 480;
  const MIME_TYPE = "image/jpeg";
  const QUALITY = 0.7;

  const blobURL = URL.createObjectURL(file);
  const img = new Image();
  img.src = blobURL;
  img.onerror = function () {
    URL.revokeObjectURL(img.src);
    // Handle the failure properly
    console.log("Cannot load image");
  };
  img.onload = function () {
    URL.revokeObjectURL(img.src);
    const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
    const canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;
    const ctx = canvas.getContext("2d");
    if (ctx) {
      ctx.drawImage(img, 0, 0, newWidth, newHeight);
      canvas.toBlob(
        (blob) => {
          // Handle the compressed image. es. upload or save in local state
          callbackFn(blob);
        },
        MIME_TYPE,
        QUALITY
      );
    }
  }
}

function calculateSize(img: HTMLImageElement, maxWidth: number, maxHeight: number) {
  let width = img.width;
  let height = img.height;

  // calculate the width and height, constraining the proportions
  if (width > height) {
    if (width > maxWidth) {
      height = Math.round((height * maxWidth) / width);
      width = maxWidth;
    }
  } else {
    if (height > maxHeight) {
      width = Math.round((width * maxHeight) / height);
      height = maxHeight;
    }
  }
  return [width, height];
}