import React, { useEffect } from 'react'

function RedirectTo({ href }: { href: string }) {

  useEffect(() => {
    if (href) {
      window.location.replace(href);
    }
  }, [href])

  return (
    <></>
  )
}

export default RedirectTo