import useEventListener from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";
import React, { useEffect, useState } from "react";
import { isInStandaloneMode, isIos, isMobileDevice } from "../../config/functions";

export interface IBeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{ outcome: "accepted" | "dismissed"; platform: string; }>;
  prompt(): Promise<void>;
}

export const useAddToHomescreenPrompt = () => {
  const [prompt, setPrompt] = useState<IBeforeInstallPromptEvent | null>(null);
  const [installAppNotifyType, setInstallAppNotifyType] = useState<"compatible" | "fallback" | "ios" | "">("");

  useEventListener("beforeinstallprompt", (e: IBeforeInstallPromptEvent) => {
    e.preventDefault();
    setPrompt(e);
    setInstallAppNotifyType("compatible");
  });

  useEventListener("appinstalled", () => {
    setInstallAppNotifyType("");
  });

  useEffect(() => {
    if (isMobileDevice() && !isIos() && !isInStandaloneMode()) {
      setInstallAppNotifyType("fallback");
    }

    // iOS mobile device
    if (isIos() && !isInStandaloneMode()) {
      setInstallAppNotifyType("ios");
    }
  }, []);

  const promptToInstall = () => {
    if (prompt) {
      return prompt.prompt();
    }
    return Promise.reject(new Error('Tried installing before browser sent "beforeinstallprompt" event'));
  }

  return [prompt, promptToInstall, installAppNotifyType];
}