import React, { useState, useEffect } from 'react';
import moment from 'moment';
import ReactHtmlParser from 'react-html-parser';
import $ from 'jquery';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faReply, faStar as faStarFull, faFolder as faFolderFull, faThumbsUp } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarEmpty, faTrashAlt, faFolder as faFolderEmpty } from '@fortawesome/free-regular-svg-icons';

import axios from '../../config/axios';
import { ChatTypeRes } from '../../Models/ResponseModels/Chat'
import { formatFileSize } from '../../config/functions';
import axiosInstance from '../../config/axios';
import { WebSocketTypeRes } from '../../Models/ResponseModels/WebSocketModels';
// import ChatPrivateMessage from './ChatPrivateMessage';
import { callEvent } from '../_hooks/useEventListener';

interface IProps {
    chatRoomID?: number,
    message: ChatTypeRes.IChatMessage,      // msg to view
    user: ChatTypeRes.IChatUserHasAccess    // connected user who sees messages
    isChatClosed: boolean,
    showTextArea: boolean,                  // is text area visible?
    liveWebinar: boolean,                   // is page used to only show messages live?
    isBackstageChat?: boolean,
    setQuotedMessage: Function,
    setImagesModal: Function,
    setHighlightMessage: Function,
    setLikedMessage: Function,
    onSubmit: Function,
    setContactMessage: Function,
    showContact?: boolean,
    setShowContactBox: Function,
    profilePicture?: string,
    showProfilePicture?: boolean,
}

const ChatMessage = ({ chatRoomID = 0, message, user, isChatClosed, showTextArea, liveWebinar, isBackstageChat = false, setQuotedMessage, setImagesModal, setHighlightMessage, setLikedMessage, onSubmit, setContactMessage, showContact, setShowContactBox, profilePicture, showProfilePicture }: IProps) => {

    // TODO: set favorite from DB
    // const [isHighlighted, setHighlight] = useState<boolean>(message.faculty_Special);

    const roomID = chatRoomID > 0 ? chatRoomID : parseInt(window.storageGetItemValue("roomID"));

    // Get favorite from local storage
    const favoriteMessageIDs: number[] = window.storageGetItemValue('favoriteMessageIDs')
    let isFavorite = false;
    if (favoriteMessageIDs !== null) {
        isFavorite = favoriteMessageIDs.includes(message.id);
    } else {
        isFavorite = false;
    }

    // Check old messages and evidence new ones
    const oldMessages: number[] = window.storageGetItemValue('oldMessagesIDs');
    let isNew = false;
    if (oldMessages !== null) {
        isNew = !oldMessages.includes(message.id);
    } else {
        isNew = true;
    }

    const [isHighlighted, setHighlight] = useState<boolean>(isFavorite);

    // const isMessageArchived = message.archived_at !== null && message.archived_at !== undefined;
    const isMessageArchived = false;

    // const [isPrivateMessageOpen, setIsPrivateMessageOpen] = useState<boolean>(false)

    // const handlePrivateMessageOpen = () => {
    //     setIsPrivateMessageOpen(!isPrivateMessageOpen)
    // }


    useEffect(() => {
        //Right click on chat preview images
        $("#chat-main-container-" + chatRoomID + ".chat-wrapper").on("contextmenu", "img.attachment-preview", function (e) {
            return false;
        });

        //Right click on lightbox image:
        $(document).on("contextmenu", ".react-images__view-image", function (e) {
            return false;
        });

        //Drag and drop:
        $("#chat-main-container-" + chatRoomID + " .chat-wrapper").on("dragstart drop", function (e) {
            e.preventDefault();
            return false;
        });

        //Drag and drop on lightbox image:
        $(document).on("dragstart drop", ".react-images__view-image", function (e) {
            e.preventDefault();
            return false;
        });

    }, [])

    useEffect(() => {
        if (isNew) {
            // $(".new-message").fadeIn();
            $("#chat-main-container-" + chatRoomID + " .new-message").fadeOut(5000);
        }
    }, [isNew])

    // Quote a message
    const quoteMessage = () => {
        if (isChatClosed) {
            return false;
        }
        var quoteContent = message.message;
        if (quoteContent.length === 0 && message.attachments.length > 0) {
            quoteContent = message.attachments[0].original_name + "...";
        }
        setQuotedMessage({
            messageId: message.id,
            quotedUserName: message.name,
            quotedUserLastName: message.lastName,
            quoteContent: quoteContent
        })
        $("#chat-main-container-" + chatRoomID + " #message-textarea").focus();
    }

    const goToQuotedMessage = (id: string) => {
        var refId = id;
        var divMessage: any = $("#chat-main-container-" + chatRoomID + " #chat-message-" + refId);
        var container: any = $("#chat-main-container-" + chatRoomID + " #message-box");

        if (divMessage.length) {
            //trovato il div del messaggio:
            var divTop = divMessage.offset().top - container.offset().top + container.scrollTop() - 20;
            $("#chat-main-container-" + chatRoomID + " #message-box").animate({ scrollTop: divTop }, 200);
        }
        else {
            // TO-DO ?
            //? carico i messaggi precedenti e vado al messaggio?
        };

    }

    const handleBanUser = () => {
        var userID = message.user_id
        var userName = message.name + " " + message.lastName;
        var deleteMessages = false;
        var confirmed = window.confirm("Are you sure you want to ban user " + userName + "?");
        if (!confirmed) {
            return false;
        }
        if (window.confirm("Do you want to delete all messages sent by this user?")) {
            deleteMessages = true;
        }

        axios.post('Chat/' + roomID + "/Ban/" + userID).then(res => {
            if (res.data.status === "success") {
                if (deleteMessages) {
                    // TODO: delete /api/Chat/{roomID}/UserMessages/{userID}
                    //Sending command to server:
                    // var msg = {
                    //     room_id: window.localStorage.getItem("roomID"),
                    //     command: "DEL_USER_MSG",
                    //     user_id: user.memberID,
                    //     name: user.name,
                    //     lastname: user.lastname,
                    //     id: user.memberID
                    // };
                    // websocket.send(JSON.stringify(msg));
                    var message: WebSocketTypeRes.IwsCommand = {
                        section: "Chat",
                        command: "BanFromRoom",
                        value: userID
                    };
                    let wsMessage: WebSocketTypeRes.IWsMessage = {
                        action: "Command",
                        roomID: roomID.toString(),
                        message: message
                    };
                    callEvent("sendWsMessage", { wsUrl: window.wsURL, object: wsMessage });
                }
            } else {
                alert("ERROR: the selected user can't be banned");
            }
        }).catch(e => {
            console.log("Error in banning user " + userName);
        })
    }

    const handleDeleteMessage = () => {
        var msgId = message.id
        var confirmed = window.confirm("Are you sure you want to permanently delete message number " + msgId + "?");
        if (!confirmed) {
            return false;
        }

        axios.delete("Chat/" + roomID + "/Message/" + msgId).then(res => {
            if (res.data.status === "success") {
                // if (window.webSocket == null || window.webSocket.readyState === 3) {
                //     window.dispatchEvent(new CustomEvent('deleteMessage', { "detail": { messageID: msgId.toString() } }));
                // } else { }
                const wsMessage: WebSocketTypeRes.IWsMessage = {
                    action: "Command",
                    roomID: roomID.toString(),
                    message: {
                        section: "Chat",
                        command: "DeleteMessage",
                        value: msgId.toString()
                    },
                };
                callEvent("sendWsMessage", { wsUrl: window.wsURL, object: wsMessage });
            }
        }).catch(e => {
            console.log("Error in deleting message from database: " + e);
        })
    }

    const handleArchiveMessage = () => {

        var msgId = message.id
        var confirmed = isMessageArchived ?
            window.confirm("Are you sure you want to restore archived message number " + msgId + "?") :
            window.confirm("Are you sure you want to archive message number " + msgId + "?")
        if (!confirmed) {
            return false;
        }

        const data = {
            archived: !isMessageArchived,
            setSpecial: false,
            order: 0
        }
        axiosInstance.post(`Chat/${roomID}/Message/${msgId}/SetOptions`, data)
            .then(res => {
                if (res.data.status === "success") {
                    // if (window.webSocket == null || window.webSocket.readyState === 3) {
                    //     window.dispatchEvent(new CustomEvent('archiveMessage', { "detail": { messageID: msgId.toString() } }));
                    // } else { }
                    const wsMessage: WebSocketTypeRes.IWsMessage = {
                        action: "Command",
                        roomID: roomID.toString(),
                        message: {
                            section: "Chat",
                            command: "ArchiveMessage",
                            value: msgId.toString()
                        },
                    };
                    callEvent("sendWsMessage", { wsUrl: window.wsURL, object: wsMessage });
                }
            })
            .catch(e => {
                console.log("Error in archiving message: " + e);
            })

    }

    const handleAttachClick = (e: any, id: number, path: string, openModal: boolean) => {
        if (openModal) {
            e.preventDefault();
            setImagesModal([{ src: path }]);
        }
    }

    // set message evidence and shift on top
    const highlightMessage = (value: boolean) => {
        setHighlight(value);
        setHighlightMessage({
            messageId: message.id,
            order: value ? 1 : 999
        })
        // Save favorite in local storage
        let favoriteMessageIDs = window.storageGetItemValue('favoriteMessageIDs')
        if (favoriteMessageIDs !== null) {
            if (value) {
                favoriteMessageIDs.push(message.id);
            } else {
                favoriteMessageIDs = favoriteMessageIDs.filter((msgID: number) => msgID !== message.id);
            }
        } else {
            if (value) {
                favoriteMessageIDs = [message.id];
            }
        }
        window.storageSetItem('favoriteMessageIDs', JSON.stringify(favoriteMessageIDs));
        // TODO: save favorites in DB based on user
        // const data = {
        //     setSpecial: value,
        //     order: 0
        // }
        // axiosInstance.post(`Chat/${roomID}/Message/${message.id}/SetOptions`, data)
        //     .then(res => { })
    }

    // -- LIKE/UNLIKE MESSAGE
    const handleLikeMessage = () => {
        const isMessageLiked = message.voted;
        var msgId = message.id;
        axios.post('Chat/VoteMessage/' + msgId + '?upvote=' + (!isMessageLiked)).then(res => {
            if (res.data.status === "success") {
                setLikedMessage(msgId, !isMessageLiked);
                // if (window.webSocket == null || window.webSocket.readyState === 3) {
                //     if (!isMessageLiked) {
                //         window.dispatchEvent(new CustomEvent('voteMessage', { "detail": { messageID: msgId.toString() } }));
                //     } else {
                //         window.dispatchEvent(new CustomEvent('unVoteMessage', { "detail": { messageID: msgId.toString() } }));
                //     }
                // }
                const wsMessage: WebSocketTypeRes.IWsMessage = {
                    action: "Command",
                    roomID: roomID.toString(),
                    message: {
                        section: "Chat",
                        command: !isMessageLiked ? "VoteMessage" : "UnVoteMessage",
                        value: msgId.toString()
                    },
                };
                callEvent("sendWsMessage", { wsUrl: window.wsURL, object: wsMessage });
            }
        })
            .catch(e => {
                console.log("Error in liking message: " + e);
            })
    }


    const determinePreviewTag = (a: any) => {
        var basePath = "/assets/icons/"
        //default preview path
        var path = basePath + "default.png";
        var additionalClass = "";
        var openModal = false;
        //type-based preview path
        if (a.type) {
            //if link
            if (a.type.match(/^link/i)) {
                path = basePath + "link.png";
            }
            //if img, use the image itself as preview
            if (a.type.match(/^img/i) || a.type.match(/^image/i)) {
                path = window.apiURL + "Resources/" + a.id + "/Download";
                additionalClass = "image-attachment";
                openModal = true;
            }
            //if video
            if (a.type.match(/^video/i)) {
                path = basePath + "video.png";

            }
            //if pdf
            if (a.type.match(/pdf$/i)) {
                path = basePath + "pdf.png";
            }
            //if doc, docx, odt
            if (a.type.match(/(?:.*word)|(?:.*opendocument)/i)) {
                path = basePath + "word.ico";
            }
            //if ppt, pptx
            if (a.type.match(/(?:.*powerpoint)|(?:.*presentation)/i)) {
                path = basePath + "ppt.png";
            }
            //if xls, xlsx
            if (a.type.match(/(?:.*excel)|(?:.*spreadsheet)/i)) {
                path = basePath + "xls.png";
            }
        }
        //specific path (if any)
        if (a.preview_path) {
            path = window.apiURL + "Resources/" + a.id + "/Download";;
        }
        return (
            <img className={`img-fluid attachment-preview attachment-${a.id}-preview ${additionalClass}`}
                src={path} alt="image preview"
                onClick={(e: any) => handleAttachClick(e, a.id, path, openModal)} ></img>
        )
    }

    let msgWrapperClass = "message-wrapper";
    let msgClass = "message";
    let msgCircleClass = "chat-user-circle";
    let userName = message.fullName;
    let userInitials = message.name.charAt(0) + message.lastName.charAt(0);
    let msgDate = moment(message.timestamp).format('YYYY-MM-DD HH:mm');
    let msgUserName = message.fullName;
    let quotedMessage = null;
    let attachments = null;
    let deleteButton = null;
    let archiveButton = null;
    let banButton = null;

    // show delete button if is chat moderator or is current user message
    if (user.isChatModerator || message.user_id === user.memberID) {
        deleteButton = (
            !liveWebinar ?
                <button type="button"
                    className="btn chat-delete-message-btn"
                    id={`chat-delete-message-${message.id}`}
                    data-messageid={message.id}
                    onClick={handleDeleteMessage}>x del
                </button> :
                <button type="button" className="btn btn-delete chat-message-id" id={`chat-btn-delete-id-${message.id}`}
                    data-messageid={message.id} data-userid={message.user_id} data-username={message.name} data-userlastname={message.lastName}
                    onClick={handleDeleteMessage}>
                    <FontAwesomeIcon
                        icon={faTrashAlt}
                        title="Delete message"
                        className="icon-delete"
                    />
                </button>
            // <FontAwesomeIcon 
            //     icon={faTrashAlt} 
            //     title="Delete message" 
            //     className="icon-delete" 
            //     onClick={handleDeleteMessage}
            // />
        )
    }

    // show archive button if is chat moderator
    if (user.isChatModerator) {
        archiveButton = (
            <FontAwesomeIcon
                icon={isMessageArchived ? faFolderFull : faFolderEmpty}
                title="Archive message"
                className="icon-archive"
                onClick={handleArchiveMessage}
            />
        )
    }

    // adding class to circle for moderators
    if (message.isModerator || (user.isChatModerator && message.user_id === user.memberID)) {
        msgCircleClass += " moderator";
    }
    // show ban button only for moderators (TODO)
    if (!message.isModerator && user.isChatModerator && false) {
        banButton = (
            <button type="button" className="btn chat-ban-user-btn" id={`chat-ban-user-${message.id}`}
                data-messageid={message.id} data-userid={message.user_id} data-msgusername={message.name} data-msguserlastname={message.lastName}
                onClick={handleBanUser}>ban
            </button>
        )
    }

    // Quoted message
    if (message.quote !== null) {

        let quotedTxt = message.quote.message;
        if (quotedTxt.length == 0 && message.quote.attachments.length > 0) {
            quotedTxt = message.quote.attachments[0].original_name + "...";
        }

        quotedMessage = (
            <div className="quoted-message-wrapper" data-messageid={message.quote.id} data-userid={message.quote.user_id}
                onClick={() => goToQuotedMessage(message.quote ? message.quote.id.toString() : '')}>
                <div className="quoted-message">
                    In reply to
                    <span className="author">{message.quote.fullName}</span>
                    <span className="message">{quotedTxt}</span>
                </div>
            </div>
        )
    }

    // If its my message
    if (message.user_id === user.memberID) {
        msgWrapperClass += " own-message";
        msgClass += " own-message";
        userName = "You";
    }

    if (message.type === 'system_msg') {
        msgWrapperClass += " system-message";
    }

    // if its live webinar, show only users messages in full size
    if (liveWebinar) {
        // msgWrapperClass += " large";
    }

    // if message selected as favorite
    if (isHighlighted) {
        msgWrapperClass += " evidence";
    }

    switch (message.additionalMessageData) {
        case "plenary1":
            msgClass += " plenary_1"
            break;
        case "plenary2":
            msgClass += " plenary_2"
            break;
        case "inPerson":
            msgClass += " inPerson"
            break;
        case "digital":
            msgClass +=  " digital"
            break;
    }

    // Attachments
    const msgAttachs = message.attachments;
    if (msgAttachs && msgAttachs.length > 0) {
        attachments = msgAttachs.map(attach => {
            var previewImage = determinePreviewTag(attach);
            var tagTarget = "_blank"
            if (attach.type.match(/^img/i) || attach.type.match(/^image/i)) {
                tagTarget = "self"
            }
            var tag = (
                <a href={window.apiURL + "Resources/" + attach.id + "/Visualize/"} target={tagTarget}>
                    {previewImage}
                </a>
            )

            return (
                <div className="chat-attachment clearfix" data-id={attach.id}>
                    <div className="chat-attachment-file">{tag}</div>
                    <div className="chat-attachment-data">
                        <div className="chat-attachment-file-name">{attach.original_name}</div>
                        <div className="chat-attachment-file-size">{formatFileSize(attach.size)}</div>
                    </div>
                </div>
            )
        })
    }

    const toggleShowContactInfo = () => {
        setContactMessage(message);
        setShowContactBox(true);
    }

    return (
        <div className={msgWrapperClass}>
            {
                message.type === 'usermsg' ?
                    <>
                        {/* <div className={msgCircleClass}>		
                        <span>{userInitials}</span>
                    </div>	 */}
                        {
                            showContact &&
                            <div id={"message-contact-" + message.id} className="message-contact" onClick={toggleShowContactInfo}>
                                {message.user_id !== user.memberID ? "CONTACT" : "MY INFO"}
                            </div>
                        }


                        <div className={msgClass} id={`chat-message-${message.id}`} data-userid={message.user_id}
                        // onClick={(e: any) => { 
                        //     // #IMPORTANT: when clicking on buttons or images i don't want to fire this event
                        //     if(e.target.tagName !== "BUTTON" && e.target.tagName !== "IMG" && e.target.tagName !== "svg" && e.target.tagName !== "path")
                        //         highlightMessage(!isHighlighted)
                        // }}
                        >
                            <div className="chat-message-body">
                                <div className="chat-message-body-image">
                                    {
                                        showProfilePicture ?
                                            profilePicture ?
                                                <div className="chat-message-icon-wrapper">
                                                    <div className="chat-message-profile-picture" style={{ backgroundImage: `url(${profilePicture})` }} />
                                                </div>
                                                :
                                                <div className="chat-message-icon-wrapper">
                                                    <span className="chat-message-icon-text">
                                                        {userInitials}
                                                    </span>
                                                </div>
                                            :
                                            null
                                    }
                                </div>
                                <div className="chat-message-body-text">
                                    <header className="message-header">
                                        <div className="message-info-wrapper">
                                            <p className="msg-date">{msgDate}</p>
                                            <p className="msg-user">{msgUserName}</p>
                                        </div>
                                        <div className="message-buttons-wrapper clearfix">
                                            {/* <span className="float-left msg-date">{msgDate}</span> */}
                                            {/* <FontAwesomeIcon 
                                    icon={isHighlighted ? faStarFull : faStarEmpty} 
                                    className="icon-favorite" onClick={() => highlightMessage(!isHighlighted)} 
                                /> */}
                                            {/* { archiveButton } */}
                                            {deleteButton}
                                            {banButton}
                                            {
                                                showTextArea && !isBackstageChat ?
                                                    <div id="likeButton">
                                                        <button type="button" id={`chat-btn-like-id-${message.id}`}
                                                            className={"btn btn-like chat-message-id arrow-bottom" + (message.voted ? " selected" : "")}
                                                            data-messageid={message.id} data-userid={message.user_id}
                                                            data-username={message.name} data-userlastname={message.lastName}
                                                            onClick={handleLikeMessage}
                                                        >
                                                            <span>{message.upVotes || 0}</span>
                                                            <FontAwesomeIcon
                                                                icon={faThumbsUp}
                                                                title="Thumbs up"
                                                                className="icon-thumb"
                                                            />
                                                        </button>
                                                    </div>
                                                    : null
                                            }
                                            {
                                                showTextArea ?
                                                    <button type="button" className="btn btn-quote chat-message-id" id={`chat-btn-quote-id-${message.id}`}
                                                        data-messageid={message.id} data-userid={message.user_id} data-username={message.name} data-userlastname={message.lastName}
                                                        onClick={quoteMessage}>
                                                        {"Quote"}
                                                    </button> : null
                                            }
                                        </div>
                                    </header>
                                    {quotedMessage}
                                    <hr className="hr_message" />
                                    <p className="user_message">{ReactHtmlParser(message.message)}</p>
                                    {attachments}
                                    { /* TODO: check value archived_at in message */}
                                    <div className="message-badge-wrapper">
                                        {isMessageArchived && <span className="message-badge">Archived</span>}
                                    </div>
                                    {isNew && <p className="new-message">NEW!</p>}
                                </div>
                            </div>
                        </div>
                    </> :
                    <div className="system_msg">{ReactHtmlParser(message.message)}</div>
            }
            {
                // message.user_id !== user.memberID &&
                // <div id={"message-contact-" + message.id} className="message-contact" onClick={handlePrivateMessageOpen}>
                //     <FontAwesomeIcon icon={faReply} /> {"SEND PRIVATE MESSAGE"}
                // </div>
            }
            {
                // isPrivateMessageOpen &&
                // <div className="chat-message-private-message">
                //     <ChatPrivateMessage
                //         privateMessage={message} setPrivateMessage={setContactMessage} handlePrivateMessageOpen={handlePrivateMessageOpen}
                //     />
                // </div>
            }
        </div>
    )
}

export default ChatMessage;