import { useEffect, useRef } from "react";

interface IMousePos {
    left: number,
    top: number,
    x: number,
    y: number
}

export const useHorizontalScroller = (refElement: HTMLElement | null, deps: any = []) => {
    const grabbing = useRef(false);
    const pos = useRef<IMousePos>({ top: 0, left: 0, x: 0, y: 0 });

    useEffect(() => {
        if (refElement) {
            refElement.classList.add("horizontal-scroll");
            refElement.style.cursor = "grab";

            // Attach the handler
            refElement.addEventListener("pointermove", mouseMoveHandler)
            refElement.addEventListener("pointerdown", mouseDownHandler)
            document.addEventListener("pointerup", mouseUpHandler);
        }

        return () => {
            if (refElement) {
                // Remove the handler
                refElement.removeEventListener("pointermove", mouseMoveHandler)
                refElement.removeEventListener("pointerdown", mouseDownHandler)
                document.removeEventListener("pointerup", mouseUpHandler);
            }
        }
    }, [refElement, ...deps])

    function mouseMoveHandler(e: MouseEvent) {
        if (grabbing.current) {
            // How far the mouse has been moved
            const dx = e.clientX - pos.current.x;
            const dy = e.clientY - pos.current.y;

            // Scroll the element
            if (refElement) {
                refElement.scrollTop = pos.current.top - dy;
                refElement.scrollLeft = pos.current.left - dx;
            }
        }
    }

    function mouseDownHandler(e: MouseEvent) {
        if (refElement) {
            refElement.style.cursor = "grabbing";
            refElement.style.userSelect = "none";
            let _pos: IMousePos = {
                left: refElement.scrollLeft,
                top: refElement.scrollTop,
                x: e.clientX,
                y: e.clientY
            }
            grabbing.current = true;
            pos.current = _pos;
        }
    }

    function mouseUpHandler(e: MouseEvent) {
        grabbing.current = false;
        if (refElement) {
            refElement.style.cursor = "grab";
            refElement.style.removeProperty("user-select");
        }
    }
}