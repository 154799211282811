import React, { useRef } from "react";
import { CSSProperties } from "react";
import "../PluginsStyles/ColorPicker.scss";

interface IProps {
    value: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    style?: CSSProperties,
}

const ColorPicker = ({ value, onChange, style }: IProps) => {
    const inputRef = useRef<HTMLInputElement>(null!);
    return (
        <div className="color-picker" onClick={() => inputRef.current && inputRef.current.click()}>
            <input ref={inputRef} type={"color"} value={value || '#000'} onChange={e => onChange(e)} />
            <div className="preview" style={{ background: value || "#000" }} />
            <span>{value}</span>
        </div>
    )
}

export default ColorPicker