import React from 'react';

const TermsConditionsDigitalCourses = () => {
    return (
        <div className="terms-conditions p-2">
            {/* <h1>TERMS AND CONDITIONS FOR DIGITAL COURSES</h1> */}
            <h2 className="mt-1">REGISTRATION AND CANCELLATION POLICY</h2>

            <h4>I. - REGISTRATION</h4>
            Registration to Zimmer Biomet* educational digital courses or events (“Event”) is limited to Healthcare Professionals** (“Participant/s).
            In order to provide each Participant with a focused and personalized educational experience, the number of Participants is limited for each Event.
            Registration is accepted on a first-come, first-served basis.
            Registration is not confirmed until Participant receives a confirmation from Zimmer Biomet.

            <h4>II. - CANCELLATION POLICY</h4>
            Registration provides Participant with the option to attend the Event.
            Zimmer Biomet shall not provide any reimbursement, or pay any other expenses to Participant in the context of the Event.
            Zimmer Biomet reserves the right to change and/or cancel the Event at its own discretion.

            <h2 className="mt-3">EDUCATIONAL PURPOSE/NO MEDICAL ADVICE</h2>
            I. DISCLOSURE: Zimmer Biomet manufactures and distributes products discussed in the Event.
            For additional product information, please refer to the individual product labelling or the individual instructions for use for each Product.
            Product clearance and availability may be limited to certain countries/regions.
            Product information is intended for clinicians only and does not comprise medical advice or recommendations.
            Product information material may not be copied or reprinted without the express written consent of Zimmer Biomet.
            <br></br>
            <br></br>
            II. CONTENT DISCLAIMER: Participant must always be aware of the hazards of using limited knowledge in integrating new techniques or procedures into their practice.
            The information provided in the Event is of a general nature and does not represent or constitute medical advice or recommendations and is for orthopaedics education purposes only.
            Each Participant should exercise his or her own independent judgment in the diagnosis and treatment of individual patients.
            Zimmer Biomet events and information provided therein do not purport to replace the comprehensive training Participants have received.
            Zimmer Biomet  Events are designed to present a fair and balanced view of all options.

            <h2 className="mt-3">COMPLIANCE</h2>
            Attendance to the Event is independent of any sales transactions between Participant and Zimmer Biomet.
            Participation in the Event does not, and should at no time be construed, nor is intended to obligate Participant to purchase, recommend, administer, sell, use or otherwise deal with any Zimmer Biomet products.
            Attendance to the Event does not restrain Participant's independence as a healthcare professional.
            To the extent required by relevant laws, ethical rules and regulations and/or the relationship of Participant with Participant's hospital/clinic or employer,
            Participant acknowledges that it has disclosed attendance to this Event to the hospital/clinic or employer, or to any other relevant authority, in a suitable manner, and where so required has obtained the relevant approval.

            <h2 className="mt-3">DATA PRIVACY</h2>
            For information on how Zimmer Biomet may process Participant’s Personal Data, the types of Personal Data Zimmer Biomet may collect, how Zimmer Biomet uses,
            shares and protects these Personal Data, data protection rights and how to contact Zimmer Biomet about its privacy practices,
            please see Zimmer Biomet’s webpage and data privacy section provided on c-event registration page (https://www.zimmerbiomet.com/privacy-policy.html).

            <h2 className="mt-3">IMAGE RIGHTS</h2>
            The Participant acknowledges that the Zimmer Biomet Event will be recorded and subsequently transmitted and that, therefore, his/her image and/or voice may be eventually recorded, used, reproduced, transformed,
            distributed and/or communicated by Zimmer Biomet as a result of his/her attendance to the Zimmer Biomet course.
            By clicking “ACCEPT AND SEND” and attending the Zimmer Biomet Event, the Participant expressly consents for Zimmer Biomet to record, capture, use, reproduce, transform, distribute and communicate and/or publicly display all photographs, pictures, names or voices captured or recorded on the same during the Event, in whole or in part, as well as individually or together with photographs, pictures, names or voices of other Participants, in all formats and media, including digital transmission in the Internet, for marketing and educational purposes, in connection with any aspect of the Event.
            The Participant is entitled to withdraw his/her consent at any time by contacting privacy.emea@zimmerbiomet.com.
            The Participant will not take pictures, record or broadcast audio or video of the Event, especially regarding the presentations developed during such Event, nor facilitate their recording or broadcasting by third parties.

            <h2 className="mt-3">TRANSPARENCY REPORTING</h2>
            Participant further acknowledges that certain laws, ethical rules or regulations now or in the future may require disclosure of information on compensation, gifts or other remuneration provided to healthcare professionals and that Zimmer Biomet and its group companies shall be entitled fully to comply with any such laws, rules or regulations when and as applicable.
            * All references to Zimmer Biomet contained herein refer to the subsidiaries of Zimmer GmbH.
            ** Healthcare Professional (HCP) means an individual, within the continuum of care of a patient, who may purchase, lease, recommend, use, prescribe, or arrange for the purchase or lease of Zimmer Biomet products and services.
            <br></br>
            Unless otherwise indicated, as referenced herein, all trademarks are the property of Zimmer Biomet; and all products are manufactured by one or more of the subsidiaries of Zimmer GmbH, and distributed and marketed by Zimmer Biomet (and, in the case of distribution and marketing, its authorized marketing partners). EDU18658EM REVA07/20 ©2021 Zimmer Biomet. All rights reserved.
        </div>
    )
}

export default TermsConditionsDigitalCourses