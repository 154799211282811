import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import ExhibitionBoothPreview from '../../Components/ExhibitionArea/ExhibitionBoothPreview';
import UserHeader from '../../Components/UserHeader/UserHeader';
import { BoothTypeRes } from '../../Models/ResponseModels/Booths';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { StandsTypeRes } from '../../Models/ResponseModels/Stands';

interface IProps {
    userData: MembersTypeRes.IMember | null,
    groupData: MembersTypeRes.IGroupAssociation | null,
    boothGroups: BoothTypeRes.IBoothGroup[],
    selectedBoothGroupID: number,
    handleSelection: (standID: number) => void,
    setShowQuickMenu: Function,
    className?: string,
}
const itemPerPage = 9;
const ExhibitionAreaSelector = ({ userData, groupData, boothGroups, selectedBoothGroupID, handleSelection, setShowQuickMenu, className }: IProps) => {

    const [filteredBooth, setFilteredBooth] = useState<BoothTypeRes.IBoothGroup[]>([])
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(0);

    useEffect(() => {
        setFilteredBooth(boothGroups.slice(currentPage * itemPerPage, (currentPage * itemPerPage) + itemPerPage));
    }, [boothGroups])

    return (
        <div className={`exhibition-selector-wrapper p-3 ${className ? className : "background-glass"}`}>
            <div className="exhibition-selector d-flex flex-column text-center">
                <FontAwesomeIcon icon={faTimes} size="lg" className="exhibition-selector__close" onClick={() => setShowQuickMenu(false)} />
                <div className="exhibition-selector-header">
                    <UserHeader userData={userData} groupData={groupData} title='Exhibition Room' />
                </div>
                <div className="row exhibition-selector-boxes">
                    {
                        boothGroups && boothGroups.map((boothGroup: BoothTypeRes.IBoothGroup) => {
                            return (
                                <ExhibitionBoothPreview key={boothGroup.boothGroupID} selected={selectedBoothGroupID === boothGroup.boothGroupID} boothGroup={boothGroup} handleSelection={handleSelection} />
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}

export default ExhibitionAreaSelector;