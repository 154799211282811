// #IMPORTANT
// Get user and pass saved as glob vars, decrypt and use them to refresh token

import axios from 'axios';
import { initial } from 'lodash';
import { isIE } from '../config/functions';
import history from './history';

var CryptoJS = require("crypto-js");


const axiosInstance = axios.create({
  baseURL: window.apiURL
});

const axiosRetryApiCall = axios.create({
  baseURL: window.apiURL
});

axiosInstance.interceptors.request.use(async (request) => {
  // Do something with request data
  const token = window.storageGetItemValue("Auth-token");
  request.headers.Authorization = token ? token : ''
  if (process.env.REACT_APP_DEBUG === "true") {
    request.headers.SentBy = "zb-smartspace";
  }
  if (isIE()) {
    const url = request.url;
    request.url = url + (url.indexOf('?') > 0 ? '&' : '?') + 'id=' + new Date().getTime()
  }
  return request;
  // use an external js class to save useremail and password for refreshing the token
}, function (error) {
  return error;
});

axiosInstance.interceptors.response.use((response) => {
  window.lastApiRequest = Date.now();
  var newToken = response.headers["x-token"];
  if (newToken !== undefined) {
    if (newToken.length > 0) {
      window.storageSetItem("Auth-token", newToken);
    }
  }
  // Success response
  return response;
  // Error response
}, async (error) => {
  // NB: if error 400 (es. registering a member already registered) i don't have to go back to login
  if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
    // Redirect on login page only if i'm not already there and save the url for redirect
    // Avoid redirect if the current page is "change-password"
    if (history.location.pathname !== "/login" && history.location.pathname !== "/login/problem-solved" && history.location.pathname !== "/qr-resource") {
      window.clearAuthentication();
      window.removeStoredUserData();
      window.storageRemoveItem("redirectTo");
      if (history.location.pathname !== '/change-password/') {
        setTimeout(() => {
          history.push('/login');
        }, 300);
      }
    }
  }
  return Promise.reject(error);
});

export function getAxiosInstance(baseURL) {
  var instance = axios.create({
    baseURL: baseURL
  });

  instance.interceptors.request.use(async (request) => {
    // Do something with request data
    const token = window.storageGetItemValue("Auth-token");
    request.headers.Authorization = token ? token : ''
    if (isIE()) {
      const url = request.url;
      request.url = url + (url.indexOf('?') > 0 ? '&' : '?') + 'id=' + new Date().getTime()
    }
    return request;
    // use an external js class to save useremail and password for refreshing the token
  }, function (error) {
    return error;
  });

  instance.interceptors.response.use((response) => {
    window.lastApiRequest = Date.now();
    var newToken = response.headers["x-token"];
    if (newToken !== undefined) {
      if (newToken.length > 0) {
        window.storageSetItem("Auth-token", newToken);
      }
    }
    // Success response
    return response;
    // Error response
  }, async (error) => {
    // NB: if error 400 (es. registering a member already registered) i don't have to go back to login
    if (error && error.response && (error.response.status === 401 || error.response.status === 403)) {
      // Redirect on login page only if i'm not already there and save the url for redirect
      // Avoid redirect if the current page is "change-password"
      if (history.location.pathname !== "/login" && history.location.pathname !== "/login/problem-solved" && history.location.pathname !== "/qr-resource") {
        window.clearAuthentication();
        window.removeStoredUserData();
        window.storageRemoveItem("redirectTo");
        if (history.location.pathname !== '/change-password/') {
          setTimeout(() => {
            history.push('/login');
          }, 300);
        }
      }
    }
    return Promise.reject(error);
  });


  return instance;
}

export default axiosInstance;