import React, { useEffect, useState } from 'react';
import { BoothTypeRes } from '../../Models/ResponseModels/Booths';

interface IProps {
    selected: boolean,
    boothGroup: BoothTypeRes.IBoothGroup,
    handleSelection: (boothID: number) => void,
}

const ExhibitionBoothPreview = ({ selected, boothGroup, handleSelection }: IProps) => {
    const [firstBooth, setFirstBooth] = useState<BoothTypeRes.IBooth>()
    useEffect(() => { if (boothGroup.booths.length > 0) setFirstBooth(boothGroup.booths[0]) }, [boothGroup])

    return (
        <div key={boothGroup.boothGroupID} className={`exhibition-booth col-md-4 ${selected ? "selected" : ""} `}>
            {
                firstBooth ?
                    <>
                        {
                            <img src={(firstBooth.previewImageID || 0) > 0 ? window.apiURL + "Resources/" + firstBooth.previewImageID + "/Visualize/" : `${process.env.PUBLIC_URL}/assets/images/exhibition-area/new-booth.webp`}
                                alt={firstBooth.name}
                                className="exhibition-booth__image"
                                onClick={() => { if (!selected) { handleSelection(firstBooth.boothGroupID); } }} />
                        }
                        <h3 className="exhibition-booth__title font-weight-bold u-font-size-12 mt-3">
                            {firstBooth.name}
                        </h3>
                    </>
                    :
                    null
            }

        </div>
    )
}

export default ExhibitionBoothPreview;