import React, { useContext } from 'react'
import Button from './_UI/Button/Button';
import { useAuth0 } from "@auth0/auth0-react";
import { translations } from '../config/translations';
import { ContextAppSettings } from '../config/context';

const RegisterButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { language } = useContext(ContextAppSettings);

  return (
    <Button
      withClass={["blue", "small"]}
      classes="btn-register font-weight-bold"
      classIcon="btn-login-icon"
      clicked={(e) => { loginWithRedirect({
        authorizationParams: {
          screen_hint: "signup"
        }
      }); }}>
      {translations.register.register[language]}
    </Button>
  )
}

export default RegisterButton