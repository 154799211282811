import React from 'react';
import { MpaUi } from '@mixerpa/mpa-react-components.mpa-ui';
import { ReactComponent as AppleShareIcon } from "../../assets/icons/pwa/apple_share_icon.svg";

interface IProps {
    promptType: string,
    installFn: () => Promise<void>
}

const InstallAppPrompt = ({ promptType, installFn }: IProps) => {

    {/* Install prompt for compatible devices */ }
    const InstallPromptCompatible = () => {
        return (
            <div className="mpa-flex-center">
                <p className="mpa-mb-0">Install our app!</p>
                <MpaUi.Components.Button size='small' additionalClass="btn white mpa-ms-2 u-font-size-7" onClick={installFn}>
                    Install
                </MpaUi.Components.Button>
            </div>
        );
    }

    {/* Fallback: Install prompt for mobile devices (generic) */ }
    const InstallPromptFallback = () => {
        return (<>From the options, tap the <b>Add to Homescreen</b> option to install our app!</>);
    }

    {/* Fallback: Install prompt for mobile devices (iOS only) */ }
    const InstallPromptIOS = () => {
        return (<><b>Install</b> this webapp on your phone: tap
            <AppleShareIcon className="pwa-notification-share" />
            and then <b>Add to Homescreen</b></>
        );
    }

    let InstallPromptComponent = null
    switch (promptType) {
        case "ios":
            InstallPromptComponent = InstallPromptIOS;
            break;
        case "compatible":
            InstallPromptComponent = InstallPromptCompatible;
            break;
        default:
            InstallPromptComponent = InstallPromptFallback;
            break;

    }

    return (
        InstallPromptComponent ? <InstallPromptComponent /> : null
    )
}

export default InstallAppPrompt;