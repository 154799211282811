import { faEdit, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MpaUi } from "@mixerpa/mpa-react-components.mpa-ui";
import { IConfirmDialog } from "@mixerpa/mpa-react-components.mpa-ui/dist/components/confirmDialog";
import { showNotification } from "@mixerpa/mpa-react-components.mpa-ui/dist/utilities";
import useEventListener, { callEvent } from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import axios from "../../../config/axios";
import axiosInstance from "../../../config/axios";
import { ResourcesTypeRes } from "../../../Models/ResponseModels/Resources";

export const defaultNode: ResourcesTypeRes.IResource = {
    resourceID: 0,
    resourceTypeID: "DOCUMENT",
    resourceCategoryID: Number(process.env.REACT_APP_IN_PERSON_JOURNAL_CATEGORY_ID) || 93,
    isExternal: false,
    friendlyURL: "",
    link: "",
    title: "",
    subTitle: "",
    author: "",
    coAuthors: "",
    description: "",
    priority: 0,
    order: 0,
    startDate: new Date(),
    endDate: new Date(),
    additionalField01: "",
    additionalField02: "",
    additionalField03: "",
    additionalField04: "",
    additionalField05: "",
    public: false,
    published: false,
    resourceTags: [],
    // @ts-ignore
    resourceCategory: undefined,
    // @ts-ignore
    resourceType: undefined,
    resourceLanguage: "",
    editedAt: new Date(),
}

interface ITreeview {
    selectedNode: ISelectedNode | null,
    setSelectedNode: Dispatch<SetStateAction<ISelectedNode | null>>,
}

const Treeview = ({ selectedNode, setSelectedNode }: ITreeview) => {
    const [resources, setResources] = useState<ResourcesTypeRes.IResource[]>([]);
    const [homeNode, setHomeNode] = useState<ResourcesTypeRes.IResource | null>(null);

    useEffect(() => {
        LoadResources();
    }, [])

    useEventListener("postPageEdit", () => {
        LoadResources();
    })

    function LoadResources() {
        let homeNodeFound = false;
        // Get all resources related to the dynamic journal
        axiosInstance.get(`Resources/Category/${process.env.REACT_APP_IN_PERSON_JOURNAL_CATEGORY_ID}/Resources`).then(res => {
            if (res.data.status === "success") {
                let resources = res.data.data.collection as ResourcesTypeRes.IResource[];
                let homeResource = resources.find(x => x.friendlyURL === "/");
                if (homeResource) {
                    homeNodeFound = true;
                    setHomeNode(homeResource);
                    resources = resources.filter(x => x.resourceID !== homeResource!.resourceID);
                }
                setResources(resources);
                if (selectedNode) {
                    let _selectedNode = resources.find(x => x.title === selectedNode.title);
                    if (_selectedNode) {
                        setSelectedNode({ id: _selectedNode.resourceID, url: selectedNode.url, parentUrl: selectedNode.parentUrl, title: _selectedNode.title, isLandingPage: selectedNode.isLandingPage, published: _selectedNode.additionalField01 !== "false" })
                    }
                }
            }
        }).finally(() => { if (!homeNodeFound) { setHomeNode({ ...defaultNode, title: "Home" }) } });
    }

    return (
        <div className="pages">
            <h4>Page structure</h4>
            <div className="child-nodes">
                {
                    homeNode &&
                    <Node key={homeNode.resourceID} node={homeNode} resources={resources} selectedNode={selectedNode} setSelectedNode={setSelectedNode} isLandingPage />
                }
            </div>
        </div>
    )
}

interface INode {
    node: ResourcesTypeRes.IResource,
    resources: ResourcesTypeRes.IResource[],
    parentUrl?: string,
    isLandingPage?: boolean,
    selectedNode: ISelectedNode | null,
    setSelectedNode: Dispatch<SetStateAction<ISelectedNode | null>>,
}

export function isChildNode(resource: ResourcesTypeRes.IResource, node: ResourcesTypeRes.IResource) {
    // Check if parent is home
    let friendlyUrl = resource.friendlyURL;
    if (friendlyUrl.startsWith("/")) {
        friendlyUrl = friendlyUrl.substring(1);
    }
    let array = friendlyUrl.split("/");
    if (node.resourceID !== resource.resourceID) {
        if (node.friendlyURL === "/") {
            return array.length === 1;
        } else {
            friendlyUrl = resource.friendlyURL.replace(node.friendlyURL, "");
            if (friendlyUrl.startsWith("/")) {
                friendlyUrl = friendlyUrl.substring(1);
            }
            array = friendlyUrl.split("/");
            return array.length === 1;
        }
    } else {
        return false;
    }
}

const Node = ({ node, resources, parentUrl, isLandingPage, selectedNode, setSelectedNode }: INode) => {
    const [childNodes, setChildNodes] = useState<ResourcesTypeRes.IResource[]>([]);
    const [filteredResources, setFilteredResources] = useState<ResourcesTypeRes.IResource[]>([]);

    useEffect(() => {
        let childNodes = resources.filter(x => isChildNode(x, node));
        if (childNodes.length > 0) {
            setFilteredResources(resources.filter(x => childNodes.findIndex(y => y.resourceID === x.resourceID) === -1));
        }
        setChildNodes(childNodes);
    }, [node, resources])

    return (
        <div className="node">
            <div className={`content ${selectedNode && node.resourceID === selectedNode.id ? "selected" : ""}`}>
                <label>{node.title}</label>
                <FontAwesomeIcon icon={faEdit} onClick={() => setSelectedNode({ id: node.resourceID, url: node.link.replace(parentUrl || "", ""), parentUrl: parentUrl, title: node.title, isLandingPage: isLandingPage || false, published: node.additionalField01 !== "false" })} />
                <FontAwesomeIcon icon={faPlus} onClick={() => setSelectedNode({ id: 0, url: "/new-page", parentUrl: node.link, title: "New page", isLandingPage: false, published: false })} />
                <FontAwesomeIcon icon={faTrash} onClick={() => {
                    const modalData: IConfirmDialog = {
                        className: "gallery-heroes-confirm-dialog",
                        title: "Delete page",
                        text: `Proceed to delete page "${node.title}"`,
                        declineText: "Undo",
                        confirmText: "Delete",
                        onConfirm: () => {
                            axios.delete(`Resources/${node.resourceID}`)
                                .then(res => {
                                    const response = res.data;
                                    if (response.status === "success") {
                                        callEvent("postPageEdit");
                                        showNotification("success", "Page deleted");
                                    }
                                })
                                .catch((e) => showNotification("error", "Couldn't delete page"));
                        },
                    }
                    MpaUi.Utils.showConfirmDialog(modalData);
                }} />
            </div>
            <div className="child-nodes">
                {
                    childNodes.map(x =>
                        <Node key={x.resourceID} node={x} resources={filteredResources} parentUrl={node.link} selectedNode={selectedNode} setSelectedNode={setSelectedNode} />
                    )
                }
            </div>
        </div>
    )
}

export interface ISelectedNode {
    id: number,
    url: string,
    parentUrl: any;
    title: string,
    isLandingPage: boolean,
    published: boolean
}

export default Treeview;