import React, { useState } from "react";
import "../PluginsStyles/Anchor.scss";

interface IProps {
    selectedAnchor: string,
    callback: (selectedAnchor: string) => void
}

const anchorPoints = [
    "left top",
    "left center",
    "left bottom",
    "top",
    "center",
    "bottom",
    "right top",
    "right center",
    "right bottom",
]

const Anchor = ({ selectedAnchor, callback }: IProps) => {
    return (
        <div className="anchor-container">
            <div className="anchor">
                {
                    anchorPoints.map(x => <div key={x} className={x} onClick={() => callback(x)}>
                        {
                            selectedAnchor === x &&
                            <div className="selected" />
                        }
                    </div>)
                }
            </div>
        </div>
    )
}

export default Anchor;