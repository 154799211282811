import history from '../../config/history';
import { IDDOption } from "@mixerpa/mpa-react-components.mpa-common";

export interface IInPersonNavbarItem {
    ID: number,
    label: string,
    route?: string,
    onClick: Function,
    dropdownItems?: IDropdownItem[]
}

export interface IDropdownItem {
    ID: number,
    icon: string,
    label: string,
    route?: string,
    onClick?: Function,
}

export const InPersonNavbarItems: IInPersonNavbarItem[] = [
    {
        ID: 1,
        label: 'programme',
        route: '/problem-solved-madrid/program',
        onClick: () => {
            history.push('/problem-solved-madrid/program');
        },
    },
    {
        ID: 2,
        label: 'course information',
        onClick: () => { },
        dropdownItems: [
            {
                ID: 1,
                icon: 'chairsIcon',
                label: 'chairs',
                route: '/problem-solved-madrid/chairs',
                onClick: () => {
                    history.push('/problem-solved-madrid/chairs');
                },
            },
            {
                ID: 2,
                icon: 'facultyIcon',
                label: 'faculty',
                route: '/problem-solved-madrid/faculty',
                onClick: () => {
                    history.push('/problem-solved-madrid/faculty');
                },
            },
            {
                ID: 3,
                icon: 'scientificMaterialIcon',
                label: 'Related content',
                onClick: () => {
                    history.push('/problem-solved-madrid/scientific-material');
                },
            },
        ]
    },
    {
        ID: 3,
        label: 'interactive sessions',
        route: '/problem-solved-madrid/interactive-sessions',
        onClick: () => {
            history.push('/problem-solved-madrid/interactive-sessions');
        },
    },
    {
        ID: 4,
        label: 'logistics',
        onClick: () => { },
        dropdownItems: [
            {
                ID: 4,
                icon: 'hotel',
                label: 'Venue',
                onClick: () => {
                    history.push('/problem-solved-madrid/venue');
                },
            },
            {
                ID: 6,
                icon: 'car',
                label: 'Transfer information',
                onClick: () => {
                    history.push('/problem-solved-madrid/transfer-information');
                },
            },
            {
                ID: 7,
                icon: 'dinner',
                label: 'Dinner',
                onClick: () => {
                    history.push('/problem-solved-madrid/dinner');
                },
            },
            {
                ID: 8,
                icon: 'dress',
                label: 'Dress Code',
                onClick: () => {
                    history.push('/problem-solved-madrid/dress-code');
                },
            },
        ]
    },
    {
        ID: 5,
        label: 'info point',
        onClick: () => {
            history.push('/problem-solved-madrid/info-point');
        },
    },
    {
        ID: 6,
        label: 'interaction toolbox',
        onClick: () => {
            history.push('/problem-solved-madrid/interaction-toolbox')
        },
    },
    {
        ID: 8,
        label: 'e-journal',
        onClick: () => {
            history.push('/madrid-journal')
        },
    },
    {
        ID: 7,
        label: 'faculty area',
        onClick: () => {
            history.push('/problem-solved-madrid/faculty-area')
        },
    },
];

export const zbAreaOptions: IDDOption[] = getZbAreaOptions();

function getZbAreaOptions() {
    let _zbAreaOptions: IDDOption[] = [];
    if (process.env.REACT_APP_MADRID_IN_PERSON_BOOKING === "true") {
        _zbAreaOptions.push({
            label: "Bookings Panel",
            value: "bookings-panel"
        })
    }
    if (process.env.REACT_APP_MADRID_IN_PERSON_NOTIFICATIONS_PANEL === "true") {
        _zbAreaOptions.push({
            label: "Notifications Panel",
            value: "notifications-panel"
        })
    }
    if (process.env.REACT_APP_MADRID_IN_PERSON_VOTING_PANEL === "true") {
        _zbAreaOptions.push({
            label: "Voting Panel",
            value: "voting-panel"
        })
    }
    if (process.env.REACT_APP_MADRID_IN_PERSON_UPLOAD_DEPARTURES === "true") {
        _zbAreaOptions.push({
            label: "Upload Departures",
            value: "upload-departures"
        })
    }
    return _zbAreaOptions;
}