import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faYoutube, faLinkedinIn, faFacebook } from '@fortawesome/free-brands-svg-icons';
import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { Enum } from '../../Models/Enum';


interface IProps {
    path: string,
    userData: MembersTypeRes.IMember | null
}
const Footer = ({ path, userData }: IProps) => {
    const [hide, setHide] = useState(false);
    const [customClassName, setCustomClassName] = useState("");
    const [isMadrid, setIsMadrid] = useState(false);

    useEffect(() => {
        if (path.toLocaleLowerCase().indexOf("wheel-of-fortune-3d") > -1 ||
            path.indexOf("wheel-of-fortune-live") > -1 ||
            path.indexOf("wheel-of-fortune/leaderboard") > -1 ||
            path.indexOf("challenge") > -1 ||
            path.indexOf("gallery") > -1 ||
            path.indexOf("problem-solved-madrid-interactive-program") > -1 ||
            path.indexOf("problem-solved-madrid/3d-interactive-map") > -1 ||
            path.indexOf("madrid-journal") > -1 ||
            path.indexOf("qr-resource") > -1 ||
            path.toLocaleLowerCase().indexOf("voting-panel") > -1 ||
            path.toLocaleLowerCase().indexOf("madrid-shoulder") > -1 ||
            path.indexOf("exhibition-room-configurator") > -1) {
            setHide(true);
        } else {
            setHide(false);
        }
        if ((path.indexOf("st-lucia-awards") > -1 || path.indexOf("sales-awards-2021-photo-album") > -1 || path.indexOf("madrid-photo-album") > -1) && userData && (userData.memberTypeID === Enum.MemberType.ZBTeamMember || userData.memberTypeID === Enum.MemberType.MPAStaff)) {
            setCustomClassName("d-none");
        } else {
            setCustomClassName("");
        }
        if (path.indexOf("problem-solved-madrid") > -1) {
            setIsMadrid(true);
        } else {
            setIsMadrid(false);
        }
    }, [path])

    return (
        hide ?
            null :
            <footer id="footer" className={`footer ${customClassName}`}>
                <div className="footer-company">
                    <div className="footer-company-content container-fluid">
                        <div className="row">
                            <div className="col-xl-2 footer-logo-wrapper">
                                <div className="footer-logo">
                                    <img src="/assets/images/logo-zimmer-biomet.png" className="footer-logo-image img-fluid" alt="zimmer biomet" />
                                </div>
                            </div>
                            <div className="col-xl-7 footer-company-disclaimer">
                                <p className="u-font-size-6 mb-0">
                                    {
                                        isMadrid ?
                                            <>
                                                GUIDELINES: Zimmer Biomet is obliged and committed to ensure compliance with all applicable laws and regulations, including the MedTech Europe/Mecomed/SAMED Codes of Ethical Business Practice and other applicable industry standards. Zimmer Biomet would like to point out that the participation to our medical education meetings is restricted to selected Health Care Professionals. Zimmer Biomet cannot organize, facilitate or pay arrangements such as hotels, meals, transportation etc. to individuals not having a bona fide professional interest in the meeting, e.g. for spouses/partners or other accompanying persons not directly involved in the meeting. Please find more information on the standards of the mentioned associations on www.medtecheurope.org, www.mecomed.org and www.samed.org.za.
                                                All content herein is protected by copyright, trademarks and other intellectual property rights, as applicable, owned by or licensed to Zimmer Biomet or its affiliates unless otherwise indicated, and must not be redistributed, duplicated or disclosed, in whole or in part, without the express written consent of Zimmer Biomet. This material is intended for health care professionals. Distribution to any other recipient is prohibited.
                                                For product information, including indications, contraindications, warnings, precautions, potential adverse effects and patient counseling information, see the package insert and www.zimmerbiomet.com.
                                                Speaker(s) may have received remuneration from Zimmer Biomet.
                                                <br /><br />
                                            </>
                                            :
                                            <>
                                                DISCLAIMER: This material is intended for healthcare professionals. Distribution to any other recipient is prohibited. Not intended for surgeons practicing in France. The surgeon presenter is a paid consultant of Zimmer Biomet. The information being presented is of a general nature and does not represent or constitutes medical advice or recommendations, is for medical education purposes only and distribution of the information being presented may not be distributed outside the EMEA region. The information includes descriptions of conditions that a surgeon may encounter and treatment options that may be considered for those conditions. Because this information does not purport to constitute any diagnostic or therapeutic statement with regard to any individual medical case, each patient must be examined and advised individually, and this information does not replace the need for such examination and/or advice in whole or in part. Zimmer Biomet does not practice medicine. Each physician should exercise his or her own independent judgment in the diagnosis and treatment of an individual patient, and this information does not purport to replace the comprehensive training physicians have received. For product information, including indications, contraindications, warnings, precautions, potential adverse effects, and patient counseling information, see the package insert and www.zimmerbiomet.com. [As appropriate: A written surgical technique is available at www.zimmerbiomet.com or from your local Zimmer Biomet representative.] All content herein is protected by copyright, trademarks and other intellectual property rights, as applicable, owned by or licensed to Zimmer Biomet or its affiliates unless otherwise indicated, and must not be redistributed, duplicated or disclosed, in whole or in part, without the express written consent of Zimmer Biomet. ©{new Date().getFullYear()} Zimmer Biomet.<br /><br />
                                            </>
                                    }
                                </p>
                                <p className="u-font-size-8">
                                    To enjoy the zbsmartspace, please open it with the updated version of Chrome, Firefox, Safari or Edge (not working in Internet Explorer).
                                </p>
                            </div>
                            <div className="col-xl-3 footer-company-info">
                                <div className="row">
                                    <div className="col-xxl-3 col-xl-6 col-sm-3 col-xs-6 footer-company-content-address">
                                        <h1 className="u-font-size-8 font-weight-bold mb-3">
                                            {/* Zimmer Biomet */}
                                            Zimmer GmbH
                                        </h1>
                                        <p className="u-font-size-8 mb-0">
                                            {/* Sulzerallee 8<br/>CH-8404 Winterthur<br/>Switzerland */}
                                            Zählerweg 4<br />CH-6300 Zug<br />Switzerland
                                        </p>
                                        <a className="u-font-size-9" href="mailto:EMEA.events@zimmerbiomet.com" target="blank">Contact us</a>
                                    </div>
                                    <div className="col-xxl-3 col-xl-6 col-sm-3 col-xs-6 footer-company-content-legal">
                                        <h1 className="u-font-size-8 font-weight-bold mb-3">
                                            Legal
                                        </h1>
                                        <p className="u-font-size-8 mb-0">
                                            {/* #IMPORTANT: Absolute path */}
                                            <Link to="/legal-notice">Legal Notice</Link><br />
                                            <Link to="/terms-of-service">Terms of Service</Link><br />
                                            <a href="https://www.zimmerbiomet.eu/en/privacy-notice" target="blank">
                                                Privacy Policy
                                            </a><br />
                                            {/* <Link to="/privacy-policy">Privacy Policy</Link><br/> */}
                                            <Link to="/cookies-notice">Cookies Notice</Link>
                                        </p>
                                    </div>
                                    <div className="col-xxl-6 col-xl-12 col-sm-6 footer-company-content-social">
                                        <h1 className="u-font-size-8 font-weight-bold mb-3">
                                            Connect with us
                                        </h1>
                                        <p className="u-font-size-8 mb-3">
                                            <a href="https://www.facebook.com/zimmerbiomet" target="blank" title="Facebook" className="mr-2">
                                                <FontAwesomeIcon className="social-icon facebook" icon={faFacebookF} />
                                            </a>
                                            <a href="https://twitter.com/zimmerbiomet" target="blank" title="Twitter" className="mr-2">
                                                <FontAwesomeIcon className="social-icon twitter" icon={faTwitter} />
                                            </a>
                                            <a href="http://www.youtube.com/zimmer" target="blank" title="YouTube" className="mr-2">
                                                <FontAwesomeIcon className="social-icon youtube" icon={faYoutube} />
                                            </a>
                                            <a href="https://www.linkedin.com/company/zimmer-biomet-institute-emea" target="blank" title="Linkedin">
                                                <FontAwesomeIcon className="social-icon linkedin" icon={faLinkedinIn} />
                                            </a>
                                        </p>
                                        <p className="u-font-size-8 mb-0">
                                            <a href="https://www.zbinetworkemea.com" target="blank">www.zbinetworkemea.com</a><br />
                                            <a href="https://www.zimmerbiomet.eu//" target="blank">www.zimmerbiomet.eu</a><br />
                                            <a href="https://zbinetwork.zimmerbiomet.com" target="blank">www.zbinetwork.zimmerbiomet.com</a>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer >
    )
}

export default Footer;