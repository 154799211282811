// components/user/Container.js
import React, { CSSProperties, ReactNode, useState } from "react";
import { useEditor, useNode } from "@craftjs/core";
import "../PluginsStyles/Carousel.scss";
import RangeInput from "./Range";
import { useHorizontalScroller } from "../../../../Components/_hooks/useHorizontalScroller";

interface IProps {
    children?: ReactNode | ReactNode[],
    style?: CSSProperties,
}

export const Carousel = ({ children, style }: IProps) => {
    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled
    }));

    const { connectors: { connect, drag } } = useNode((node) => ({
        props: node.data
    }))

    const [carousel, setCarousel] = useState<HTMLDivElement | null>(null)
    useHorizontalScroller(carousel, [carousel, enabled]);

    return (
        <div className="carousel-container">
            <div className={`carousel ${enabled ? "edit-mode" : ""}`} ref={ref => { if (ref) { setCarousel(ref); connect(drag(ref)) } }} style={{ ...style, overflowX: enabled ? "auto" : "hidden" }}>
                {children ? children : <span className="dummy">No elements</span>}
            </div>
        </div>
    )
}

export const CarouselSettings = () => {
    const { gap, actions: { setProp } } = useNode(node => ({
        gap: node.data.props.style.gap
    }));
    return (
        <form>
            <div className="option">
                <label>Gap<span>({gap})</span></label>
                <RangeInput value={gap.replace("px", "")} min={16} max={100} steps={1} style={{ fontSize: "0.5rem" }} onChange={e => { setProp((props: { style: CSSProperties }) => props.style.gap = `${e.target.value}px`) }} />
            </div>
        </form>
    )
}

Carousel.craft = {
    props: {
        style: {
            gap: "16px",
        }
    },
    related: {
        settings: CarouselSettings
    }
}