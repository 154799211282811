import { faEdit, faSearch, faUpload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { MpaUi } from "@mixerpa/mpa-react-components.mpa-ui";
import { callEvent } from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";
import moment from "moment";
import React from "react";
import axios from "../../../config/axios";
import { BoothTypeRes } from "../../../Models/ResponseModels/Booths";

interface IProps {
  selectedBoothGroup: BoothTypeRes.IBoothGroup,
  selectedBoothID: number,
  preview?: boolean,
  setSelectedBooth: React.Dispatch<React.SetStateAction<BoothTypeRes.IBooth | null>>,
}

const BoothHistory = ({ selectedBoothGroup, selectedBoothID, setSelectedBooth, preview }: IProps) => {

  function handlePublish(boothID: number) {
    axios.post(`Booths/Publish/${boothID}`)
      .then(res => {
        callEvent("reloadBooths", { selectedBoothID: selectedBoothGroup.boothGroupID });
        MpaUi.Utils.showNotification("success", "Published booth updated successfully");
      })
      .catch(e => console.log("Error while updating published booth", e))
  }

  return (
    <div className="exhibition-configurator-history">
      <h2>Booth history</h2>
      <hr />
      <div className="booths">
        {
          selectedBoothGroup.booths.map(x => <div key={x.boothID} className={`booth${selectedBoothID === x.boothID ? " selected" : ""}`} onClick={() => setSelectedBooth(x)}><FontAwesomeIcon icon={preview ? faSearch : faEdit}></FontAwesomeIcon><span></span><span>{x.published ? "PUBLISHED" : moment(x.editedAt).format("DD/MM/YYYY HH:mm")}</span> {preview ? null : <FontAwesomeIcon icon={faUpload} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handlePublish(x.boothID) }}></FontAwesomeIcon>} </div>)
        }
      </div>
    </div>
  )
}

export default BoothHistory;