import React from 'react';

const InPersonLoader = () => {
    return (
        <div className="in-person-page-loader">
            <div className="lds-dual-ring"></div>
            <div className="lds-dual-ring-2"></div>
        </div>
    );
}
export default InPersonLoader;