import { useEditor } from "@craftjs/core";
import { faForward, faReply } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useEventListener from "@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener";
import React, { useEffect } from "react";
import axiosInstance from "../../../config/axios";
import { dataURLtoFile } from "../../../config/functions";

interface IProps {
    readonly: boolean
    handleSavePage: (base64: string) => void
}

export const TopBar = ({ readonly, handleSavePage }: IProps) => {
    const { actions, query, enabled, canUndo, canRedo } = useEditor((state, query) => ({
        enabled: state.options.enabled,
        canUndo: state.options.enabled && query.history.canUndo(),
        canRedo: state.options.enabled && query.history.canRedo(),
    }));

    useEventListener("postPageEdit", () => {
        actions.setOptions(options => {
            options.enabled = true
        })
    })

    useEffect(() => {
        actions.setOptions(options => {
            options.enabled = !readonly
        })
    }, [readonly])

    return (
        <div className="topbar">
            <div className="title">
                <h2>Editor Journal</h2>
            </div>
            <div className="options">
                <button
                    className={`button-undo-redo ${!canUndo && 'disabled'}`}
                    disabled={!canUndo}
                    onClick={() => actions.history.undo()}
                >
                    <FontAwesomeIcon icon={faReply} size="lg" />
                </button>
                <button
                    className={`button-undo-redo ${!canRedo && 'disabled'}`}
                    disabled={!canRedo}
                    onClick={() => actions.history.redo()}
                >
                    <FontAwesomeIcon icon={faReply} size="lg" style={{ transform: 'rotateY(180deg)' }} />
                </button>
                <div className="topbar-toggle">
                    {/* <label htmlFor="finish-edit"> Editing </label> */}
                    <span style={!enabled ? { fontWeight: 'bold' } : { fontWeight: 'normal', opacity: 0.5 }} >Finish Edit</span>
                    <input id="finish-edit" type="checkbox" checked={enabled} onChange={(e) => {
                        actions.setOptions(options => {
                            options.enabled = Boolean(e.target.checked)
                        })
                    }} />
                    <label htmlFor="finish-edit"></label>
                    <span style={enabled ? { fontWeight: 'bold' } : { fontWeight: 'normal', opacity: 0.5 }}>Editing</span>
                </div>               
                <button style={!enabled ? { visibility: 'visible' } : { visibility: 'hidden' }} className="editor-button save" onClick={() => {
                    // Add or Update page resource:
                    let json = query.serialize();
                    let base64 = `data:application/json;base64,${btoa(unescape(JSON.stringify(json)))}`;
                    handleSavePage(base64);
                }}> Save </button>
            </div>
        </div>
    )
}