import React from 'react';
import { AchievementsTypeRes } from '../Models/ResponseModels/Achievements';
import { EventsTypeRes } from '../Models/ResponseModels/Events';
import { MembersTypeRes } from '../Models/ResponseModels/Members';

export type TCurrentRoute = '' | 'the-early-night-show-2022-edition';
export type TMainTemplate = 'Template01' | 'Template02' | 'Template03';
export type TMainLanguage = 'en' | 'de' | 'es' | 'it' | 'fr';
export type IForm = 'default' | 'registration' | 'login';

export interface IAppSettings {
    language: TMainLanguage,
    currentRoute: TCurrentRoute,
    currentEvent: EventsTypeRes.IEvent | null,
    uiState: number,
    template: TMainTemplate,
    msTeamsData: string,
    isChatVisible: boolean,
    isPollsVisible: boolean,
    eventsLight: EventsTypeRes.IEventLight[],
    events: EventsTypeRes.IEvent[],
    isAttendeeOfCurrentEvent: boolean,
    isDemoEvent: boolean,
    isTens2022: boolean,
    isMadridDigital: boolean,
    isMadridInPerson: boolean,
    achievements: AchievementsTypeRes.IAchievement[] | null,
    setAchievements: Function | null,
    setAuthToken: (token: string | null, callbackFn?: Function) => void,
    setProfileData: (member: MembersTypeRes.IMember) => void,
}

const initAppSettings: IAppSettings = {
    language: 'en',
    currentRoute: '',
    currentEvent: null,
    uiState: 0,
    template: 'Template01',
    msTeamsData: '',
    isChatVisible: false,
    isPollsVisible: false,
    eventsLight: [],
    events: [],
    isAttendeeOfCurrentEvent: false,
    isDemoEvent: false,
    isTens2022: false,
    isMadridDigital: false,
    isMadridInPerson: false,
    achievements: null,
    setAchievements: null,
    setAuthToken: () => { },
    setProfileData: () => { }
}

export const ContextAppSettings = React.createContext(initAppSettings);