import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { updateButtonEffect } from "../../config/functions";
// import { updateButtonEffect } from "../../config/functions";

interface IProps {
    numberMax: number,
    setNumberMax: Function,
    isWsOpen: boolean,
    setIsWsOpen: Function,
    isForcedToFinish: boolean,
    setIsForcedToFinish: Function,
    setCurrentNumberClicks: Function,
    isFallbackActive: boolean,
    setIsFallbackActive: Function,
}

let timeout: NodeJS.Timeout;

const TensChallengePanel = ({ numberMax, setNumberMax, isWsOpen, setIsWsOpen, isForcedToFinish, setIsForcedToFinish, setCurrentNumberClicks, isFallbackActive, setIsFallbackActive }: IProps) => {
    const [animateLiveBtn, setAnimateLiveBtn] = useState(false);
    // const [liveBtnText, setLiveBtnText] = useState("Like!");
    const inputNumberMax = useRef<HTMLInputElement>(null!);
    const _animating = useRef(false);

    useEffect(() => {
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        }
    }, []);

    const sendNumberMax = () => {
        if (inputNumberMax.current) {
            setNumberMax(inputNumberMax.current.value);
            setCurrentNumberClicks(0);
            setIsForcedToFinish(false);
        }
    }

    const resetAll = () => {
        if (inputNumberMax.current) {
            setNumberMax(inputNumberMax.current.value);
        }
        setIsForcedToFinish(false);
        setCurrentNumberClicks(0);
        setIsWsOpen(false);
    }

    const animateLiveButton = () => {
        if (!_animating.current) {
            _animating.current = true;
            updateButtonEffect(window.chatRoomLive, { id: 'btnLiveStream', animate: true })
            timeout = setTimeout(() => { _animating.current = false }, 1250);
        }
    }

    // const updateLiveButtonText = () => {
    //     updateButtonEffect(window.chatRoomLive, { id: 'btnLiveStream', text: liveBtnText })
    // }

    return (
        <div className="challenge-panel-container">
            <div className="d-flex flex-column">
               
                {/* Config panel */}
                <div className="d-flex flex-column challenge-panel-config w-100">
                    <span className="challenge-panel-warning text-black mr-auto ml-auto w-100"><h1>Websocket is now {isWsOpen ? "open" : "closed"}</h1></span>
                    <div className="mr-auto ml-auto d-flex flex-column justify-content-center">
                        <br />
                        <label htmlFor="max-value" className="text-black">Number to reach <b>(Current max value: {numberMax})</b></label>
                        <div className="d-flex flex-row">
                            <input ref={inputNumberMax} id="max-value" type="number" defaultValue={numberMax} />
                            <button className="challenge-panel-button ml-2" onClick={(e) => { e.currentTarget.blur(); sendNumberMax() }}>SUBMIT</button>
                            <button className="challenge-panel-button ml-2" onClick={(e) => { e.currentTarget.blur(); resetAll(); }}><span className="text-green">R</span>ESET</button>
                        </div>
                        <br />
                        <div className="d-flex flex-row justify-content-between">
                            <button className="challenge-panel-button" onClick={(e) => { e.currentTarget.blur(); setIsForcedToFinish(!isForcedToFinish); }}><span className="text-yellow">F</span>ORCE TO FINISH</button>
                            <button className="challenge-panel-button ml-2" onClick={(e) => { e.currentTarget.blur(); animateLiveButton(); }}>ANIMATE LIVE BUTTON</button>
                        </div>
                        <br />
                        {
                            /*
                            <div className="d-flex w-75 justify-content-between">
                            <button className="challenge-panel-button flex-shrink-0" onClick={animateLiveButton}>ANIMATE LIVE BUTTON</button>
                            <button className="challenge-panel-button flex-shrink-0 ml-2" onClick={updateLiveButtonText}>CHANGE LIVE BUTTON TEXT</button>
                            <input id="btnText" type="text" className="form-control flex-shrink-1 ml-2" value={liveBtnText}
                                onChange={(e) => setLiveBtnText(e.target.value)} ></input> 
                            </div>    
                            <br />
                            */
                        }
                        <div className="d-flex flex-row justify-content-between">
                            <button className="challenge-panel-button" onClick={(e) => { e.currentTarget.blur(); setIsWsOpen(true); }}>START WEBSOCKET</button>
                            <button className="challenge-panel-button ml-2" onClick={(e) => { e.currentTarget.blur(); setIsWsOpen(false); }}>STOP WEBSOCKET</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column challenge-panel-values w-50 mt-5">
                <b className="text-black">Current MAX value: {numberMax}</b>
                <b className="text-black">Forced to finish active? {isForcedToFinish ? "yes" : "no"}</b>
                <b className="text-black">Fallback active? {isFallbackActive ? "yes" : "no"}</b>
            </div>
             {/* Info */}
             <div className="d-flex flex-column justify-content-start challenge-panel-shortcut w-75 mt-5">
                    <h1><span className="text-pink">Shortcuts:</span></h1>
                    <br />
                    <span className="text-blue">O - Toggle configuration panel</span>
                    <span className="text-orange">Spacebar - START / STOP WEBSOCKET</span>
                    <span className="text-yellow">F - Simulate users clicks on the live button till it reaches the current max value</span>
                    <span className="text-green">R - Reset all configuration to its initial value and reset user click</span>
                    <span className="text-pink">S - Swap the current rider images with the other one</span>
                    <span className="text-blue">A - Animate live button</span>
                    <span className="text-orange">B - Toggle fallback animation of the rider if the default one is not working</span>
                </div>
        </div>
    )
}

export default TensChallengePanel;