import React, { useContext, useEffect, useRef, useState } from 'react';

// Form fields with validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import moment from 'moment';
import Resizer from 'react-image-file-resizer';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSquare, faCheckSquare } from '@fortawesome/free-regular-svg-icons';
import { faCalendarAlt, faTimes } from '@fortawesome/free-solid-svg-icons';

// Lightbox to show image inserted
import Lightbox from 'react-image-lightbox';

import axios from '../../config/axios';
import history from '../../config/history';
import { validateEmail, validatePassword, fileToBase64, dataURLtoFile } from '../../config/functions';

import { MembersTypeRes } from '../../Models/ResponseModels/Members';
import { TagsTypeRes } from '../../Models/Tags';
import { ContextAppSettings, IForm } from '../../config/context';
import Button from '../_UI/Button/Button';
import ModalMessage from '../_UI/Modal/ModalMessage';
import TakePicture from '../TakePicture/TakePicture';
import TermsConditions from '../Legal/TermsConditions';
import TermsConditionsDigitalCourses from '../Legal/TermsConditionsDigitalCourses';
import { translations } from '../../config/translations';
import ReactHtmlParser from 'react-html-parser';
import { MpaUi } from '@mixerpa/mpa-react-components.mpa-ui';

interface IProps {
    type: 'new' | 'edit' | 'edit-admin',
    fnHideForm?: () => void,
    isBtnFormActiveVisible?: boolean,
    setFormActive?: (activeForm: IForm) => void,
    idMemberToEdit?: number,
    template?: "default" | "problem-solved",
}

interface IFormValues {
    first_access: string,
    type_reg: { label: string, value: string } | null,
    title: { label: string, value: string } | null,
    firstName: string,
    lastName: string,
    email: string,
    gender: { label: string, value: string } | null,
    birth: Date | null,
    interests: { label: string, value: string }[],
    hospital: string,
    MPA: string,
    ZB_Employ: string,
    city: string,
    country: { label: string, value: string } | null,
    password: string,
    password2: string,
    pictureUploaded: boolean,
    changePassword: boolean,
    passwordOld: string,
    passwordNew: string,
    emailing_consent: boolean,
    confidentiality: boolean,
    terms: boolean,
    termsCourses: boolean,
    biography: string,
    approved: boolean,
    registeredFrom: string | null,
    eanNumber: string | null,
}

// -- title
const titleOptions = [
    { value: 'Mr', label: 'Mr' },
    { value: 'Ms', label: 'Ms' },
    { value: 'Mrs', label: 'Mrs' },
    { value: 'Dr', label: 'Dr' },
    { value: 'Dr. Ms', label: 'Dr. Ms' },
    { value: 'Prof', label: 'Prof' },
    { value: 'A/Prof', label: 'A/Prof' },
]

// -- type of user
const userTypeOptions = [
    { value: '1', label: 'H.C.P. (Health Care Professionals)' },
    { value: '2', label: 'Zimmer Biomet Team Member' },
]

const initialValues: IFormValues = {
    first_access: 'N',
    type_reg: null,
    title: null,
    firstName: '',
    lastName: '',
    email: '',
    gender: null,
    birth: null,
    hospital: '',
    MPA: 'Mixer Prod.Action',
    ZB_Employ: '',
    city: '',
    country: null,
    interests: [],
    password: '',
    password2: '',
    pictureUploaded: false,
    changePassword: false,
    passwordOld: '',
    passwordNew: '',
    emailing_consent: false,
    confidentiality: false,
    terms: false,
    termsCourses: false,
    biography: '',
    approved: false,
    registeredFrom: 'register_from_zbsmartspace',
    eanNumber: null,
}
let memberID: number;
let memberGuid: string;
let anagraphicGuid: string;

const RegistrationForm = ({
    type,
    isBtnFormActiveVisible = true,
    fnHideForm = () => { },
    setFormActive = () => { },
    idMemberToEdit,
    template }: IProps) => {
    const [showTakeAPicture, setShowTakeAPicture] = useState(false);
    const [countries, setCountries] = useState<MembersTypeRes.ICountry[]>([]);
    const [interests, setInterests] = useState<TagsTypeRes.ITag[]>([]);
    const [initValues, setInitValues] = useState<any>(initialValues);

    const [showModalDeleteProfile, setShowModalDeleteProfile] = useState<boolean>(false);

    // Base 64 for profile picture
    const [base64, setBase64] = useState("");
    // File received from take a picture
    const [fileUploaded, setFileUploaded] = useState<File | null>(null);
    // When submitting form avoid executing API call twice
    const [submittingForm, setSubmittingForm] = useState<boolean>(false);
    // Manage errors
    const [showValidation, setValidation] = useState<boolean>(false);
    const [statusError, setStatusError] = useState<number>(0);
    const [error, setError] = useState<string>('');

    const smallDevice = window.matchMedia('(max-width: 575.98px)').matches;

    const userData = useRef<MembersTypeRes.IMember | null>(null);
    const { language } = useContext(ContextAppSettings);

    // -- gender 
    const genderOptions = [
        { value: 'Male', label: translations.gender.male[language] },
        { value: 'Female', label: translations.gender.female[language] },
        { value: 'Prefer not to say', label: translations.gender.prefer_not_say[language] },
    ]

    const signupSchema = Yup.object().shape({
        first_access: Yup.string().nullable(),
        type_reg: Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
        })
            .required(translations.error.required[language])
            .nullable(),
        title: Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
        })
            .required(translations.error.required[language])
            .nullable(),
        firstName: Yup.string()
            .required(translations.error.required[language]),
        lastName: Yup.string()
            .required(translations.error.required[language]),
        gender: Yup.object().shape({
            label: Yup.string().required(),
            value: Yup.string().required(),
        })
            .required(translations.error.required[language])
            .nullable(),
        birth: Yup.date().nullable()
            .max(new Date()),
        hospital: Yup.string().when('type_reg.value', {
            is: '1' || '4',
            then: Yup.string().required(translations.error.required[language]),
            otherwise: Yup.string(),
        }),
        ZB_Employ: Yup.string().when('type_reg.value', {
            is: '2',
            then: Yup.string().required(translations.error.required[language]),
            otherwise: Yup.string(),
        }),
        city: Yup.string()
            .required(translations.error.required[language]),
        country: Yup.object().shape({
            countryName: Yup.string().required(),
            countryID: Yup.string().required(),
        })
            .required(translations.error.required[language])
            .nullable(),
        email: Yup.string()
            .email(translations.error.invalid_email[language])
            .required(translations.error.required[language]),
        interests: Yup.array().of(Yup.string()),
        password: Yup.string().when(type, {
            is: 'new',
            then: Yup.string().min(8, translations.error.password_short[language]).max(50, translations.error.password_long[language]).required(translations.error.required[language]),
            otherwise: Yup.string(),
        }),
        changePassword: Yup.bool(),
        passwordOld: Yup.string().when('changePassword', {
            is: true,
            then: Yup.string().min(8, translations.error.password_short[language]).max(50, translations.error.password_long[language]).required(translations.error.required[language]),
            otherwise: Yup.string(),
        }),
        passwordNew: Yup.string().when('changePassword', {
            is: true,
            then: Yup.string().min(8, translations.error.password_short[language]).max(50, translations.error.password_long[language]).required(translations.error.required[language]),
            otherwise: Yup.string(),
        }),
        terms: Yup.bool()
            .oneOf([true], translations.error.required[language]),
        termsCourses: Yup.bool()
            .oneOf([true], translations.error.required[language]),
        emailing_consent: Yup.bool(),
        /*
        confidentiality: Yup.bool()
            .oneOf([true], translations.error.required[language]),
            */
        biography: Yup.string(),
        approved: Yup.bool(),
        registeredFrom: Yup.string().nullable(),
    });

    const handleSubmit = async (values: any) => {

        // If i'm already calling API avoid doing it twice
        if (submittingForm) return;

        setStatusError(0);
        setError('');
        setValidation(false);
        setSubmittingForm(true);

        const type_reg = values.type_reg ? values.type_reg.value : '';
        const title = values.title ? values.title.value : '';
        const gender = values.gender ? values.gender.value : '';
        const countryID = values.country ? values.country.countryID : '0';
        const interests = values.interests;

        const memberData: any = {
            companyID: 1,
            title: title,
            memberTypeID: parseInt(type_reg),
            name: values.firstName,
            lastName: values.lastName,
            email: values.email,
            gender: gender,
            sendRegistrationEmail: true,
            interests: interests,
            tokenNeeded: true,
            disabled: false,
            termsAndConditions: values.terms,
            termsAndConditionsDigitalCourses: values.termsCourses,
            mailingList: values.emailing_consent,
            confidentiality: values.confidentiality,
            additionalField01: type_reg === '1' || type_reg === '4' ? values.hospital :
                (type_reg === '2' ? values.ZB_Employ : values.MPA),
            resident: userData && userData.current ? userData.current.resident : null,
            expectedYearCompletion: userData && userData.current ? userData.current.expectedYearCompletion : null,
        }

        if (values.birth) {
            memberData.birthDate = values.birth
        }

        if (type === 'edit' || type === 'edit-admin') {
            memberData.memberID = memberID;
            memberData.memberGuid = memberGuid;
            memberData.biography = values.biography;
            memberData.disabled = !values.approved;
            memberData.additionalField02 = values.registeredFrom;
            memberData.additionalField03 = null;
            memberData.eanNumber = values.eanNumber;
            if (!fileUploaded && !base64) {
                memberData.profilePictureID = 0;
            }
        }

        if (type === 'new') {
            memberData.password = values.password;
            if (template === "problem-solved") {
                memberData.additionalField02 = 'registered_from_problem_solved_digital';
            }
            else {
                memberData.additionalField02 = 'registered_from_zbsmartspace';
            }
        }

        memberData.memberAnagraphics = [{
            countryID: parseInt(countryID),
            anagraphicDescription: 'Registration',
            address: "",
            city: values.city,
            region: "",
            taxCode: "",
            vat: "",
            default: true,
            additionalField01: "",
            additionalField02: "",
            additionalField03: "",
            additionalField04: "",
            additionalField05: ""
        }];

        // If we're editing an already existing member, we keep his anagraphicGuid.
        if (type === 'edit' || type === 'edit-admin') {
            memberData.memberAnagraphics[0].anagraphicGuid = anagraphicGuid;
        }

        // Execute registration or edit profile
        if (type === 'new') {
            axios.post('Members', memberData)
                .then(res => {
                    fnSuccess(res, '/register-success' + (template === "problem-solved" ? "/problem-solved" : ""));
                })
                .catch(e => {
                    fnFailed(e);
                })
        } else {
            axios.put('Members/' + memberID, memberData)
                .then(res => {
                    if (type === 'edit-admin') {
                        fnSuccess(res, '/users-list');
                    }
                    else {
                        window.dispatchEvent(new CustomEvent('reloadProfile'));
                        fnSuccess(res);
                    }
                })
                .catch(e => {
                    fnFailed(e);
                })
        }

    }

    const saveProfilePicture = async (memberID: number) => {
        if (base64) {
            let resourceData = new FormData();
            resourceData.append('memberID', memberID.toString());
            const fileUploaded: File = dataURLtoFile(base64, `profile-picture-${memberID}.png`);
            resourceData.append('AttachedFile', fileUploaded);
            const res = await axios.post('Members/UploadProfileImage', resourceData);
            if (res.data && res.data.status === 'success') {

            } else {
                return {
                    status: "error",
                    data: {},
                    message: 'An error has occurred while uploading the profile picture'
                }
            }
        }

        return {
            status: "success",
            data: {},
            message: ''
        }


    }

    const fnSuccess = async (apiResult: any, redirectPath?: string) => {
        console.log("Redirect to", redirectPath);
        const response = apiResult.data;
        if (response.status === 'success') {
            const userId = type === 'new' ? response.data.memberID : memberID;
            if (type === 'new') {
                const token = response.data.token;
                window.storageSetItem("Auth-token", token);
            }
            const savedProfilePicture = await saveProfilePicture(userId);
            if (type === 'new') {
                window.storageRemoveItem("Auth-token");
            }
            toggleFirstAccess(false);
            setStatusError(200);
            if (type === 'edit') {
                MpaUi.Utils.showNotification("success", "Your profile has been successfully updated!");
                window.dispatchEvent(new CustomEvent("reloadProfile"));
            }
            setTimeout(() => { setSubmittingForm(false); fnHideForm(); redirectPath && history.push(redirectPath); setFormActive('default'); }, 500);
        } else {
            setSubmittingForm(false);
            setError(response.data)
        }
    }

    const fnFailed = (error: any) => {
        const response = error.response;
        setSubmittingForm(false);
        if (response.data.status === "error") {
            setError(response.data.message)
        } else {
            setError("An error has occurred")
        }
    }

    const deleteUserProfile = () => {
        axios.delete('Members/' + memberID, {
            data: { "mailchimpUnsubscribe": true }
        })
            .then(res => {
                window.storageRemoveItem("Auth-token");
                window.removeStoredUserData();
                history.push('/login');
            });
    }

    const toggleFirstAccess = (value: boolean) => {
        if (value) {
            window.storageSetItem("isFirstAccess", 'S');
        } else {
            window.storageSetItem("isFirstAccess", 'N');
        }
    }

    // -- VALIDATION AND MODEL FUNCTIONS

    const formValidateEmail = (email: string) => {
        let error;
        if (!validateEmail(email))
            error = translations.error.invalid_email[language];
        return error;
    }

    const formValidatePassword = (psw: string) => {
        let error;
        if (!validatePassword(psw)) {
            error = 'Invalid password';
        }
        return error;
    }

    const formValidatePassword2 = (psw: string, mainPsw: string) => {
        let error;
        if (!validatePassword(psw)) {
            error = 'Invalid password';
        } else {
            if (psw !== mainPsw) {
                error = 'Doesn\'t match password field';
            }
        }
        return error;
    }

    const isPictureUploadable = (file: File) => {
        const filesize = file.size;
        const maximum_size = 20 * 1024 * 1024;
        if (filesize > maximum_size) {
            alert("Maximum filesize exceeded. The submitted file can't exceed 20 MB");
            return false;
        }
        return true;
    }

    const resizeFile = (file: File) => new Promise(resolve => {
        const type = file.type == 'image/png' ? 'PNG' : 'JPEG';
        Resizer.imageFileResizer(file, 1920, 1080, type, 90, 0,
            uri => {
                resolve(uri);
            },
            'file'
        );
    });

    useEffect(() => {

        if (type === 'edit' || type === 'edit-admin') {
            setSubmittingForm(true);
        }

        (async function getData() {
            // Get countries
            const resCountries = await axios.get('Countries');
            setCountries(resCountries.data.data);

            // Get tags
            const resTagsInterests = await axios.get('Tags/Categories/3');
            let tags: TagsTypeRes.ITag[] = resTagsInterests.data.data;
            setInterests(tags);

            if (type === 'new') {
                setInitValues({ ...initValues, interests: tags.map(x => x.tagDescription) });
            }

            var apiCall = '';

            if (type === 'edit')
                apiCall = 'Members/GetMyProfile?id=' + new Date().getTime();

            if (type === 'edit-admin')
                apiCall = 'Members/' + idMemberToEdit;

            if (type === 'edit' || type === 'edit-admin') {
                axios.get(apiCall).then(async res => {
                    const response = res.data;
                    if (response.status === "success") {
                        const member: MembersTypeRes.IMember = response.data;
                        userData.current = response.data;
                        memberID = member.memberID;
                        memberGuid = member.memberGuid;
                        if (member.anagraphics?.length > 0) {
                            anagraphicGuid = member.anagraphics[0].anagraphicGuid;
                        }

                        // -- get profile picture (if inserted)
                        if (member.hasProfilePicture) {
                            const resProfilePic = await axios.get(`Members/${memberID}/ProfileImage`);
                            if (resProfilePic && resProfilePic.data && resProfilePic.data.status === 'success') {
                                setBase64(resProfilePic.data.data.content);
                            }
                        }

                        let title = null;
                        let titleFound = titleOptions.find(x => x.value === member.title);
                        if (titleFound) {
                            title = {
                                label: titleFound.label,
                                value: titleFound.value
                            }
                        }

                        const getMemberTypeLabel = (memberTypeID: number) => {
                            switch (memberTypeID) {
                                case 1: return "H.C.P. (Health Care Professionals)";
                                case 2: return "Zimmer Biomet Team Member";
                                case 3: return "MPA Staff";
                                case 4: return "Faculty";
                                case 5: return "External Vendor";
                                default: return "";
                            }
                        }

                        const type_reg = {
                            label: getMemberTypeLabel(member.memberTypeID), // member.memberTypeID === 1 ? 'H.C.P. (Health Care Professionals)' : (member.memberTypeID === 2 ? 'Zimmer Biomet Team Member' : 'MPA Staff'),
                            value: member.memberTypeID.toString()
                        }

                        const country = member.anagraphics.length > 0 && member.anagraphics[0].country && (
                            {
                                countryName: member.anagraphics[0].country.countryName.toString(),
                                countryID: member.anagraphics[0].country.countryID.toString()
                            }
                        )

                        const gender = member.gender ? {
                            label: genderOptions.find(x => x.value === member.gender)?.label,
                            value: member.gender
                        } : null

                        setInitValues({
                            title: title,
                            type_reg: type_reg,
                            firstName: member.name,
                            lastName: member.lastName,
                            memberTypeID: member.memberTypeID,
                            gender: gender,
                            birth: member.birthDate ? moment(member.birthDate).set({ hour: 12 }) : '',
                            hospital: member.additionalField01 ? member.additionalField01 : '',
                            MPA: 'Mixer Prod.Action',
                            ZB_Employ: member.additionalField01 ? member.additionalField01 : '',
                            city: member.anagraphics[0].city,
                            country: country,
                            email: member.email,
                            interests: member.interests,
                            password: 'password',
                            password2: 'password',
                            pictureUploaded: member.hasProfilePicture,
                            emailing_consent: member.mailingList,
                            confidentiality: member.confidentiality,
                            biography: member.biography ? member.biography : '',
                            approved: !member.disabled,
                            registeredFrom: member.additionalField02,
                            eanNumber: member.eanNumber,
                            terms: member.termsAndConditions,
                            termsCourses: member.termsAndConditionsDigitalCourses,
                        });
                    }
                    setTimeout(() => setSubmittingForm(false), 200);
                }).catch(e => {
                    setTimeout(() => setSubmittingForm(false), 200);
                })
            } else {
                setTimeout(() => setSubmittingForm(false), 200);
            }
        })();
    }, [])



    return (
        <div id="registrationContainer" className={"registration-form-wrapper"}>
            <Formik
                enableReinitialize
                initialValues={initValues}
                validationSchema={signupSchema}
                onSubmit={values => {
                    handleSubmit(values);
                }}
            >
                {({ errors, touched, values, isValid, setFieldValue, validateField, setFieldTouched }) => (
                    <Form className="main-form main-form--register">
                        {/* loader */}
                        {submittingForm && (
                            <div className="spinner-wrapper">
                                <div className="spinner-border color-blue" role="status"></div>
                            </div>
                        )}
                        {/* content */}
                        <div className={"main-form-content" + (submittingForm ? ' main-form-content--disabled' : '')}>
                            {
                                isBtnFormActiveVisible &&
                                <FontAwesomeIcon
                                    className="main-form__close-icon"
                                    icon={faTimes}
                                    onClick={() => setFormActive('default')}
                                />
                            }
                            <p className="main-form-content-text u-font-size-10 text-center mb-md-5 mb-4">
                                {
                                    type === 'new' ?
                                        <>
                                            {ReactHtmlParser(translations.policy.if_already[language])}
                                            <br />
                                            {translations.policy.by_registering[language]} <a href="https://www.zbinetworkemea.com" target="_blank">www.zbinetworkemea.com</a>
                                        </> :
                                        <> {ReactHtmlParser(translations.profile.update_info[language])}</>
                                }
                            </p>

                            <div className="row">
                                <div className="col-lg-6">
                                    {/* first name & last name */}
                                    <div className="main-form__group form-row">
                                        <div className="col-md-6">
                                            <label className="main-form__label" htmlFor="firstName">{translations.profile.name[language]}</label>
                                            <Field
                                                name="firstName"
                                                type="text"
                                                className="form-control main-form__field"
                                            />
                                            <div className={`invalid-feedback u-font-size-9 ${errors.firstName && touched.firstName ? "d-block" : ""}`}>
                                                {errors.firstName}
                                            </div>
                                        </div>
                                        <div className="col-md-6 mt-md-0 mt-3">
                                            <label className="main-form__label" htmlFor="lastName">{translations.profile.surname[language]}</label>
                                            <Field
                                                name="lastName"
                                                type="text"
                                                className="form-control main-form__field"
                                            />
                                            <div className={`invalid-feedback u-font-size-9 ${errors.lastName && touched.lastName ? "d-block" : ""}`}>
                                                {errors.lastName}
                                            </div>
                                        </div>
                                    </div>
                                    {/* type of user */}
                                    {
                                        <div className="main-form__group">
                                            <label className="main-form__label" htmlFor="type_reg">{translations.register.type_of_user[language]}</label>
                                            <Select
                                                id="typereg"
                                                className={"main-form__select " + (type === 'edit' ? 'disabled' : '')}
                                                placeholder={translations.register.select_title[language]}
                                                options={userTypeOptions}
                                                value={values.type_reg}
                                                onChange={(value: any) => { setFieldValue('type_reg', value) }}
                                                onBlur={() => setFieldTouched('type_reg', true)}
                                                isDisabled={type === 'edit'}
                                                isSearchable={false}
                                            />
                                            <div className={`invalid-feedback u-font-size-9 ${errors.type_reg && touched.type_reg ? "d-block" : ""}`}>
                                                {errors.type_reg}
                                            </div>
                                        </div>
                                    }
                                    {/* title */}
                                    <div className="main-form__group">
                                        <label className="main-form__label" htmlFor="title">{translations.profile.title[language]}</label>
                                        <Select
                                            id="title"
                                            className="main-form__select"
                                            placeholder="Please select a title"
                                            options={titleOptions}
                                            value={values.title}
                                            onChange={(value: any) => { setFieldValue('title', value) }}
                                            onBlur={() => setFieldTouched('title', true)}
                                            isSearchable={false}
                                        />
                                        <div className={`invalid-feedback u-font-size-9 ${errors.title && touched.title ? "d-block" : ""}`}>
                                            {errors.title}
                                        </div>
                                    </div>
                                    {/* gender*/}
                                    <div className="main-form__group">
                                        <label className="main-form__label" htmlFor="gender">{translations.profile.gender[language]}</label>
                                        <Select
                                            id="gender"
                                            className="main-form__select"
                                            placeholder="Please choose an option"
                                            options={genderOptions}
                                            value={values.gender}
                                            onChange={(value: any) => { setFieldValue('gender', value) }}
                                            onBlur={() => setFieldTouched('gender', true)}
                                            isSearchable={false}
                                        />
                                        <div className={`invalid-feedback u-font-size-9 ${errors.gender && touched.gender ? "d-block" : ""}`}>
                                            {errors.gender}
                                        </div>
                                    </div>
                                    {/* area of interests */}
                                    <div className="main-form__group">
                                        <label className="main-form__label" htmlFor="interests">
                                            {translations.register.area_of_interests[language]}
                                        </label>
                                        <Select
                                            id="interests"
                                            className="main-form__select"
                                            placeholder={translations.register.choose_area_of_interests[language]}
                                            isMulti
                                            options={interests}
                                            value={interests.filter(x => values.interests.findIndex((y: string) => x.tagDescription === y) > -1)}
                                            getOptionLabel={(option: TagsTypeRes.ITag) => option.tagDescription}
                                            getOptionValue={(option: TagsTypeRes.ITag) => option.tagDescription}
                                            onChange={(value: TagsTypeRes.ITag[]) => { console.log("on change select interests", value); setFieldValue('interests', value !== null ? value.map(x => x.tagDescription) : []) }}
                                            onBlur={() => setFieldTouched('interests', true)}
                                            isSearchable={false}
                                        />
                                    </div>
                                    {/* date of birth */}
                                    <div className="main-form__group">
                                        <label className="main-form__label" htmlFor="birth">{translations.register.date_of_birth[language]}</label>
                                        <div className='input-group datetime-picker' id='datetimepicker6'>
                                            <DatePicker
                                                className="form-control main-form__field"
                                                selected={values.birth ? moment(values.birth).toDate() : null}
                                                maxDate={new Date()}
                                                dateFormat="dd/MM/yyyy"
                                                placeholderText="DD/MM/YYYY"
                                                name="birth"
                                                onChange={(date: Date | null) => { setFieldValue('birth', date ? new Date(date.setHours(12)) : null) }}
                                                withPortal={smallDevice}
                                                showYearDropdown
                                                autoComplete="off"
                                            />
                                            <FontAwesomeIcon className="datetime-picker-calendar" icon={faCalendarAlt} />
                                        </div>
                                        <div className={`invalid-feedback u-font-size-9 ${errors.birth && touched.birth ? "d-block" : ""}`}>
                                            {errors.birth}
                                        </div>
                                    </div>
                                </div>

                                <div className="col-lg-6">
                                    {/* clinic / hospital */}
                                    <div className={"main-form__group" + (values.type_reg && (values.type_reg.value === '1' || values.type_reg.value === '4') ? "" : " d-none")}>
                                        <label className="main-form__label" htmlFor="hospital">
                                            {translations.register.clinic[language]}
                                        </label>
                                        <Field
                                            name="hospital"
                                            type="text"
                                            className="form-control main-form__field"
                                        />
                                        <div className={`invalid-feedback u-font-size-9 ${errors.hospital && touched.hospital ? "d-block" : ""}`}>
                                            {errors.hospital}
                                        </div>
                                    </div>
                                    <div className={"main-form__group" + (values.type_reg && values.type_reg.value === '2' ? "" : " d-none")}>
                                        <label className="main-form__label" htmlFor="ZB_Employ">
                                            ZB Employee / ZB Sales Rep. / ZB Distributor:
                                        </label>
                                        <Field
                                            name="ZB_Employ"
                                            type="text"
                                            className="form-control main-form__field"
                                        />
                                        <div className={`invalid-feedback u-font-size-9 ${errors.ZB_Employ && touched.ZB_Employ ? "d-block" : ""}`}>
                                            {errors.ZB_Employ}
                                        </div>
                                    </div>
                                    <div className={"main-form__group" + (values.type_reg && values.type_reg.value === '3' ? "" : " d-none")}>
                                        <label className="main-form__label" htmlFor="MPA">
                                            MPA:
                                        </label>
                                        <Field
                                            name="MPA"
                                            type="text"
                                            className="form-control main-form__field"
                                            disabled={true}
                                        />
                                        <div className={`invalid-feedback u-font-size-9 ${errors.MPA && touched.MPA ? "d-block" : ""}`}>
                                            {errors.MPA}
                                        </div>
                                    </div>

                                    {/* city */}
                                    <div className="main-form__group">
                                        <label className="main-form__label" htmlFor="city">
                                            {translations.register.city[language]}
                                        </label>
                                        <Field
                                            name="city"
                                            type="text"
                                            className="form-control main-form__field"
                                        />
                                        <div className={`invalid-feedback u-font-size-9 ${errors.city && touched.city ? "d-block" : ""}`}>
                                            {errors.city}
                                        </div>
                                    </div>

                                    {/* country */}
                                    <div className="main-form__group">
                                        <label className="main-form__label" htmlFor="country">  {translations.register.country[language]}</label>
                                        <Select
                                            id="country"
                                            options={countries}
                                            className="main-form__select"
                                            placeholder={translations.register.select_a_country[language]}
                                            getOptionLabel={(option: MembersTypeRes.ICountry) => option.countryName}
                                            getOptionValue={(option: MembersTypeRes.ICountry) => option.countryID}
                                            onChange={(value: MembersTypeRes.ICountry) => setFieldValue('country', value)}
                                            onBlur={() => setFieldTouched('country', true)}
                                            value={values.country}
                                        />
                                        <div className={`invalid-feedback u-font-size-9 ${errors.country && touched.country ? "d-block" : ""}`}>
                                            {errors.country}
                                        </div>
                                    </div>

                                    {/* Email */}
                                    <div className="main-form__group">
                                        <label className="main-form__label" htmlFor="email">
                                            Email <span className="u-font-size-9">({translations.profile.email_lowercase[language]})</span>
                                        </label>
                                        <Field
                                            name="email"
                                            type="email"
                                            className="form-control main-form__field"
                                            onChange={(e: any) => { setFieldValue('email', e.target.value.toLowerCase()) }}
                                            disabled={type === 'edit' || type === 'edit-admin'}
                                        />
                                        <div className={`invalid-feedback u-font-size-9 ${errors.email && touched.email ? "d-block" : ""}`}>
                                            {errors.email}
                                        </div>
                                    </div>

                                    {/* password */}
                                    {
                                        type === 'new' &&
                                        <div className="main-form__group form-row">
                                            <div className="col-md-6">
                                                <label className="main-form__label" htmlFor="password">{translations.profile.password[language]}</label>
                                                <Field
                                                    name="password"
                                                    type="password"
                                                    placeholder="Min. 8 characters"
                                                    className="form-control main-form__field"
                                                    validate={() => formValidatePassword(values.password)}
                                                />
                                                <div className={`invalid-feedback u-font-size-9 ${errors.password && touched.password ? "d-block" : ""}`}>
                                                    {errors.password}
                                                </div>
                                            </div>
                                            <div className="col-md-6 mt-md-0 mt-3">
                                                <label className="main-form__label" htmlFor="password2">{translations.register.confirm_password[language]}</label>
                                                <Field
                                                    name="password2"
                                                    type="password"
                                                    placeholder="Min. 8 characters"
                                                    className="form-control main-form__field"
                                                    validate={() => formValidatePassword2(values.password2, values.password)}
                                                />
                                                <div className={`invalid-feedback u-font-size-9 ${errors.password2 && touched.password2 ? "d-block" : ""}`}>
                                                    {errors.password2}
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* profile picture */}
                                    <div className="main-form__group">
                                        {
                                            base64 &&
                                            <div
                                                className="main-form__subgroup_profile_pic-wrapper cursor-pointer"
                                                style={{ backgroundImage: `url(${base64})` }}
                                                onClick={() => setShowTakeAPicture(true)}
                                            >
                                                <img
                                                    className="main-form__subgroup_profile_pic-wrapper-img img-fluid cursor-pointer"
                                                    id="img-prevew"
                                                    src={base64}
                                                    alt="preview"
                                                    onClick={() => setShowTakeAPicture(true)}
                                                />
                                            </div>
                                        }
                                        <div className="main-form__subgroup_profile_pic">
                                            {!base64 &&
                                                <div className="main-form__subgroup_profile_pic-img-wrapper cursor-pointer">
                                                    <img
                                                        className="main-form__subgroup_profile_pic-img main-form__subgroup_profile_pic-img--portrait img-fluid"
                                                        src="/assets/icons/icon-portrait-grey.png"
                                                        alt="take/upload picture"
                                                        onClick={() => { setShowTakeAPicture(true) }}
                                                    />
                                                </div>
                                            }
                                            <div className="main-form__subgroup_profile_pic-buttons">
                                                <Button type="button"
                                                    withClass={["blue", "center", "small"]}
                                                    classes="main-form__subgroup_profile_pic-button font-weight-bold"
                                                    clicked={() => { setShowTakeAPicture(true) }}
                                                >{translations.profile.take_a_picture[language]}</Button>
                                                {
                                                    base64 &&
                                                    <Button type="button"
                                                        withClass={["red", "center", "small"]}
                                                        classes="main-form__subgroup_profile_pic-button font-weight-bold"
                                                        clicked={() => { setBase64(""); setFieldValue('pictureUploaded', false) }}
                                                    >{translations.profile.remove[language]}</Button>
                                                }
                                            </div>
                                        </div>
                                        <span className="main-form__subgroup_profile_pic-desc u-font-size-8 mt-2">
                                            ({translations.profile.maximum_file_size[language]})
                                        </span>
                                    </div>
                                </div>
                            </div>


                            {/* email consent */}
                            <div className="main-form__group mt-2">
                                <div className="main-form__checkbox">
                                    <label className="main-form__label" style={{ display: 'flex' }}>
                                        <Field name="emailing_consent" type="checkbox" checked={values.emailing_consent} className="main-form__checkbox-input" />
                                        <FontAwesomeIcon
                                            icon={!values.emailing_consent ? faSquare : faCheckSquare}
                                            className="main-form__checkbox-icon"
                                        />
                                        <div>
                                            <p className="u-font-size-9 font-weight-bold mb-1">
                                                {translations.policy.consent_to_receive[language]}
                                            </p>
                                            <p className="u-font-size-8 mb-0">
                                                {translations.register.unsubscribe[language]}
                                            </p>
                                        </div>
                                    </label>
                                </div>
                                <div className={`invalid-feedback u-font-size-9 ${errors.emailing_consent && touched.emailing_consent ? "d-block" : ""}`}>
                                    {errors.emailing_consent}
                                </div>
                            </div>

                            {/* confidentiality */}
                            {/*
                            <div className="main-form__group">
                                <div className="main-form__checkbox">
                                    <label className="main-form__label" style={{ display: 'flex' }}>
                                        <Field name="confidentiality" type="checkbox" checked={values.confidentiality} className="main-form__checkbox-input" />
                                        <FontAwesomeIcon
                                            icon={!values.confidentiality ? faSquare : faCheckSquare}
                                            className="main-form__checkbox-icon"
                                        />
                                        <div>
                                            <p className="u-font-size-9 font-weight-bold mb-1">
                                                CONFIDENTIALITY
                                            </p>
                                            <p className="u-font-size-8 mb-0">
                                                You have been invited to attend Zimmer Biomet The Early Night Show 2021 digital meeting. During the presentation, Zimmer Biomet will share some confidential Information which constitutes the property and trade secrets of Zimmer Biomet. By ticking the box, you agree not to disclose, and treat strictly confidential these confidential Information and not divulge such confidential information or knowledge to any person, corporation or other entity or use the confidential information either by direct or indirect means, nor verbally, nor in writing, nor by any other demonstrative means whatsoever except as expressly permitted by Zimmer Biomet.
                                            </p>
                                        </div>
                                    </label>
                                </div>
                                <div className={`invalid-feedback u-font-size-9 ${errors.confidentiality && touched.confidentiality ? "d-block" : ""}`}>
                                    {errors.confidentiality}
                                </div>
                            </div>
                            */}

                            {/* terms of service */}
                            {
                                (type === 'new' || !initValues.terms) &&
                                <div className="main-form__group">
                                    <div className="main-form__checkbox">
                                        <label className="main-form__label" style={{ display: 'flex' }}>
                                            <Field name="terms" type="checkbox" checked={values.terms} className="main-form__checkbox-input" />
                                            <FontAwesomeIcon
                                                icon={!values.terms ? faSquare : faCheckSquare}
                                                className="main-form__checkbox-icon"
                                            />
                                            <p className="u-font-size-9 font-weight-bold mb-0">
                                                {translations.register.accept_terms[language]}
                                            </p>
                                        </label>
                                    </div>
                                    <div className={`invalid-feedback u-font-size-9 ${errors.terms && touched.terms ? "d-block" : ""}`}>
                                        {errors.terms}
                                    </div>
                                </div>
                            }

                            {/* terms and conditions */}
                            {
                                (type === 'new' || !initValues.terms) &&
                                <div className="main-form__group">
                                    <TermsConditions />
                                </div>
                            }

                            {/* terms and conditions for digital events */}
                            {
                                (type === 'new' || !initValues.termsCourses) &&
                                <div className="main-form__group">
                                    <div className="main-form__checkbox">
                                        <label className="main-form__label" style={{ display: 'flex' }}>
                                            <Field name="termsCourses" type="checkbox" checked={values.termsCourses} className="main-form__checkbox-input" />
                                            <FontAwesomeIcon
                                                icon={!values.termsCourses ? faSquare : faCheckSquare}
                                                className="main-form__checkbox-icon"
                                            />
                                            <p className="u-font-size-9 font-weight-bold mb-0">
                                                {translations.register.accept_terms_digital[language]}
                                            </p>
                                        </label>
                                    </div>
                                    <div className={`invalid-feedback u-font-size-9 ${errors.termsCourses && touched.termsCourses ? "d-block" : ""}`}>
                                        {errors.termsCourses}
                                    </div>
                                </div>
                            }
                            {
                                (type === 'new' || !initValues.termsCourses) &&
                                <div className="main-form__group">
                                    <TermsConditionsDigitalCourses />
                                </div>
                            }

                            {/* biography */}
                            {
                                // type === 'edit' &&
                                // <div className="main-form__group">
                                //     <label htmlFor="edit_desc_member">Description / Biography:</label>
                                //     <textarea className="form-control tinymce" rows={10} id="desc_member" name="edit_desc_member" 
                                //         onChange={(e) => setFieldValue('biography', e.target.value)} defaultValue={values.biography} >
                                //     </textarea>
                                // </div>
                            }

                            <div className="main-form-buttons">
                                {
                                    type !== "new" ?
                                        <Button type="button"
                                            withClass={["white", "underlined", "left"]}
                                            classes="main-form__delete font-weight-bold"
                                            clicked={() => setShowModalDeleteProfile(true)}
                                            // clicked={() => window.open('https://auth.zbinetworkemea.com/Identity/Account/Manage/PersonalData?template=zbsmartspace')}
                                            disabled={submittingForm}
                                        >{translations.profile.delete_account[language]}</Button>
                                        : null
                                }
                                {
                                    !(type === 'edit' && !initValues.terms) &&
                                    <Button type="button"
                                        withClass={["white"]}
                                        classes="main-form__close font-weight-bold"
                                        clicked={() => {
                                            type === "new" ? setFormActive('default') : fnHideForm()
                                        }}
                                        disabled={submittingForm}
                                    >
                                        {translations.survey.check_answers_button[language]}
                                    </Button>
                                }
                                <Button type="submit"
                                    withClass={["blue"]}
                                    classes="main-form__submit font-weight-bold"
                                    clicked={() => setValidation(true)}
                                    disabled={submittingForm}
                                >{type === "new" ? translations.register.register[language] : translations.profile.save[language]}</Button>
                            </div>

                            {showValidation && !isValid && <div className="invalid-feedback u-font-size-10 d-block">
                                Please fill all mandatory fields
                            </div>}
                            {statusError === 200 && <div className="valid-feedback text-center u-font-size-10 d-inline-block">{translations.profile.successfully_updated[language]}</div>}
                            {statusError === 401 && <div className="invalid-feedback text-center u-font-size-10 d-inline-block">{translations.error.unauthorized[language]}</div>}
                            {statusError === 403 && <div className="invalid-feedback text-center u-font-size-10 d-inline-block">{translations.error.forbidden[language]}</div>}
                            {statusError === 404 && <div className="invalid-feedback text-center u-font-size-10 d-inline-block">{translations.error.network_error[language]}</div>}
                            {![0, 200, 401, 403, 404].includes(statusError) &&
                                <div className="invalid-feedback text-center u-font-size-10 d-inline-block">{translations.error.generic_error[language]}</div>}
                            <div className={`invalid-feedback text-center u-font-size-10 ${error.length > 0 ? "d-inline-block" : ""}`}>
                                {error}
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>

{/* modal delete account */ }
            <ModalMessage
                title={translations.profile.delete_account[language]}
                text={<p className="px-3">{ReactHtmlParser(translations.profile.delete_account_info[language])}</p>}
                buttons={[
                    { text: translations.survey.check_answers_button[language], className: "font-weight-bold btn-white", action: () => setShowModalDeleteProfile(false), },
                    { text: translations.profile.yes_delete_account[language], className: "font-weight-bold red", action: () => { setShowModalDeleteProfile(false); deleteUserProfile() } },
                ]}
                isVisible={showModalDeleteProfile}
                closeButton={true}
                className="modal-default modal-default--shadow"
                fnHideModal={() => setShowModalDeleteProfile(false)}
            />

            <TakeAPictureModal showTakeAPicture={showTakeAPicture} setShowTakeAPicture={setShowTakeAPicture} handleConfirm={(base64) => { setBase64(base64); setShowTakeAPicture(false); }} />
        </div >
    )
}

interface ITakeAPictureModalProps {
    showTakeAPicture: boolean,
    setShowTakeAPicture: Function,
    handleConfirm: (base64: string) => void,
}

const TakeAPictureModal = ({ showTakeAPicture, setShowTakeAPicture, handleConfirm }: ITakeAPictureModalProps) => {
    return (
        <MpaUi.Components.ModalMessage isVisible={showTakeAPicture} closeButton fnHideModal={() => setShowTakeAPicture(false)} size="xl">
            <MpaUi.Containers.TakePictureManager handleConfirm={handleConfirm} options={{ allowTakeAPicture: true, allowUpload: true, enableEdit: true }} />
        </MpaUi.Components.ModalMessage>
    )
}

export default RegistrationForm;