import React from 'react';

const SvgGradients = () => {
    return (
        <svg width={0} height={0} style={{ display: "block" }}>
            <linearGradient id="svgstarempty" gradientUnits="userSpaceOnUse" x1="-0.0011208" y1="11.9471" x2="20.9929" y2="11.9471" gradientTransform="matrix(1 0 0 -1 0 22)">
                <stop offset="0" style={{ stopColor: "rgb(0, 160, 247)" }} ></stop>
                <stop offset="0.25" style={{ stopColor: "rgb(0, 148, 246)" }}></stop>
                <stop offset="0.7" style={{ stopColor: "rgb(0, 117, 244)" }}></stop>
                <stop offset="1" style={{ stopColor: "rgb(0, 94, 243)" }}></stop>
            </linearGradient>
        </svg>
    )
}

export default SvgGradients;