import React, { CSSProperties, useEffect, useState } from "react";
import _ from "lodash";
import "../PluginsStyles/Range.scss";

interface IProps {
    value: string,
    min: number,
    max: number,
    steps: number,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
    style?: CSSProperties,
}

const RangeInput = ({ value, min, max, steps, onChange, style }: IProps) => {
    const [id] = useState(_.uniqueId("range_"));

    useEffect(() => {
        let range = document.getElementById(id);
        if (range) {
            range.style.setProperty('--val', value);
            range.style.setProperty('--min', min.toString());
            range.style.setProperty('--max', max.toString());
        }
    }, [value, min, max])

    return (
        <input id={id} type="range" value={value} min={min} step={steps} max={max} style={style} onChange={e => {
            e.target.style.setProperty('--val', e.target.value);
            onChange(e)
        }} />
    )
}

export default RangeInput;