import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import React, { CSSProperties, useEffect } from 'react';

interface IProps {
    iconSvg?: any,
    imgSource?: string,
    title: string,
    subtitle?: string,
    showCloseButton?: boolean,
    clickFunction?: () => void,
    style?: CSSProperties
}

const LivePageSectionHeader = ({ iconSvg, imgSource, title, subtitle, showCloseButton, clickFunction, style }: IProps) => {

    useEffect(() => {

    }, [])

    return (
        <header className="live-page-section-header" style={style ? style : {}}>
            {iconSvg}
            {
                imgSource &&
                <img className="live-page-section-header__icon" src={imgSource} alt="icon" />
            }
            <div className="live-page-section-header-content">
                <h2 className="live-page-section-header__title u-font-size-12">{title}</h2>
                <hr className="live-page-section-header__separator" />
                {
                    subtitle &&
                    <h3 className="live-page-section-header__subtitle u-font-size-7">{subtitle}</h3>
                }
            </div>
            {
                showCloseButton &&
                <FontAwesomeIcon
                    icon={faTimes}
                    className="live-page-section-header__close"
                    size="lg"
                    onClick={clickFunction}
                />
            }
        </header>
    )

}

export default LivePageSectionHeader;