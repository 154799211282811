import { useEditor, useNode } from "@craftjs/core";
import React, { CSSProperties, ReactNode, useEffect } from "react"
import "../PluginsStyles/Button.scss";
import Anchor from "./Anchor";
import history from "../../../../config/history";

interface IProps {
    children?: ReactNode | ReactNode[],
    style?: CSSProperties,
    size?: string,
    variant?: string,
    anchor?: string,
    additionalClass?: string,
}

export function calculateMargin(anchor?: string) {
    if (anchor === "none") {
        return "unset";
    }
    let marginTop = "auto";
    let marginBottom = "auto";
    let marginLeft = "auto";
    let marginRight = "auto";
    if (anchor) {
        if (anchor.indexOf("top") > -1) marginTop = "0px";
        if (anchor.indexOf("left") > -1) marginLeft = "0px";
        if (anchor.indexOf("right") > -1) marginRight = "0px";
        if (anchor.indexOf("bottom") > -1) marginBottom = "0px";
    }
    return `${marginTop} ${marginRight} ${marginBottom} ${marginLeft}`;
}

export const Button = ({ children, style, size, variant, anchor }: IProps) => {
    const { connectors: { connect, drag }, url } = useNode((node) => ({
        url: node.data.props.url
    }));

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));

    return (
        <div ref={(ref: any) => { if (ref) { connect(drag(ref)); } }} onClick={() => { !enabled && url && history.push(url) }} style={{ margin: calculateMargin(anchor) }} >
            <button className={`journal-button ${size} ${variant} ${enabled && 'editable'}`}  >
                {children}
            </button>
        </div>
    )
}

const ButtonSettings = () => {
    const { size, variant, anchor, url, actions: { setProp } } = useNode((node) => ({
        size: node.data.props.size,
        variant: node.data.props.variant,
        anchor: node.data.props.anchor,
        url: node.data.props.url
    }));

    return (
        <form>
            <div className="option">
                <label>Url</label>
                <input type={"text"} value={url} onChange={e => {
                    setProp((props: { url: string }) => props.url = e.target.value);
                }} />
            </div>
            <div className="option">
                <label>Size</label>
                <select value={size} onChange={e => {
                    const selectedValue = e.target.value;
                    setProp((props: { size: string; }) => props.size = selectedValue);
                }}>
                    <option value={"small"}>small</option>
                    <option value={"medium"}>medium</option>
                    <option value={"large"}>large</option>
                </select>
            </div>
            <div className="option">
                <label>Variant</label>
                <select value={variant} onChange={e => {
                    const selectedValue = e.target.value;
                    setProp((props: { variant: string; }) => props.variant = selectedValue);
                }}>
                    <option value={"default"}>default</option>
                    <option value={"variant1"}>variant 1</option>
                    <option value={"variant2"}>variant 2</option>
                </select>
            </div>
            <div className="option">
                <label>Anchor</label>
                <Anchor selectedAnchor={anchor} callback={(selectedAnchor) => setProp((props: { anchor: string; }) => props.anchor = selectedAnchor)} />
            </div>
        </form>
    )
};

Button.craft = {
    props: {
        size: "medium",
        variant: "default",
        anchor: "center",
        url: ''
    },
    rules: {
        canDrag: (node: any) => node.data.props.text != "Drag"
    },
    related: {
        settings: ButtonSettings
    }
}