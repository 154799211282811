import React, { useLayoutEffect, useRef, useState } from 'react';
import { MpaUi } from "@mixerpa/mpa-react-components.mpa-ui";
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import axiosInstance from '../../../config/axios';
import { dataURLtoFile, fileToBase64 } from '../../../config/functions';
import { ResourcesTypeRes } from '../../../Models/ResponseModels/Resources';
import { showNotification } from '@mixerpa/mpa-react-components.mpa-ui/dist/utilities';
import useEventListener from '@mixerpa/mpa-react-components.mpa-ui/dist/_hooks/useEventListener';
import { faDownload, faEye, faFile, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./ModalMenu.scss";

const ModalMenu = () => {
    const [isModalLoading, setModalLoading] = useState<boolean>(false);
    const [isModalVisible, setModalVisibility] = useState<boolean>(false);

    useEventListener("showMenuModal", () => { setModalVisibility(true); });
    useEventListener("hideMenuModal", () => { setModalVisibility(false); });

    return (
        isModalVisible ?
            <MpaUi.Components.ModalMessage
                title="Upload Menu"
                isVisible={isModalVisible}
                isLoading={isModalLoading}
                closeButton={true}
                className="menu-upload-modal"
                size="md"
                fnHideModal={() => setModalVisibility(false)}
                children={<MenuForm setLoading={setModalLoading} fnClose={setModalVisibility} />}
            />
            :
            null
    )
}

export default ModalMenu;


interface IFormValues {
    fileName: string,
}

interface IPropsForm {
    setLoading: Function,
    fnClose: Function
}

const MenuForm = ({ setLoading, fnClose }: IPropsForm) => {
    const labelFileRef = useRef<HTMLLabelElement>(null!);
    const inputFileRef = useRef<HTMLInputElement>(null!);
    const [error, setError] = useState<string>("");
    const [initialValues, setInitialValues] = useState<IFormValues>({ fileName: '', });
    const validationSchema = Yup.object({ fileName: Yup.string().required('Required') });
    const [resource, setResource] = useState<ResourcesTypeRes.IResource>();

    useLayoutEffect(() => {
        axiosInstance.get("/Resources/Category/" + process.env.REACT_APP_RESOURCE_MENU_ID + "/Resources").then((res) => {
            if (res.data.status === "success" && res.data.data.totalRecords > 0) {
                let _resource = res.data.data.collection[0];
                setResource(_resource);
                setInitialValues({ fileName: _resource.link });
                if (labelFileRef.current) {
                    labelFileRef.current.innerText = _resource.link;
                }
            }
        }).finally(() => { setTimeout(() => setLoading(false), 500); });
    }, []);

    async function uploadResource(file: File, fileName: string, fnCallbackSuccess?: Function) {
        let data = new FormData();
        data.append('AttachedFile', file, fileName);
        if (resource)
            data.append('ResourceID', resource.resourceID.toString());
        data.append('ResourceTypeID', "DOCUMENT");
        data.append('ResourceCategoryID', process.env.REACT_APP_RESOURCE_MENU_ID || "");
        data.append('Public', 'true');
        data.append('Published', 'true');
        data.append('Title', "Menu");

        if (resource) {
            axiosInstance.put(`Resources/${resource.resourceID}`, data).then((res) => {
                if (res.data.status === "success") {
                    fnCallbackSuccess && fnCallbackSuccess();
                }
                else {
                    handleError(res.data.message);
                }
            }).catch((e: any) => {
                handleError(e);
            });
        }
        else {
            axiosInstance.post("Resources/", data).then((res) => {
                if (res.data.status === "success") {
                    fnCallbackSuccess && fnCallbackSuccess();
                }
                else {
                    handleError(res.data.message);
                }
            }).catch((e: any) => {
                handleError(e);
            });
        }
    }

    function handleSubmit(values: IFormValues) {
        setLoading(true);
        console.log("inputFileRef", inputFileRef)
        if (inputFileRef.current && inputFileRef.current.files && inputFileRef.current.files.length > 0) {

            const myFile = inputFileRef.current.files[0];
            if (myFile) {
                fileToBase64(myFile).then((base64: any) => {
                    let document: any = {
                        fileName: myFile.name,
                        base64: base64,
                    }

                    if (document) {
                        let _file = dataURLtoFile(document.base64, document.fileName);
                        uploadResource(_file, document.fileName, handleSuccess);
                    }
                })
            }
        }
        else {
            if (resource) {
                setLoading(false);
                fnClose();
            }
            else {
                handleError("");
            }
        }
    }

    function previewUploadedDocument(setFieldValue: Function) {
        if (inputFileRef.current && inputFileRef.current.files && inputFileRef.current.files.length > 0) {
            const _file = inputFileRef.current.files[0];
            if (_file) {
                //setResource(undefined);
                setFieldValue("fileName", _file.name);

                if (labelFileRef.current) {
                    labelFileRef.current.innerText = _file.name;
                }
            }
        }
    }

    function handleSuccess() {
        setLoading(false);
        showNotification("success", "Upload menu successful");
        fnClose();
    }

    function handleError(_error: string) {
        setLoading(false);
        setError(_error);
    }

    return (
        <Formik enableReinitialize onSubmit={values => handleSubmit(values)} validationSchema={validationSchema} initialValues={initialValues}>
            {({ handleSubmit, setFieldValue, errors }) => (
                <Form onSubmit={handleSubmit} autoComplete="off" className='menu-upload-form'>
                    <div className='file-wrapper'>
                        <div className="custom-file">
                            <input ref={inputFileRef} type="file" name="fileName" className="custom-file-input mpa-cursor-pointer" placeholder='Select a file' accept=".pdf"
                                onChange={() => {
                                    if (inputFileRef.current && inputFileRef.current.files) {
                                        previewUploadedDocument(setFieldValue);
                                    }
                                }} />
                            <label ref={labelFileRef} className="custom-file-label u-font-size-7">Choose file...</label>
                        </div>
                        {
                            resource &&
                            <MpaUi.Components.Button type="button" size="small" additionalClass="zb-smartspace-btn u-font-size-8"
                                onClick={() => window.open(window.apiURL + "Resources/" + resource?.resourceID + "/Visualize/")}>
                                <FontAwesomeIcon icon={faDownload} className="mpa-me-2" />
                                Current Menu
                            </MpaUi.Components.Button>
                        }
                    </div>
                    <div className={`invalid-feedback u-font-size-8 ${errors.fileName ? "d-block" : ""}`}>
                        {errors.fileName}
                    </div>
                    <hr className='mpa-w-100' />
                    <MpaUi.Components.Button type="submit" additionalClass="mpa-ms-0 zb-smartspace-btn u-font-size-10">Confirm</MpaUi.Components.Button>
                    {error && <div className="invalid-feedback d-inline-block">{error}</div>}
                </Form>
            )}
        </Formik>
    )
}