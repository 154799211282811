import { TMainLanguage } from "./context";

type IStringTranslations = {
    [key in TMainLanguage]: string;
};
interface IFlags {
    flag: IStringTranslations,
}
export const flags: IFlags = {
    flag: {
        'en': 'flag-icon flag-icon-gb',
        'de': 'flag-icon flag-icon-de',
        'es': 'flag-icon flag-icon-es',
        'it': 'flag-icon flag-icon-it',
        'fr': 'flag-icon flag-icon-fr',
    }
}