import React from 'react';
import { Modal } from 'react-bootstrap';
import { ModalProps } from 'react-bootstrap/esm/Modal';
import Button, { IconPosition, IconProp } from '../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle, faTimes, faInfo, faCheck } from '@fortawesome/free-solid-svg-icons';
import ReactHtmlParser from 'react-html-parser'

export interface Button {
    text: string,
    action: () => any,
    className?: string,
    icon?: IconProp,
    iconPosition?: IconPosition,
    disabled?: boolean
}

interface IProps {
    title: any,         // can be text or html
    text: any,          // can be text or html
    type?: "error" | "warning" | "info" | "success" | "",
    size?: ModalProps["size"],
    closeButton?: boolean,
    buttons: Button[],
    isVisible?: boolean,
    className?: string,
    fnHideModal?: () => void,
    blocked?: boolean,
    footerClass?: string
}

const ModalMessage = ({ title, text, size, closeButton = false, type = "", buttons, isVisible = true, fnHideModal, className = '', blocked = false, footerClass = "" }: IProps) => {

    const renderIcon = () => {
        let icon: any;
        switch (type) {
            case "error":
                icon = faTimes;
                break;
            case "warning":
                icon = faExclamationTriangle;
                break;
            case "info":
                icon = faInfo;
                break;
            case "success":
                icon = faCheck;
                break;
        }
        return icon ? <FontAwesomeIcon icon={icon} className="mr-2" /> : null;
    }

    const icon = renderIcon();

    return (
        <Modal className={`modal-message ${type} ${className}`} show={isVisible} onHide={fnHideModal} size={size} backdrop={blocked ? "static" : true} centered>
            <Modal.Header closeButton={closeButton}>
                <Modal.Title className={`${!icon ? 'w-100' : ''}`}>
                    {icon}
                    {title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {text}
            </Modal.Body>
            {
                buttons.length > 0 &&
                <Modal.Footer className={footerClass}>
                    {
                        buttons.map((button, idx) => {
                            return <Button key={idx}
                                clicked={button.action}
                                classes={button.className || ''}
                                disabled={button.disabled}
                                icon={button.icon}
                                iconPosition={button.iconPosition}>{button.text}</Button>
                        })
                    }
                </Modal.Footer>
            }
        </Modal>
    )
}

export default ModalMessage;