import React from 'react';
import { Modal, Button } from 'react-bootstrap';

interface IProps {
    isOpen: boolean,
    setOpen: (value: boolean) => void
}

const ChatInfo = ({ isOpen, setOpen }: IProps) => {

    return (
        <Modal show={isOpen} onHide={() => setOpen(false)} size="lg" id="chatInfoPopup" className="modal">
            <Modal.Header closeButton>
                <Modal.Title>Informations about Case Forum</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h2>Welcome to Zimmer Biomet Case Forum</h2>
                <p>Here are some useful tips about this section, please read them carefully.</p>
                <h3>Messages</h3>
                <p>
                    You can type your message and send it clicking the paper airplane icon.<br />
                    The <strong>ENTER</strong> key allows to add a new line of text, so if you want to send the message through the keyboard, you must use the combined keys <strong>CTRL+ENTER</strong>.<br />
                    <br />
                    Through the <strong>“DEL” button</strong> inside the message bubble, the common user can delete its own message, but he cannot do the same with other user’s message: only moderators have this ability.<br />
                    <br />
                    You can even quote a message sent by another user through the <strong>“quote” button</strong> inside the bubble you want to quote.<br />
                    <br />
                    Through the button <strong>“Previous Message”</strong> you can see the messages sent before your login.<br />
                    <br />
                    All the chat activity is saved in the database and it will be cleared after a certain amount of time, in this way a user can re-watch the entire chat flow.
                </p>
                <h3>Files</h3>
                <p>There is a limitation about the file size you can send with your message:</p>
                <ul>
                    <li>Maximum of 30MB for pptx and video files</li>
                    <li>Maximum of 10MB for others file types</li>
                </ul>
                <h3>DO and DONT'S</h3>
                <p>Please follow these rules:</p>
                <ul id="do-donts">
                    <li><strong>DO:</strong>
                        <ul>
                            <li>Respect other people's privacy</li>
                            <li>Verify facts before reposting</li>
                            <li>Check messages and respond promptly</li>
                            <li>When sending picture or video, make sure all recognizable data are not visible (GDPR)</li>
                        </ul>
                    </li>	
                    <li><strong>DONT'S:</strong>
                        <ul>
                            <li>Name-call or express offensive opinions</li>
                            <li>Post private or embarrassing comments</li>
                            <li>Exclude people or talk behind their backs</li>
                            <li>Use all capital case to write your messages</li>
                            <li>Ask or share personal contact/information</li>
                            <li>Publish link or other media not concerning the current discussion (e.g. commercials,
                                external links, etc...)</li>
                        </ul>
                    </li>	
                </ul>
                <br />
                <p>
                    For further informations, please see the <a href="/assets/docs/case-forum/zbi-case-forum-guidelines.pdf" target="_blank">Case Forum Guidelines</a>.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="primary" onClick={() => setOpen(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ChatInfo;