// components/user/Card.js
import React, { ReactNode } from "react";
import { Text } from "./Text";
import { Button } from "./Button";
import { Container, ContainerSettings } from "./Container";
import { Element, useEditor, useNode } from "@craftjs/core";
import '../PluginsStyles/Card.scss';
import history from '../../../../config/history';

interface IPropsCardTop {
    children?: ReactNode | ReactNode[]
}

export const CardContent = ({ children }: IPropsCardTop) => {
    const { connectors: { connect } } = useNode();
    
    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled,
    }));
    

    return (
        <div ref={ref => { if (ref) connect(ref) }} className="card editable" onClick={enabled ? ()=>{} : ()=>{history.push('www.google.com')}}>
            {children}
        </div>
    )
}

CardContent.craft = {
    rules: {
        // Only accept Text
        canMoveIn: (incomingNodes: any[]) => incomingNodes.every((incomingNode) => incomingNode.data.type === Text)
    }
}

// interface IPropsCardBottom {
//     children?: ReactNode | ReactNode[]
// }

// export const CardBottom = ({ children }: IPropsCardBottom) => {
//     const { connectors: { connect } } = useNode();
//     return (
//         <div ref={ref => { if (ref) connect(ref) }}>
//             {children}
//         </div>
//     )
// }

// CardBottom.craft = {
//     rules: {
//         // Only accept Buttons
//         canMoveIn: (incomingNodes: any[]) => incomingNodes.every((incomingNode) => incomingNode.data.type === Button)
//     }
// }

export const Card = () => {
    const { connectors: { connect } } = useNode();
   
    
    return (
        <div ref={(ref: any) => { if (ref) connect(ref) }} className="card editable" >
            {/* <Element id="container" is={Container} canvas> */}
                <Element  id="card" canvas>
                    <Text text="Title" elementType="h2" />
                    <Text text="Subtitle" elementType="h6" />
                    <br />
                    <Text text="text preview text preview text preview text preview text preview text preview text preview text preview text preview " elementType="p" />
                </Element>
                {/* <Element id="buttons" is={CardBottom} canvas>
                <Button>
                    button
                </Button>
            </Element> */}
            {/* </Element> */}
        </div>
    )
}

Card.craft = {
    related: {
        // Since Card has the same settings as Container, we'll just reuse ContainerSettings 
        settings: ContainerSettings
    },
    rules: {
        // Only accept Text
        canMoveIn: (incomingNodes: any[]) => incomingNodes.every((incomingNode) => incomingNode.data.type === Text)
    }
}