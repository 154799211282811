import { useEditor, useNode } from "@craftjs/core";
import React, { ReactNode, useEffect, useState } from "react"
import { CSSProperties } from "react"
import ContentEditable from "react-contenteditable";
import ReactQuill from "react-quill";
import "../PluginsStyles/Text.scss";
import ColorPicker from "./ColorPicker";
import { Image } from "./Image";
import RangeInput from "./Range";
import 'react-quill/dist/quill.snow.css';
import moment from "moment-timezone";

interface IProps {
    text: string,
    elementType?: string,
    style?: CSSProperties,
    children?: ReactNode | ReactNode[]
}

const modules = {
    toolbar: {
        container: "#toolbar",
    }
};

const formats = [
    "size",
    "bold",
    "italic",
    "underline",
    "align",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "color"
];

export const Text = ({ text, elementType, style, children }: IProps) => {
    const [id, setID] = useState("");

    const { enabled } = useEditor((state) => ({
        enabled: state.options.enabled
    }));

    const { connectors: { connect, drag }, isActive, actions: { setProp } } = useNode((node) => ({
        isActive: node.events.selected
    }));
    const [editable, setEditable] = useState(false);

    useEffect(() => {
        !isActive && setEditable(false)
    }, [isActive]);

    useEffect(() => {
        setID("Text_" + moment(new Date()).format("YYYYMMDD_HHmmss"))
    }, [])

    return (
        <div className={`${enabled && 'editable'}`} ref={ref => { if (ref) connect(drag(ref)) }} onClick={e => { setEditable(true) }}>
            {children}
            <ContentEditable
                disabled={!editable || !enabled}
                html={text}
                onChange={e => setProp((props: { text: string; }) => props.text = e.target.value.replace(/<(?!br\s*\/?)[^>]+>/g, ""))}
                tagName={elementType}
                style={style}
            />
            {
                // id &&
                // <>
                //     <Toolbar id={`${id}`} show={isActive}></Toolbar>
                //     <ReactQuill
                //         theme="snow"
                //         value={text}
                //         onChange={(value) => setProp((props: { text: string; }) => props.text = value)}
                //         // placeholder={'TEXT'}
                //         // modules={{ ...modules, toolbar: { container: `#toolbar` } }}
                //         modules={{ ...modules, toolbar: { container: `#${id}_toolbar` } }}
                //         formats={formats}
                //     />
                // </>
            }
        </div>
    )
}
interface IToolbarProps {
    id: string,
    show: boolean,
}

const Toolbar = ({ id, show }: IToolbarProps) => {
    return (
        // <div id={`toolbar`} className={`quill-toolbar ql-toolbar ql-snow ${show ? " show" : " hide"}`}>
        <div id={`${id}_toolbar`} className={`quill-toolbar ql-toolbar ql-snow ${show ? " show" : " hide"}`}>
            {/* <select className="ql-size" defaultValue="medium">
                <option value="small">small</option>
                <option value="medium" >medium</option>
                <option value="large">large</option>
            </select>
            <select className="ql-align" />
            <select className="ql-color">
           
                    <option value="#32364a"></option>
                    <option value="#6c7684"></option>
                    <option value="#687eeb"></option>
                    <option value="#102d7e"></option>
              
                    <option value="#2b6eb6"></option>
                    <option value="#1e466d"></option>
          
                    <option value="#121212"></option>
                    <option value="#E5E5E5"></option>
            </select> */}

            {/* <select className="ql-font ql-picker">
                <option className="ql-picker-item" data-value="serif">serif</option>
                <option className="ql-picker-item" data-value="san Serif">san serif</option>
                <option className="ql-picker-item" data-value="monospace">monospace</option>
            </select> */}
            {/* <button className="ql-bold" />
            <button className="ql-italic" />
            <button className="ql-underline" />
            <button className="ql-link" /> */}
        </div>
    )
}

const TextSettings = () => {
    const { elementType, color, fontSize, actions: { setProp } } = useNode((node) => ({
        elementType: node.data.props.elementType || "p",
        color: node.data.props.style.color || "#000000",
        fontSize: node.data.props.style.fontSize || "20px"
    }));


    return (
        <form>
            {/* <div className="option">
                <label>Element type</label>
                <select onChange={e => {
                    const selectedValue = e.target.value;
                    setProp((props: { elementType: string; }) => props.elementType = selectedValue);
                    let fontSize = 14;
                    switch (selectedValue) {
                        case "p":
                            fontSize = 14;
                            break;
                        case "span":
                            fontSize = 14;
                            break;
                        case "h1":
                            fontSize = 40;
                            break;
                        case "h2":
                            fontSize = 30;
                            break;
                        case "h3":
                            fontSize = 25;
                            break;
                        case "h4":
                            fontSize = 20;
                            break;
                        case "h5":
                            fontSize = 15;
                            break;
                        case "h6":
                            fontSize = 10;
                            break;
                    }
                    setProp((props: { style: CSSProperties; }) => props.style.fontSize = fontSize + "px");
                }} value={elementType}>
                    <option value={"p"}>p</option>
                    <option value={"span"}>span</option>
                    <option value={"h1"}>h1</option>
                    <option value={"h2"}>h2</option>
                    <option value={"h3"}>h3</option>
                    <option value={"h4"}>h4</option>
                    <option value={"h5"}>h5</option>
                    <option value={"h6"}>h6</option>
                </select>
            </div>
            <div className="option">
                <label>Color</label>
                <ColorPicker value={color} style={{ fontSize: "0.5rem" }} onChange={e => { setProp((props: { style: CSSProperties }) => props.style.color = e.target.value); }} />
            </div>
            <div className="option">
                <label>Font size <span>({fontSize})</span></label>
                <RangeInput value={fontSize.replace("px", "")} steps={1} min={7} max={50} style={{ fontSize: "0.5rem" }} onChange={e => { setProp((props: { style: CSSProperties }) => props.style.fontSize = e.target.value + "px"); }} />
            </div> */}
        </form >
    )
}

Text.craft = {
    props: {
        text: "Insert here your text",
        elementType: "p",
        style: {
            color: "black",
            fontSize: "20px",
        }
    },
    rules: {
        canDrag: (node: any) => node.data.props.text != "Drag",
        canMoveIn: (incomingNodes: any[]) => incomingNodes.every((incomingNode) => incomingNode.data.type === Image)
    },
    related: {
        settings: TextSettings
    }
}