
import React, { useState } from 'react';
import { useEffect } from 'react';
import Button, { IconProp } from './Button';

interface IProps {
    id: string,
    children: any,
    className?: string,
    toggleButtonIcon?: IconProp,
    toggleButtonImageIcon?: string,
    toggleButtonCustomIcon?: boolean,
    toggleButtonText?: string,
    toggleButtonClass?: string,
    toggleButtonIconClass?: string,
    toggleButtonAction?: Function,
    menuClass?: string,
    menuTop?: string,
    menuLeft?: string,
    menuRight?: string,
}

const DropDownButtons = ({
    id,
    children,
    className = "",
    toggleButtonIcon,
    toggleButtonImageIcon = "",
    toggleButtonCustomIcon = false,
    toggleButtonText = "",
    toggleButtonClass = "",
    toggleButtonIconClass = "",
    toggleButtonAction,
    menuClass = "",
    menuTop = "unset",
    menuLeft = "unset",
    menuRight = "unset" }: IProps) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        window.addEventListener('click', hideMenu);
        return () => {
            window.removeEventListener('click', hideMenu);
        }
    }, [])

    const hideMenu = (e: any) => {
        let show = false;
        let elem = e.target;
        let n = 1;
        while (elem.parentElement) {
            // if (elem.className && elem.className.indexOf && elem.className.indexOf("dropdown-buttons") > -1) {
            if (elem.id && elem.id.indexOf && elem.id.indexOf(id) > -1) {
                show = true;
                break;
            }
            elem = elem.parentElement;
            n++;
        }
        if (!show) {
            setShow(show);
        }
    }

    return (
        <div id={id} className={"dropdown-buttons d-flex flex-column justify-content-center " + (className ? className : "")}>
            <Button
                icon={toggleButtonIcon}
                imageIcon={toggleButtonImageIcon}
                customIcon={toggleButtonCustomIcon}
                classes={toggleButtonClass}
                classIcon={toggleButtonIconClass}
                clicked={() => {
                    setShow(!show);
                    if (toggleButtonAction) {
                        toggleButtonAction();
                    }
                }}>
                {toggleButtonText}
            </Button>
            <div className="position-relative">
                <div className={`${(show ? "d-block position-absolute" : "d-none")} dropdown-buttons-menu ${menuClass}`}
                    style={{ top: menuTop, left: menuLeft, right: menuRight }}>
                    {
                        children &&
                        React.Children.map(children, x => {
                            if (x) {
                                return React.cloneElement(x, {
                                    className: `dropdown-buttons-item ${x.props.className}`, onClick: (e: any) => {
                                        if (x.props['no-hide'] !== "true") {
                                            setShow(!show);
                                        }
                                        e.stopPropagation();
                                    }
                                })
                            }
                        })}
                </div>
            </div>
        </div>
    )
}

export default DropDownButtons;