import React, { useEffect } from 'react';
import history from '../../config/history';
import Button from '../../Components/_UI/Button/Button';
import Header from '../../Components/LoginPage/LoginPageHeader';
import RegistrationForm from '../../Components/Form/RegistrationForm';

interface IProps {
    type: 'new' | 'edit',
    isRegistrationEnabled: boolean,
}

// If type = 'new' => Register new user
// If type = 'edit' & logged => Edit logged user data
// If type = 'edit' & not logged => Redirect to login

const RegisterPage = ({ type, isRegistrationEnabled }: IProps) => {
    useEffect(() => {
    }, [])
    return (
        <div className="interactive-page-register container-fluid">
            <div className="row">
                <div className="col-12 p-0">
                    <div className="container-fluid mb-4" style={{ maxWidth: 1200, margin: '0 auto' }}>
                        <Header />
                    </div>
                    {/* back button */}
                    {
                        type === 'edit' &&
                        <Button type="submit"
                            withClass={["secondary","center"]}
                            classes="interactive-page-reg-success-back u-font-size-12 font-weight-bold"
                            clicked={() => history.push('/')}
                        >&lt; BACK</Button>
                    }
                    <main className="interactive-page-form">
                        <RegistrationForm type={type} />
                    </main>

                </div>
            </div>
        </div>
    )

}

export default RegisterPage;